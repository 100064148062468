import React, {Component, Fragment} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import FormEdit from "../../../components/Common/form-input-control/FormEdit";
import TableDataView from "../../../components/Common/tables/TableDataView";
import Connector from "../../../redux/common/Connector";
import {
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    attachImageProfile,
    submitEntryCRM2, sortData,
    deleteEntryCRM_WithData
} from "../../../api/fn";
import{
    buildAssociationData
}from "./fn";

import {GetUserToken, PostTo} from "../../../api/fetching-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import ComboboxOptions from "../../../components/Common/form-input-control/ComboxOption";
import PopupMe from "../../../components/Common/PopupMe";
import UploadDocument from "../../../components/databases/UploadDocument";
import ChooseCategoryModal from "../../../components/databases/popup-choose-file-category";
import {SERVER_RESERVATION, SERVER_CRM, CURRENT_DATABASE} from "../../../configures/constants";
import PopupDownloadFile from "../../../components/databases/popup-download-file";
import ModelSimple from "../../../components/Common/popup-modal/ModalView";
import {makeStyles, Paper} from "@material-ui/core";
import {UpcaseFirst} from "../../../api/fn"
import Button from "@material-ui/core/Button";

class SetupDatabasePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEdit: false,
            isLoading: false,
            listField: listField,
            newEntry: {},
            module: "",
            showViewDetail: false,
            selectViewData: null,
            selectViewTitle: null,
            showUpload: false,
            selectedRecord: null,
            showChooseCategory: false,
            fileCategory: "",
            fileName: "",
            fileBase64: "",

            showFilterPopup: false,
            filterData: [],
            fitlerTitle: "--",
            filterField: "",
            showEditFormType: false,
            BaseFilter: [],
        }
        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
    }

    componentDidMount() {
        //todo
        this.setState({
            module: this.props.module
        })
    }

    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }
    onCancel = () => {
        this.setState({
            showEdit: false
        })
    }
    handleInput = async (e) => {
        //console.log("onChangeModule :)-> ", e.target.name, " > ", e.target.value);
        let tmp = this.state;
        tmp[e.target.name] = e.target.value;
        this.setState(tmp);
    }
    saveProfileFile = async (info) => {
        if (typeof info === "undefined") {
            return
        }
        const user = GetUserToken();
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Module = info.module;
        hub.Ref = info.ref;
        hub.Category = info.category;
        hub.Type = info.category;
        hub.Name = info.category;// this.state.newEntry.name;
        hub.Username = user.Username;
        hub.Filename = info.filename;
        hub.Base64String = info.base64string;
        const _this = this;
        let endpoint = "/global/upload/new";
        this.setState({isLoading: true});
        await PostTo(SERVER_CRM, hub, endpoint, async (dataIn) => {
            //console.log("submitUploadEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    await _this.fetchData();
                    _this.setState({
                        isLoading: false,
                    });
                    _this.cancelChooseFileCategory();
                }
            }
        })
    }
    fetchData = async () => {
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        this.props.LoadData();
    }
    buildEntry = (inEntry) => {
        const _this = this;
        let o = {};
        const findKeyObject = (myKey) => {
            const listFields = _this.getListField();
            for (let i in listFields) {
                const row = listFields[i];
                if (row.field === myKey) {
                    return row
                }
            }
            return {}
        }
        for (let i in inEntry) {
            const key = i;
            let val = inEntry[i];
            const row = findKeyObject(key);
            if (row.dataType === "float") {
                val = parseFloat(val)
            }
            if (row.dataType === "boolean") {
                val = val === "yes"
            }
            o[key] = val;
        }
        return o;
    }
    submitEntry = async (e) => {
        let newEntry = this.buildEntry(this.state.newEntry);
        let listField = this.getListField();
        //console.log("submitEntry--->", this.state.newEntry, newEntry, listField);

        this.setState({newEntry: newEntry, listField: listField});

        //todo uncomment after
        let endpoint = "/entity/insert/database";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode}, {key: "module", val: this.props.module}];
        let reloadFunction = [this.fetchData];
        let payload={};
        for(let i in newEntry){
            const key =i;
            const val =newEntry[i];
            payload[key]=""+val.toString();
        }

        /*payload.org =  GetUserToken().OrgCode;
        payload.module = this.props.module;
        await this.props.SaveGlobalMiddleware({
            module:this.props.module,
            payload:payload
        })*/
        await submitEntryCRM2(e, payload, othersField, endpoint, reloadFunction, this);
    }
    onDelete = async (rowIn) => {
        const e = undefined;
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }
        let conds = [
            {key: "org", type: "string", val: GetUserToken().OrgCode},
            {key: "module", type: 'string', val: this.props.module},
            {key: "ref", type: "string", val: rowIn.ref}
        ];
        let hub = {
            Conditions: conds,
            Fields: [],
            DbName: "crm",
            TableName: "GeneralTableData",
            In: {}
        }
        //todo uncomment aft
        let endpoint = "/global-service/remove";
        let reloadFunction = [this.fetchData];
        await deleteEntryCRM_WithData(e,hub,endpoint,reloadFunction,this);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    closeDetail = () => {
        this.setState({
            showViewDetail: false,
            selectViewData: null,
            selectViewTitle: null
        });
    }
    openDetail = (record) => {
        let row = record;
        let info = {};
        info.title = row.names;
        info.descriptionText = row.module;
        info.descObject = row.data;
        info.image = row.link;
        info.row = row;
        this.setState({
            showViewDetail: true,
            selectViewData: info,
            selectViewTitle: ""
        });
    }
    openUpload = (row) => {
        this.setState({
            showChooseCategory: true,
            showUpload: false,
            selectedRecord: row,
        })
    }
    handleCloseUpload = () => {
        this.setState({
            showUpload: false,
            selectedRecord: null,
        })
    }
    handleInputFilterParams = (key, val) => {
        this.setState({
            [key]: val
        });
    }
    handleInputFileChooseCategory = async (key, e) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
        console.log("handleInputChooseCategory > ", key, " > ", e.target.value)
    }
    closeChooseFileCategory = (record) => {
        if (this.state.fileCategory === "") {
            return alert("Sorry can't continue,please choose your file category!")
        }
        if (this.state.fileCategory !== "profile") {
            if (this.state.fileName === "") {
                return alert("Please provide the file name before continue!")
            }
        }
        this.setState({
            showChooseCategory: false,
            showUpload: true,
            selectedRecord: record,
            fileName: "",
            fileBase64: "",
        });
        //this.handleOpenUpload()

    }
    cancelChooseFileCategory = () => {
        this.setState({
            showChooseCategory: false,
            showUpload: false,
            selectedRecord: null,
            fileCategory: "",
            fileName: "",
            fileBase64: "",
        });
    }
    getListDisplayField=()=>{
        let ls = [];
        const attributes = this.props.allData.Attributes;
        for (let i in attributes) {
            const row = attributes[i];
            if (row.module === this.props.module) {
                if(row.display){
                    ls.push(row)
                }
            }
        }

        ls =sortData("displayposition",ls);
        return ls
    }
    renderList = () => {
        if (this.state.showEdit) {
            return null
        }

        const associationsData = buildAssociationData(this.props,this);

        let columns = [
            {id: 'link', label: 'Profile Pic.', minWidth: 170, dataType: "string"},
        ];

        /**
         *
         * Let add our display field
         */
        const attributes = this.getListDisplayField();
        for(let i in attributes){
            const row=attributes[i];
            const fieldname=row.fieldname;
            columns.push({
                id:fieldname, label: fieldname, minWidth: 170, dataType: "string"
            })
        }
        //let add association columns
        let assCol={};
        for(let i in associationsData){
            const row=associationsData[i];
            if(row.mainentity ===this.props.module){
                assCol[row.supplierentity] = true;
            }
        }
        for(let i in assCol){
            columns.push({
                id:i, label: i, minWidth: 170, dataType: "string"
            })
        }

        /**
         * Let add our display fields data
         *
         */
        const addFiledDisplayData=(inData)=>{
            for(let i in attributes) {
                const row = attributes[i];
                const fieldname = row.fieldname;
                inData[fieldname]=inData.data[fieldname]
            }

            return inData
        }

        let ls = [];
        const databases = this.props.allData.Databases;
        for (let i in databases) {
            let row = databases[i];
            if (row.module === this.props.module) {
                row = addFiledDisplayData(row);
                ls.push(row)
            }
        }

        ls = attachImageProfile(this.props.allData.Files, ls);

        /**
         *
         * let me add our associate data
         */
        let tmpLst=[];
        for(let i in ls){
            let rowRecord = ls[i];
            const ref = rowRecord.ref;
            for(let i in associationsData){
                const row=associationsData[i];
                if(row.mainentity ===this.props.module){
                    if(row.main===ref){
                        rowRecord[row.supplierentity]=row.suppliername;
                    }
                }
            }
            tmpLst.push(rowRecord)
        }

         ls=tmpLst;

        let data = {columns: columns, data: ls};
        return (
            <Paper style={{paddingLeft:135,paddingRight:135}}>
            <TableDataView
                {...data}
                tableTitle={UpcaseFirst(this.props.module)+" Records ("+data.data.length+") "}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDelete}
                openDetail={this.openDetail}
                openUpload={this.openUpload}
                closeDownload={this.closeDownload}
                openDownload={this.openDownload}
                associations={associationsData}

            />
            </Paper>
        )
    }
    getListField = () => {
        let ls = [];
        let attrs = sortData("position", this.props.allData.Attributes);
        for (let i in attrs) {
            const row = attrs[i];
            const org = GetUserToken().OrgCode;
            const module = this.props.module;
            if (row.org === org && row.module === module) {//let make sure that were are in the correct filter
                let type = "text";
                let options = [];
                if (row.datatype === "string") {
                    type = "text";
                }
                if (row.datatype === "boolean") {
                    type = "radio";
                    options = [true, false];
                }
                if (row.datatype === "list") {
                    type = "combobox";
                    for(let i in row.options){
                        options.push(i)
                    }
                }
                let my = {
                    field: row.fieldname,
                    label: row.fieldname,
                    options: options,
                    type: type,
                    required: row.required,
                    dataType: row.datatype
                }
                ls.push(my);
            }
        }

        return ls;
    }
    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }

        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Form edit [" + this.props.module + "]"}
                data={this.getListField()}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }
    renderViewDetail = () => {
        if (!this.state.showViewDetail) {
            return null
        }
        let info = this.state.selectViewData;
        return (
            <PopupMe
                {...info}
                closeModal={this.closeDetail}
                visible={this.state.showViewDetail}
            />
        )
    }
    renderChooseFileCategory = () => {
        if (!this.state.showChooseCategory) {
            return null
        }
        let record = this.state.selectedRecord;

        return (
            <ChooseCategoryModal
                status={this.state.showChooseCategory}
                record={record}
                onChangeCategory={this.handleInputFileChooseCategory}
                handleClose={this.closeChooseFileCategory}
                onCancel={this.cancelChooseFileCategory}
            />
        )
    }
    openDownload = (row) => {
        let ls = [];
        for (let i in this.props.allData.Files) {
            const o = this.props.allData.Files[i];
            if (o.ref === row.ref) {
                ls.push(o);
            }
        }
        this.setState({
            showDownloadFile: true,
            downloadFileList: ls,
            selectedRecord: row,
        })

    }
    closeDownload = () => {
        this.setState({
            showDownloadFile: false,
            downloadFileList: [],
        })
    }
    openFilterForm = (row) => {
        this.setState({
            selectedRecord: row,
            showEditForm: true,
            showEditFormType: true,
        })
    }
    closeFilterPopup = () => {
        this.setState({
            showFilterPopup: false,
            filterData: [],
            fitlerTitle: "--",
        })
    }

    render() {

        return (
            <Fragment>
                <renderNav onAddRecord={this.onAddRecord} goTo={this.props.goTo} />
                <GridContainer style={{marginTop: 50}}>
                    <GridItem xs={12} sm={12} md={12} alignItems="center">
                        <h4 style={{marginLeft:135,color:"#2196f3",fontSize:18}}>{UpcaseFirst(this.props.module)+" Database"}</h4>
                    </GridItem>
                    {this.props.module !== "" ?
                        <Fragment>
                            <GridItem xs={12} sm={12} md={12} alignItems="center">
                                {this.renderList()}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} alignItems="center">
                                {this.renderEditForm()}
                            </GridItem>
                        </Fragment> :
                        <p style={{color: "red"}}>Please select your target module(people)</p>
                    }

                </GridContainer>

                {this.renderViewDetail()}
                {this.renderChooseFileCategory()}


                <UploadDocument
                    open={this.state.showUpload}
                    handleClose={this.handleCloseUpload}
                    selectedRecord={this.state.selectedRecord}
                    uploadCategory={this.state.fileCategory}
                    handleSave={this.saveProfileFile}
                    module={this.props.module}
                    filesLimit={1}
                    submitFile={this.saveProfileFile}
                />
                <PopupDownloadFile
                    popTitle={"Download File"}
                    _this={this}
                    popStatus={this.state.showDownloadFile}
                    popFnClose={this.closeDownload}
                    vehicle={this.state.selectedRecord}
                    fileList={this.state.downloadFileList}
                    tableRef={"vehicle"}
                />
                <ModelSimple
                    data={this.state.filterData}
                    open={this.state.showFilterPopup}
                    entity={this.props.module}
                    title={this.state.fitlerTitle}
                    onClose={() => this.closeFilterPopup()}
                    includeHeader={true}
                />

                <LoadingProcessing open={this.state.isLoading}/>
            </Fragment>
        )

    }
}

export default Connector(SetupDatabasePage);


const buttonStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));
const renderNav=(props)=>{
    let {onAddRecord,goTo}=props;
    const classes2=buttonStyles()
    return(
        <div className={classes2.root}>
            <Button variant="contained" onClick={()=>goTo("menu")}>Exit</Button>
            <Button variant="contained" onClick={()=>onAddRecord()}>ADD RECORD</Button>
            <Button variant="contained">Dashboard</Button>
        </div>
    )
}

const options = ["yes", "no"];
const optionsDataType = ["string", "float", "boolean"];
const optionsBoolean = ["yes", "no"];
const listField = [
    {
        field: "FieldName",
        label: "Field Name",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "Section",
        label: "Display Section",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    },
    {
        field: "Position",
        label: "Position",
        options: options,
        type: "float",
        required: true,
        dataType: "float"
    },
    {
        field: "DataType",
        label: "DataType",
        options: optionsDataType,
        type: "radio",
        required: true,
        dataType: "string"
    },
    {
        field: "Username",
        label: "Username",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Password",
        label: "Password",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Unique",
        label: "Unique",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Mandatory",
        label: "Mandatory",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Name",
        label: "Display 1",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Surname",
        label: "Display 2",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Required",
        label: "Required",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Phone",
        label: "Phone",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Email",
        label: "Email",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },

];
