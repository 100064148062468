import {FetchDataCrm} from "../../api/fn";
import { SAVE_DATA,DELETE_DATA, TICKET_DATA_RECEIVED} from "../constants";


const SaveGlobalMiddleware = store => next => async action => {
    if (action.type === SAVE_DATA) {
        let payload = action.payload;
        let module = action.module;
        let hub = payload;
        let endpoint = "/entity/insert/" + module;
        await FetchDataCrm(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("SaveGlobalMiddleware response > ",out);

            next({type: TICKET_DATA_RECEIVED, data: out});
        });
    }else if (action.type === DELETE_DATA) {
        let payload = action.payload;
        let module = action.module;
        let hub = payload;
        let endpoint = "/entity/remove/" + module;
        await FetchDataCrm(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("SaveGlobalMiddleware response > ",out);

            next({type: TICKET_DATA_RECEIVED, data: out});
        });
    } else {
        next(action);
    }

}

export default SaveGlobalMiddleware;
