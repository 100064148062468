import React from "react";
import {LOAD_DATA,SAVE_DATA,DELETE_DATA} from "../constants";

const LoadData =  () => {
    return {
        type: LOAD_DATA,
    }
}

const SaveGlobalMiddleware=(payload)=>{
    return { ...payload,
        type: SAVE_DATA,
    }
}

const DeleteGobalMiddleware=(payload)=>{
    return { ...payload,
        type: DELETE_DATA,
    }
}
export {
    LoadData,
    SaveGlobalMiddleware,
    DeleteGobalMiddleware
}

