import {SET_VERIFICATION_MODULE_MAINTENANCE} from "../constants";

const initial = {
    maintenance: {
        status: true,
        allow: ["vehicle", "driver"],
        denny: ["people", "location", "supplier"]
    }
}
const VerificationModules = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SET_VERIFICATION_MODULE_MAINTENANCE:
            const entry = action.payload;
            //console.log("Reducer ::(--> ", action);
            newState = initial;
            newState.currentModule = entry.module;
            newState.currentMenu = entry.menu;
            newState.currentSubmenu = entry.submenu;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default VerificationModules;
