import React, {useEffect, useRef, useState} from "react";

export default ({colorIn, status, actual, numberVehicle}) => {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);
    const styles={
        container:{
            minWidth:"100%",
            minHeight:(dimensions.width * 2)/ 2,
            border:"2px solid "+colorIn,
            borderRadius: 20,
            display:"flex",
            flexDirection:"row",
            flex:1,
            margin:10
        },
        boxDetail:{
            minWidth:"50%",
            maxWidth:"50%",
            minHeight:(dimensions.width * 2)/ 2,
            maxHeight:(dimensions.width * 2)/ 2,
            display:"flex",
            flex:1,
            justifyContent:"center",
            alignItems: "center",
        },
        boxScore:{
            minWidth:"50%",
            maxWidth:"50%",
            minHeight:(dimensions.width * 2)/ 2,
            maxHeight:(dimensions.width * 2)/ 2,
            display:"flex",
            flex:1,
            justifyContent:"center",
            alignItems: "center",
            backgroundColor:colorIn,
            borderTopRightRadius:20,
            borderBottomRightRadius:20,
        },
        circle:{
            minWidth: dimensions.width / 2,
            maxWidth:dimensions.width / 2,
            minHeight:dimensions.width / 2,
            display:"flex",
            flex:1,
            flexDirection:"column",
            justifyContent:"center",
            alignItems: "center",
            borderRadius: dimensions.width / 4,
            backgroundColor: "white"
            /*minWidth:"50%",
            maxWidth:80,
            minHeight:80,
            display:"flex",
            flex:1,
            flexDirection:"column",
            justifyContent:"center",
            alignItems: "center",
            borderRadius: 40,
            backgroundColor: "white"*/
        },
        circleLabel1:{
            color: "lightblack",
            fontSize: 20,
            fontWeight: "bold"
        },
        circleLabel2: {
            fontSize: 12,
            color: "lightblack",
        },
        detailContent:{
            display:"flex",
            flex:1,
            flexDirection:"column",
        },
        detailContentBottom: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
            color: "#2196f3",
            fontSize: 12,
            cursor:"pointer"
        },
        detailContentHeader: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
            color: "lightblack",
        },
        detailContentMiddle: {
            alignItems: "center",
            flex: 2,
            display: "flex",
            flexDirection:"column",
            padding:5,
            maxWidth:"100%"
        },
        detailContentMiddleInner:{
            alignItems: "center",
            justifyContent:"center",
            display: "flex",
            flexDirection:"row",
            minWidth:"100%",
        },
        detailContentMiddleInnerRight:{
            minWidth:"50%",
            color: "lightblack",
        },
        detailContentMiddleInnerLeft:{
            minWidth:"50%",
            textAlign:"right",
            color: "lightblack",
        }

    }

    return (
        <div style={styles.container}>
            <div style={styles.boxDetail}>
                <div style={styles.detailContent}>
                    <p style={styles.detailContentHeader}>
                        {status}
                    </p>
                    <p style={{...styles.detailContentHeader,fontWeight:"bold"}}>
                        {numberVehicle}
                    </p>
                    <p style={styles.detailContentHeader}>
                        Vehicles
                    </p>
                </div>
            </div>
            <div style={styles.boxScore}  ref={targetRef}>
                <div style={styles.circle}>
                    <div style={styles.circleLabel1}>{actual}</div>
                    <div style={styles.circleLabel2}>R/Km</div>
                </div>
            </div>
        </div>
    )
}