import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import ListEvent from "../../../components/Pages/Operations/EventPlanning/ListEvent";
import NewEvent from "../../../components/Pages/Operations/EventPlanning/NewEvent";
import {sortData, submitEntryFleetminder} from "../../../api/fn";
import {GetEntityData} from "../../../api/entity-data";
import {GetUserToken} from "../../../api/fetching-data";
import maintenanceData from "../../../redux/reducer/reducer-maintenance-data";


class EventPlanningPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            component: "list",
            selectedVehicle: "",
            selectedLocation: "",

            newEventType: "",
            newEventCategory: "",
            newEventName: "",

            newEventNextDue: "",

            newEventRepeatApply: true,
            newEventRepeatUnit: "",
            newEventRepeatValue: "",

            newEventReminderApply: true,
            newEventReminderUnit: "",
            newEventReminderValue: "",

            newEventNotificationApply: false,
            newEventNotificationPerson: "",
            newEventNotificationEntity: "",

            newEventExpirePeriodUnit: "day",
            newEventExpirePeriodValue: "7",


            tasks: {},
            newTask: {
                name: "",
                cost: "",
                category: "",
                quantity:"",
            },

        }

    }

    getSummaryData = () => {
        let state = this.state;
        return [
            {key: "Type", val: state.newEventType},
            {key: "Category", val: state.newEventCategory},
            {key: "Name", val: state.newEventName},
            {key: "Due", val: state.newEventNextDue},
            {key: "", val: ""},
            {key: "Repeat Apply", val: state.newEventRepeatApply},
            {key: "Repeat Unit", val: state.newEventRepeatUnit},
            {key: "Repeat Value", val: state.newEventRepeatValue},
            {key: "", val: ""},
            {key: "Reminder Apply", val: state.newEventReminderApply},
            {key: "Reminder Unit", val: state.newEventReminderUnit},
            {key: "Reminder Value Before", val: state.newEventReminderValue},
            {key: "", val: ""},
            {key: "Notification Apply", val: state.newEventNotificationApply},
            {key: "Notify Person", val: state.newEventNotificationPerson},
        ]
    }

    componentDidMount() {
        //todo

    }
    fetchData = async () => {
        this.props.LoadData();
        this.props.LoadDataMaintenance()

    }
    handleInput = () => {

    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        });
        //console.log("handleInput2 > ", key, val)
    }
    getHandleValue = (key) => {
        //console.log("getHandleValue > ", key, " > ", this.state[key]);
        return this.state[key];
    }
    onAddRecord = async () => {
        this.setState({
            component: "new"
        })
    }
    onCancelAdding = () => {
        this.setState({
            component: "list"
        })
    }
    onFinishAdd = async () => {
        /*if(!window.confirm("Are you sure to submit?")){
            return
        }*/
        this.setState({
            isLoading:true
        });
        let state = this.state;
        let o = {};
        o.Org = GetUserToken().OrgCode;
        o.Vehicle = state.selectedVehicle;
        o.Originate = state.newEventType;
        o.Name = state.newEventName;
        o.RecurrenceType = state.newEventCategory;
        o.FleetLocation = state.selectedLocation || "cape town";

        if (o.RecurrenceType === "time") {
            o.TimeRecurrenceRepeatApply = state.newEventRepeatApply;
            o.TimeRecurrenceRepeatValue = parseFloat(state.newEventRepeatValue);
            o.TimeRecurrenceRepeatUnit = state.newEventRepeatUnit;
            o.TimeRecurrenceReminderApply = state.newEventReminderApply;
            o.TimeRecurrenceReminderValue = parseFloat(state.newEventReminderValue);
            o.TimeRecurrenceReminderUnit = state.newEventReminderUnit;
            o.TimeRecurrenceNextDue = state.newEventNextDue;
            o.TimeRecurrenceNextReminder = "";
        } else {
            o.MeterRecurrenceRepeatApply = state.newEventRepeatApply;
            o.MeterRecurrenceRepeatValue = parseFloat(state.newEventRepeatValue);
            o.MeterRecurrenceRepeatUnit = state.newEventRepeatUnit;
            o.MeterRecurrenceReminderApply = state.newEventReminderApply;
            o.MeterRecurrenceReminderValue = parseFloat(state.newEventReminderValue);
            o.MeterRecurrenceReminderUnit = state.newEventReminderUnit;
            o.MeterRecurrenceNextDue = parseFloat(state.newEventNextDue);
            o.MeterRecurrenceNextReminder = 0;//todo to be calculate before save;
        }

        o.NotifyAssign = state.newEventNotificationApply;
        o.NotifyEntity = state.newEventNotificationEntity || "personnel";
        o.NotifyRef = state.newEventNotificationPerson;

        o.ExpirePeriodType = "time";
        o.ExpirePeriodUnit = state.newEventExpirePeriodUnit;
        o.ExpirePeriodValue = parseFloat(state.newEventExpirePeriodValue);

        o.EventStatus = "system";

        let hub={};
        let tasks=[];
        for(let i in this.state.tasks){
            const row =this.state.tasks[i];
            tasks.push({
                Org       :  o.Org,
                Vehicle   :o.Vehicle,
                Originate :o.Originate,
                EventName : o.Name,

                TaskName       :row.name,
                Category       :row.category,
                Cost      : parseFloat(row.cost),
                Quantity  : parseInt(row.quantity)
            })


        }

        hub.Event = o;
        hub.Tasks = tasks;
        hub.Org = o.Org;
        //console.log("onFinishAdd > ",hub);

        /*this.props.SaveMaintenaceMiddleware({
            module:"event-plan",
            payload:o
        })
        this.setState({
            component: "list"
        })*/


        //todo uncomment after
        let endpoint = "/maintenance/insert/event-plan";
        let othersField = [];
        let reloadFunction = [this.fetchData];
        await submitEntryFleetminder(undefined, hub,othersField, endpoint, reloadFunction, this);
        this.setState({
            component: "list",
            isLoading:false
        });
    }

    onDelete = (row) => {
        //console.log("onDelete $$$--> ", row)
    }
    getEntityData = (module) => {
        let ls = [];
        const Databases = this.props.allData.Databases;

        for (let i in Databases) {
            const row = Databases[i];
            if (row.module === module) {
                ls.push({
                    key: row.ref,
                    val: this.getDisplayNameEntity(module, row),
                    data: row.data
                })
            }
        }


        return ls
    }
    getDisplayNameEntity = (module, record) => {
        const fieldList = this.getListDisplayField(module);
        let names = "";
        for (let i in fieldList) {
            const row = fieldList[i];
            names += record.data[row.fieldname] + " "
        }
        return names
    }
    getListDisplayField = (module) => {
        let ls = [];
        const attributes = this.props.allData.Attributes;
        for (let i in attributes) {
            const row = attributes[i];
            if (row.module === module) {
                if (row.display) {
                    ls.push(row)
                }
            }
        }

        ls = sortData("displayposition", ls);
        return ls
    }
    getEventPlanData = () => {

        let data =this.props.maintenanceData.EventPlans;// [];
        let ls = [];
        /*data.push({
            org: "easipath",
            vehicle: "CA 789 987",
            originate: "spec",
            name: "Service A",
            recurrencetype: "mileage",
            fleetlocation: "cape town",
            meterRecurrenceeepeatapply: false,
            meterrecurrencerepeatvalue: 0,
            meterrecurrencerepeatunit: ""
        });
        data.push({
            org: "easipath",
            vehicle: "CA 789 987",
            originate: "inspection",
            name: "Check Oil",
            recurrencetype: "time",
            fleetlocation: "cape town",
            meterRecurrenceeepeatapply: true,
            meterrecurrencerepeatvalue: 7,
            meterrecurrencerepeatunit: "day"
        });*/

        for (let i in data) {
            const row = data[i];
            if (row.vehicle === this.state.selectedVehicle) {
                if(row.recurrencetype ==="time"){
                    row.due =row.timerecurrencenextdue;
                    row.reminder = row.timerecurrencereminderapply?"Yes":"No";
                    row.repeat = row.timerecurrencerepeatapply?"Yes":"No";
                    row.repeatunit = row.timerecurrencerepeatunit;
                    row.repeatvalue = row.timerecurrencerepeatvalue;
                }else{
                    row.due =row.meterrecurrencenextreminder+" /Km";
                    row.reminder = row.meterrecurrencereminderapply?"Yes":"No";
                    row.repeat = row.meterrecurrencerepeatapply?"Yes":"No";
                    row.repeatunit = row.meterrecurrencerepeatunit;
                    row.repeatvalue = row.meterrecurrencerepeatvalue;

                }
                ls.push(row)
            }
        }
        return ls
    }
    getEventPlanColunms = () => {
        return (
            [
                {id: "name", label: "Vehicle"},
                {id: "originate", label: "Origin"},
                {id: "name", label: "Name"},
                {id: "recurrencetype", label: "Measure"},
                {id: "due", label: "Due"},
                {id: "reminder", label: "Reminder"},
                {id: "repeat", label: "Repeat"},
                {id: "repeatunit", label: "Repeat Unit"},
                {id: "repeatvalue", label: "Repeat Value"},
            ]
        )
    }

    addTask = (e) => {
        e.preventDefault();
        const task = this.state.newTask;
        if (task.name === "" || task.category === "" || task.cost === "") {
            return alert("Error we can't add this task because one of the field is empty! make select your category for provide the name" +
                " or put zero on the cost")
        }

        let tmp = this.state;
        tmp.tasks[tmp.newTask.name] = tmp.newTask;
        tmp.newTask = {
            name: "",
            cost: "",
            category: "",
        }
        this.setState(tmp);
    }
    setTaskValue = (e, key) => {
        let tmp = this.state;
        tmp.newTask[key] = e.target.value;
        this.setState(tmp);
    }
    removeTask = (name) => {
        let tmp = this.state;
        delete tmp.tasks[name];
        this.setState(tmp);
    }
    getTaskValue = (key) => {
        return this.state.newTask[key];
    }
    handleInputCheckbox = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = !tmp[key];
        this.setState(tmp)
    }
    getListPersonnel = () => {
        const data = GetEntityData(this.state.newEventNotificationEntity, this.props);
        let ls = data;

        return ls
    }
    renderListEvent = () => {

        return (
            <ListEvent
                {...this.props}
                state={this.state}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDelete}
                lsVehicle={this.getEntityData("vehicle")}
                handleInput={this.handleInput}
                handleInput2={this.handleInput2}
                getHandleValue={this.getHandleValue}
                ls={this.getEventPlanData()}
                columns={this.getEventPlanColunms()}
            />
        )
    }
    renderNewEvent = () => {
        return (
            <NewEvent
                {...this.props}
                state={this.state}
                onCancelAdding={this.onCancelAdding}
                onFinishAdd={this.onFinishAdd}
                handleInput2={this.handleInput2}
                getHandleValue={this.getHandleValue}
                getValue={this.getHandleValue}
                handleInput={this.handleInput}
                addTask={this.addTask}
                getTaskValue={this.getTaskValue}
                removeTask={this.removeTask}
                setTaskValue={this.setTaskValue}
                handleInputCheckbox={this.handleInputCheckbox}
                lsPersonnel={this.getListPersonnel()}
            />
        )
    }
    switchComponent = () => {
        switch (this.state.component) {
            case "list":
                return this.renderListEvent();
            case "new":
                return this.renderNewEvent();
            default:
                return this.renderListEvent()
        }
    }


    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Event Plan"} displayLeftPanel={true}>

                {this.switchComponent()}
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(EventPlanningPage);
/*
0726297466
willycib@gmail.com

 */