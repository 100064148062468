import {SAVE_ROLE, FLEETMINDER_FAULT_LOAD_RECEIVE,FLEETMINDER_LOAD_RECEIVE} from "../constants";
import enquiry from "./reducer-all-data";


const initial = {
    EventPlans :[],
    EventDues:[],
    EventSchedules:[],
    EventWorkshops:[],
    Faults:[],
    Parts:[],
}

const maintenance = (state = initial, action) => {
    let newState = state;
    const data=action.data;
    switch (action.type) {
        case FLEETMINDER_LOAD_RECEIVE:
            console.log("REQ-2: FLEETMINDER_LOAD_RECEIVE :",action);
            if(typeof data ==="undefined"){
                return Object.assign({}, newState);
            }
            newState.EventPlans=data.EventPlan;
            return Object.assign({}, newState);
        case FLEETMINDER_FAULT_LOAD_RECEIVE:
            console.log("REQ-2: FLEETMINDER_FAULT_LOAD_RECEIVE :",action);

            if(typeof data ==="undefined"){
                return Object.assign({}, newState);
            }
            newState.Faults=data.Fault;
            return Object.assign({}, newState);

        default:
            return state
    }
}


export default maintenance;