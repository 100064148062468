import React, {useEffect, useRef, useState} from "react";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import Divider from "@material-ui/core/Divider/Divider";
import DriveEtaIcon from '@material-ui/icons/Person';

export default (props) => {
    let {colorIn, number, vehicle, image,expense,mileage,onClick,data,onClickViewVehicle} =props;
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);
    const styles={
        container:{
            minWidth:"100%",
            minHeight:150,
            border:"2px solid "+colorIn,
            borderRadius: 5,
            display:"flex",
            flexDirection:"row",
            flex:1,
            margin:10
        },
        boxDetail:{
            minWidth:"50%",
            maxWidth:"50%",
            minHeight:150,
            display:"flex",
            flex:1,
            justifyContent:"center",
            alignItems: "center",
        },
        boxScore:{
            minWidth:"50%",
            maxWidth:"50%",
            minHeight:160,
            maxHeight:160,
            display:"flex",
            flex:1,
            justifyContent:"center",
            alignItems: "center",
            backgroundColor:colorIn,
        },
        circle:{
            minWidth: dimensions.width / 2,
            maxWidth:dimensions.width / 2,
            minHeight:dimensions.width / 2,
            display:"flex",
            flex:1,
            flexDirection:"column",
            justifyContent:"center",
            alignItems: "center",
            borderRadius: dimensions.width / 4,
            backgroundColor: "white"
            /*minWidth:"50%",
            maxWidth:80,
            minHeight:80,
            display:"flex",
            flex:1,
            flexDirection:"column",
            justifyContent:"center",
            alignItems: "center",
            borderRadius: 40,
            backgroundColor: "white"*/
        },
        circleLabel1:{
            color: "lightblack",
            fontSize: 20,
            fontWeight: "bold"
        },
        circleLabel2: {
            fontSize: 12,
            color: "lightblack",
        },
        detailContent:{
            display:"flex",
            flex:3,
            flexDirection:"column",
        },
        detailContentBottom: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
            color: "#2196f3",
            fontSize: 12,
            cursor:"pointer"
        },
        detailContentHeader: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
            color: "black",
            fontWeight: "bold"
        },
        detailContentMiddle: {
            alignItems: "center",
            flex: 2,
            display: "flex",
            flexDirection:"column",
            padding:5,
            maxWidth:"100%"
        },
        detailContentMiddleInner:{
            alignItems: "center",
            justifyContent:"center",
            display: "flex",
            flexDirection:"row",
            minWidth:"100%",
        },
        detailContentMiddleInnerRight:{
            minWidth:"50%",
            color: "lightblack",
        },
        detailContentMiddleInnerLeft:{
            minWidth:"50%",
            textAlign:"right",
            color: "lightblack",
        }

    }

    return (
        <div style={styles.container}>
            <div style={styles.boxDetail}>
                <div style={styles.detailContent}>
                    <p style={styles.detailContentHeader}>
                        {vehicle}
                    </p>
                    <div style={styles.detailContentMiddle}>
                        <div style={styles.detailContentMiddleInner}>
                            <div style={{
                                ...styles.detailContentMiddleInnerRight,
                                maxWidth:"25%",
                                minWidth:"25%"
                            }}>
                                Fuel:
                            </div>
                            <div style={{...styles.detailContentMiddleInnerLeft,
                                maxWidth:"75%",
                                minWidth:"75%"
                            }}>
                                R{expense}
                            </div>
                        </div>
                    </div>
                    <div style={styles.detailContentMiddle}>
                        <div style={styles.detailContentMiddleInner}>
                            <div style={styles.detailContentMiddleInnerRight}>
                                Mileage:
                            </div>
                            <div style={styles.detailContentMiddleInnerLeft}>
                                {mileage}
                            </div>
                        </div>
                    </div>
                    <div style={styles.detailContentBottom}>
                        <Divider />
                        <div style={{flex:1,flexDirection:"row",minWidth:"100%"}}>
                            <DriveEtaIcon
                                onClick={()=>onClickViewVehicle(data)}
                                style={{
                                flex:1,
                                cursor:"pointer",
                                    color:"rgb(33, 150, 243)",
                                size:35,
                                alignItems: "center",
                                justifyContent:"center",
                                float:"left",
                                marginLeft:10
                            }}/>
                            <a
                                onClick={()=>onClick(data)}
                                style={{float:"right",marginRight:10}}
                            >
                                View Detail &nbsp;&nbsp;
                                <PlayCircleFilledWhiteIcon/></a>
                        </div>

                    </div>
                </div>
            </div>
            <div style={styles.boxScore}  ref={targetRef}>
                <div style={styles.circle}>
                    <div style={styles.circleLabel1}>{number}</div>
                    <div style={styles.circleLabel2}>R/Km</div>
                </div>
            </div>
        </div>
    )
}

