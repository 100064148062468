/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';



export default function ComboBox(props) {
    const {setSystemAppName}=props;
    /**
     *
     * Get the current schema
     */
    let currentSchema ={};
    let appName=props.navigations.currentApp;
    for(let i in props.navigations.schema){
        let row = props.navigations.schema[i];
        if(row.name===appName){
            currentSchema = row;
        }
    }

    const useStyles = makeStyles(theme => ({
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        inputRoot:{
            color:currentSchema.color,
            fontSize: 18,
        }
    }));
    const classes = useStyles();
    let options=[
        /*{name:"maintenance",title:"Maintenance"},
        {name:"fuel",title:"Fuel "},
        {name:"trip",title:"Trip Management"},
        {name:"reservation",title:"Reservation"}*/
    ];
    let indexSelected=0;
    let x=0;
    const listApp = props.navigations.schema;
    for(let i in listApp){
        let row = listApp[i];
        options.push(row);
        if(row.name ===props.navigations.currentApp){
            indexSelected   = x;
        }
        x++;
    }



    return (
        <Autocomplete
            classes={classes}
            size="small"
            id="System Module"
            options={options}
            defaultValue={options[indexSelected]}
            getOptionLabel={option => option.title}
            style={{ minWidth: "90%",maxWidth:"90%",minHeight:"90%" ,maxHeight:"75%",}}
            onChange={(e,val)=>{
                //console.log("RTTTT--> ",val.name)
                setSystemAppName(props,val.name)
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="System Module"
                    variant={"outlined" /*standard*/}
                    fullWidth
                    placeholder="Select your system module"
                    style={{
                        color:currentSchema.color,/*"orange"*/
                        fontWeight:"bold",
                        fontSize:20
                    }}
                />
            )}
        />
    );
}
