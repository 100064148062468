import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from "@material-ui/core/Divider";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StorageIcon from '@material-ui/icons/Storage';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listItem: {
        primary: {
            color: "blue"
        }
    },
    primary:{
        fontSize:18
    }
}));
const listStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    primary: {
        color: "#42a5f5",
        fontWeight: "bold",
        fontSize: 12
    },
    secondary: {
        fontSize: 10
    }
}));

export default (props) => {
    let {onChange, goTo,displayClose,displayInventory} = props;
    const classes = useStyles();
    const classesListItem = listStyles();

    const titleMessage = "Settings";
    const onCancel = () => {
        goTo("incomplete")
    }
    const nextComp = (rootName) => {
        goTo("incomplete")
    }
    const stylIcon={fontWeight:"bold",fontSize:38,color:"gray"};
    let lsOptionsMaintenance = [
        {
            primary: "User Management",
            secondary: "Set user of the sytem with login detail username and password",
            icon: (<PeopleAltIcon style={stylIcon}/>),
            component:"",
        },
        {
            primary: "Entity Setup",
            secondary: "System attribute required for start and all other procedure internal",
            icon: (<EditAttributesIcon  style={stylIcon}/>),
            component:"attribute",
        },
        {
            primary: "Entity Management",
            secondary: "database management through uploading or entering entity data",
            icon: (<StorageIcon  style={stylIcon}/>),
            component:"database",
        },
        {
            primary: "Link Entities",
            secondary: "Set your activities category and services use in your planing",
            icon: (<EventAvailableIcon  style={stylIcon}/>),
            component:"link",
        },
        {
            primary: "Inventory",
            secondary: "create your critical parts inventory list and price depend on the location offering",
            icon: (<ReceiptIcon  style={stylIcon}/>),
            component:"",
        },
    ];
    let lsOptionsFuel = [
        /*{
            primary: "User Management",
            secondary: "Set user of the sytem with login detail username and password",
            icon: (<PeopleAltIcon style={stylIcon}/>),
            component:"",
        },*/
        {
            primary: "Entity Setup",
            secondary: "System attribute required for start and all other procedure internal",
            icon: (<EditAttributesIcon  style={stylIcon}/>),
            component:"attribute",
        },
        {
            primary: "Entity Management",
            secondary: "database management through uploading or entering entity data",
            icon: (<StorageIcon  style={stylIcon}/>),
            component:"database",
        },
        {
            primary: "Link Entities",
            secondary: "Set your activities category and services use in your planing",
            icon: (<EventAvailableIcon  style={stylIcon}/>),
            component:"link",
        },
        /*{
            primary: "Inventory",
            secondary: "create your critical parts inventory list and price depend on the location offering",
            icon: (<ReceiptIcon  style={stylIcon}/>),
            component:"",
        },*/
    ];

    let lsOptions=[];
    let app = props.navigations.currentApp.toLocaleUpperCase();
    if(app==="FUEL"){
        lsOptions = lsOptionsFuel;
    }else{
        lsOptions = lsOptionsMaintenance;
    }


    if(typeof displayInventory !=="undefined"){
        if(displayInventory){
            lsOptions.push()
        }
    }

    let canDisplayClose=true;
    if(typeof displayClose !=="undefined"){
        canDisplayClose = displayClose;
    }
    return (
        <div className={classes.root} style={{minWidth: "100%"}}>

            <List dense={true} style={{minWidth: "100%"}}>
                <ListItem>

                    <ListItemText
                        classes={classes}
                        primary={titleMessage}
                        style={{color: "red", fontWeight: "bold", fontSize: 24}}
                        size={32}
                    />
                </ListItem>

            </List>

            <List className={classes.root} style={{minWidth: "100%"}}>
                {lsOptions.map((row,index)=>{
                    return (
                        <ListItem onClick={() => goTo(row.component)} style={{minWidth: "100%", cursor: "pointer"}}>
                            <ListItemAvatar>
                                {row.icon}
                            </ListItemAvatar>
                            <ListItemText
                                style={{minWidth: "100%"}}
                                primary={row.primary}
                                secondary={row.secondary}
                                classes={classesListItem}
                            />
                        </ListItem>
                    )
                })}

                {canDisplayClose ?
                    <ListItem>
                        <button
                            className={"btn btn-danger"}
                            onClick={() => onCancel()}
                        >Close
                        </button>
                    </ListItem> : null
                }
            </List>
        </div>
    );
}
