import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {GetUserToken} from "../../../api/fetching-data";
import {SERVER_CRM, SERVER_FLEETMINDER} from "../../../configures/constants";
import {
    PostTo,
} from "../../../api/fetching-data";
import Connector from "../../../redux/common/Connector";
import {fetchDataEntity, UpCaseFirst} from "../Settings/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {FetchDataCrm, FetchDataFleetminder, GetDisplayNameFromEntity} from "../../../api/fn";
import {Button, Modal,Form, Input, Select, Alert} from "antd";
import {TICKET_DATA_RECEIVED} from "../../../redux/constants";
import {findNameFromDatabase, GetEntityData} from "../../../api/entity-data";
import ControllerButton from "../../../components/Common/ControllerInput/ControllerButton";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import eConfig from "../../../configures";
import 'antd/dist/antd.css';
import ReactDOM from 'react-dom';
import ModalConfirm from "./ModalConfirm";
import { Radio } from 'antd';
const { confirm } = Modal;
const { TextArea } = Input;
const {Option} = Select;

class FuellogsQaHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Attributes: [],
            Entities: [],
            QaList: [],
            component: "list",
            isLoading: false,
            Module: "",
            selectedQaRecord:null,
            selectedQaStatus:"",
            qaComment:"",
            selectedStatus:"reject",
        }

        //this.eventEmitter =  window.addEventListener("nv-enter", this.handleNvEnter);
        var event = new Event('nv-enter');
        this.eventEmitter =new Event('nv-enter') ;
        window.addEventListener("nv-enter", this.handleNvEnter);
        //window.document.body.addEventListener("nv-enter", this.handleNvEnter);

    }

    componentDidMount = async () => {
        await this.loadInitialData();

    }
    onChangeStatus = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            selectedStatus: e.target.value,
        });
    };
    handleNvEnter=async (event)=>{
        console.log("Nv Enter:", event);
        await this.submitQa()

    }


    loadInitialData = async () => {
        await fetchDataEntity("Data", "collection", "collector", this);
        await fetchDataEntity("QaList", "collection", "filling-qa", this);
    }
    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange1 = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]: val,
        })
    }
    submitPost = async (hub, endpoint, successMsg) => {
        let _this = this;
        await FetchDataFleetminder(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }
    submitEntry = async (values) => {
        let _this = this;
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let hub = values;
        hub.Org = GetUserToken().OrgCode;
        let arr = hub.Ref.split("--");
        hub.Name = arr[1];
        hub.Ref = arr[0];
        let endpoint = "/collection/insert/collector";

        console.log("submitEntry *** send > ", hub);

        await PostTo(SERVER_FLEETMINDER, hub, endpoint, function (data) {
            console.log("submitEntry *** response > ", data);
            if (data !== null) {
                if (typeof data.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.component = "list";
                    tmp.newEntry = {};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }
        });

    }
    submitQa=async ()=>{
        //TODO let send our status
        const _this=this;
        let user = GetUserToken();
        let state = this.state;
        let record =state.selectedQaRecord;
        delete record.ActionReject;
        delete record.ActionApprove;
        delete record.Link;
        let hub={
            Org:user.OrgCode,
            Record:state.selectedQaRecord,
            Status:state.selectedQaStatus,
            Username:user.Username,
            Comment:state.qaComment,
        }

        //  qa-log

        let endpoint = "/collection/insert/qa-log";

        console.log("submitEntry *** send > ", hub);

        await PostTo(SERVER_FLEETMINDER, hub, endpoint, function (data) {
            console.log("qa submitEntry *** response > ", data);
            if (data !== null) {
                if (typeof data.RESULT !== "undefined") {
                    // alert("Thank you Qa Record submitted!");
                    let tmp = _this.state;
                    tmp.component = "list";
                    tmp.newEntry = {};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }
        });
    }

    onAddRecord = () => {
        this.setState({component: "form"})
    }
    onDelete = async (row) => {
        if (!window.confirm("Are you sure you want to remove this inbound queue ?")) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });
        let hub = {...row}
        let endpoint = "/collection/remove/collector";
        this.submitPost(hub, endpoint, "Tank you, User removed")
    }
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    documentView=(dropboxId)=>{
        const endpoint ="/global/download/"+dropboxId;
        const host =SERVER_CRM;
        const server = eConfig[host];
        let url = server + "" + endpoint;

        let link =url;
        document.title = "Qa Receipt";
        window.open(link, '_blank');
    }
    documentDownload=(dropboxId)=>{
        const endpoint ="/global/download/"+dropboxId;
        const host =SERVER_CRM;
        const server = eConfig[host];
        let url = server + "" + endpoint;

        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a'); // create html element anchor
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = "receipt.jpg"; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();

    }
    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        let columns = [
            {id: "Vehicle", label: "Vehicle"},
            {id: "Cost", label: "Cost"},
            {id: "Quantity", label: "Quantity"},
            {id: "CostUnit", label: "CostUnit"},
            {id: "Mileage", label: "Mileage"},
            {id: "RefuelDate", label: "Refuel Date"},
            {id: "QaStatus", label: "Qa Status"},
            {id: "Link", label: "Receipt"},
            {id: "QaDate", label: "Qa Date"},
            {id: "QaTime", label: "Qa Time"},
            {id:"User",label:"Driver"},
            {id: "QaComment", label: "Comment"},
        ]
        let ls = [];
        const state = this.state.QaList;
        for (let i in state) {
            let row = state[i];
            if(row.QaStatus!==this.state.selectedStatus){
                continue;
            }
            row.User =GetDisplayNameFromEntity(row.Driver,this.props.allData)
            row.Link=(
                <div style={{
                    color:"blue",
                    flex:1,
                    flexDirection:"row",
                    justifyContent:"space-around",
                    fontSize:18,
                }}>
                    <a onClick={()=>this.documentView(row.ImageReceipt)}>View</a>&nbsp;|&nbsp;
                    <a onClick={()=>this.documentDownload(row.ImageReceipt)}>Download</a>
                </div>
            )
            //todo some futures

            console.log("**** this.record > ", row)

            ls.push(row)
        }



        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Qa List")}
                    /*onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}*/
                    noAddButton={true}
                />
            </GridItem>
        )
    }


    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Qa logs"} displayLeftPanel={true}>

                <div style={{marginLeft:20}}>
                    <div className="row">
                        <div className="col col-xs-12">
                            <Radio.Group onChange={this.onChangeStatus} value={this.state.selectedStatus}>
                                <Radio value={"reject"}>Reject</Radio>
                                <Radio value={"approve"}>Approve</Radio>
                                {/*<Radio value={""}>Pending</Radio>*/}
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">

                            {this.renderList()}
                        </div>
                    </div>
                </div>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(FuellogsQaHistory);
