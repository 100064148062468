import React from "react";
import MyChartExective0 from "../../../components/Pages/fuel/ChartFleetDashboard0";
export class InnerChart1 extends React.Component{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.renderChart();

        setTimeout(()=>{
            Array.from(document.getElementsByClassName("highcharts-credits"))
                .forEach(element => element.remove());

        },10)

    }
    renderChart=()=>{
        const props = this.props;
        MyChartExective0({
            divId: props.divId,
            categories: props.chartCategories,
            axeX1: props.axeX1,
            axeX2: props.axeX2,
            labelX1: props.labelX1,
            labelX2: props.labelX2,
            maxSoft: props.maxSoft,
            titleChart:props.cTitle,
            haveMultipleAxes:props.multipleAxe,
        });
    }

    render() {
        const props = this.props;
        return (
            <div
                id={props.divId}
                style={{
                    minWidth:"100%",
                    maxWidth:"100%",
                    backgroundColor:"white",
                    marginTop:20
                }}>
                &nbsp;
            </div>
        );
    }
}

/*
<text x="590" class="highcharts-credits" text-anchor="end" data-z-index="8" style="cursor:pointer;color:#999999;font-size:9px;fill:#999999;" y="395" aria-label="Chart credits: Highcharts.com" aria-hidden="false">Highcharts.com</text>
 */