
import  React from 'react';



import {
    PostTo,
} from "./fetching-data";

import {SERVER_RESERVATION} from "../configures/constants";

export default function(ComposedComponent){
    class Authenitcate extends React.Component{


        constructor(props) {
            super(props);
            this.state = {}
        }


        componentWillMount=async ()=>{

           await this.checkLoginNow();
        }


        checkLoginNow=async ()=>{
            let endpoint = "/user/detail";
            let hub={};
            await PostTo(SERVER_RESERVATION,hub, endpoint, function (data) {
                if(data ===null){
                    return
                }
                if(typeof data.error !=="undefined"){
                    if( data.error !==""){
                        alert("Unauthorised access to this resource, Please login!")
                        window.location.href = "#/login"
                    }
                }

            });

        }

        render(){
            return(
                <ComposedComponent {...this.props} />
            );
        }
    }

    return Authenitcate ;
}
