import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import React from "react";
import InputBox from "../../Common/ControllerInput/ControllerInputBox";
import InputButton from "../../Common/ControllerInput/ControllerButton";
import {MyBoxSelecttion} from "../SetupAssociation/fn";

export default (props) => {
    let {handleInput2, ls, onSubmit, onCancel} = props;
    let fields = [

        {id: "newLitter", label: "Manufacture spec (Ltrs/100 Km)"},
        {id: "newAllowance", label: "Operation Allowance (Rand/Km)"},
        {id: "newTakeOnMileage", label: "Take On Mileage"},

        {id: "newCurrentFuelPrice", label: "Current Fuel Price"},
        {id: "newRatioUpValue", label: "Ratio Up Value"},
        {id: "newRatioLowerValue", label: "Ratio Lower Value"},

    ]



    return (
        <GridContainer style={{marginTop: 50, paddingRight: 20, paddingBottom: 30}}>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                    New Spec
                </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{marginBottom: 10}}>
                <MyBoxSelecttion
                    {...props}
                    label={"Vehicle"}
                    name={"newVehicle"}
                    ls={ls}
                    noImage={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {fields.map((row, index) => {
                    return (
                        <InputBox
                            name={row.id}
                            handleInput2={handleInput2}
                            required={true}
                            label={row.label}
                            styleIn={{minWidth: "100%"}}
                            {...props}
                        />
                    )
                })}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>

                <InputButton
                    label={"Submit"}
                    variant={"contained"}
                    onClick={onSubmit}
                    styles={{float: "right"}}
                    color={"primary"}
                />
                <InputButton
                    label={"Cancel"}
                    variant={"outlined"}
                    onClick={onCancel}
                    styles={{float: "right"}}
                    color={"secondary"}
                />
            </GridItem>

        </GridContainer>
    )
}