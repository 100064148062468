import React from "react";
import {FLEETMINDER_LOAD_DATA,FLEETMINDER_DELETE_DATA,FLEETMINDER_SAVE_DATA} from "../constants";

const LoadDataMaintenance =  () => {
    return {
        type: FLEETMINDER_LOAD_DATA,
    }
}

const SaveMaintenanceMiddleware=(payload)=>{
    return { ...payload,
        type: FLEETMINDER_SAVE_DATA,
    }
}

const DeleteMaintenaceMiddleware=(payload)=>{
    return { ...payload,
        type: FLEETMINDER_DELETE_DATA,
    }
}
export {
    LoadDataMaintenance,
    SaveMaintenanceMiddleware,
    DeleteMaintenaceMiddleware,
}
