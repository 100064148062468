import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, DatePicker} from 'antd';


export const RatioPopup=({data,_this})=>{

    let visible = _this.state.showRatioInfo;
    const onClose=()=>{
        _this.setState({showRatioInfo: false})
    }

    return(
        <>
            <Drawer
                title={"Ratio Information Categories!"}
                width={720}
                onClose={onClose}
                visible={visible}
                bodyStyle={{paddingBottom: 80, overflow: "scroll"}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={onClose} style={{marginRight: 8}}>
                            Close
                        </Button>
                    </div>
                }
            >
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >

                    <Row>
                        <Col span={5} ></Col>
                        <Col span={6} style={{...styles.header,color:"green"}}>Good</Col>
                        <Col span={7} style={{...styles.header,color:"orange"}}>Satisfactory</Col>
                        <Col span={6} style={{...styles.header,color:"red"}}>Bad </Col>
                    </Row>
                    {data.map(row=>{
                        return <Row>
                            <Col span={5}  style={styles.body}>{row.Category}</Col>
                            <Col span={6}  style={{...styles.body,color:"green"}}>{row.Good}</Col>
                            <Col span={7}  style={{...styles.body,color:"orange"}}>{row.Satisfactory}</Col>
                            <Col span={6}  style={{...styles.body,color:"red"}} >{row.Bad}</Col>
                        </Row>
                    })}

                </div>
            </Drawer>
        </>
    )
}

const styles={
    header:{
        color:"red",
        fontWeight:"bold",
        border:"1px solid black",
        fontSize:18,
        textAlign:"left",
        paddingLeft:10,
    },
    body:{
        color:"gray",
        fontWeight:"bold",
        border:"1px solid black",
        fontSize:18,
        textAlign:"left",
        paddingLeft:10,
    }
}