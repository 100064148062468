import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";
import InputButton from "./ControllerButton";

export default (props) =>{
    let {name,name2,label,label2,handleInput2,btnLabel,onSubmit,variant,color}=props;
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date(/*'2014-08-18T21:11:54'*/));
    const [selectedDate2, setSelectedDate2] = React.useState(new Date(/*'2014-08-18T21:11:54'*/));

    const convertDateFormat_YYYYMMDD = (datesIn) => {
        let myDate = moment(datesIn).format("YYYY-MM-DD");
        return myDate;
    }
    const handleDateChange1 = date => {
        setSelectedDate(date);
        handleInput2(name,convertDateFormat_YYYYMMDD(date))
    };
    const handleDateChange2 = date => {
        setSelectedDate2(date);
        handleInput2(name2,convertDateFormat_YYYYMMDD(date))
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
                {/*<KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date picker inline"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />*/}
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={label}
                    format="yyyy-MM-dd"
                    value={selectedDate}
                    onChange={handleDateChange1}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={label2}
                    format="yyyy-MM-dd"
                    value={selectedDate2}
                    onChange={handleDateChange2}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <InputButton
                    label={btnLabel}
                    variant={variant}
                    onClick={onSubmit}
                    styles={{float: "right",marginTop: 15}}
                    color={color}
                />

                {/*<KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                />*/}
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
