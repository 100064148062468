import React from 'react';
import {makeStyles, Paper} from "@material-ui/core";
import FormEditInputSelect from "../../Common/form-input-control/FormEditInputSelect";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import AvailableList from "./StepAvailableList";

import {MyBoxSelecttion} from "./fn";

export default (props) => {
    let {handleInput, getHandleValue, getEntityData, state} = props;

    const classes = useStyles();
    let dataMain = [];
    let dataSupplier = [];
    const isInArray = (arr, item) => {
        for (let i in arr) {
            const row = arr[i];
            if (row.key === item) {
                return true
            }
        }
        return false
    }

    for (let i in state.modules) {
        const item = state.modules[i];
        if (state.MainEntity !== item) {
            dataSupplier.push({
                key: item,
                val: item
            })
        }
        dataMain.push({
            key: item,
            val: item
        })
    }

    return (
        <Paper style={{paddingLeft: 135, paddingRight: 135}}>
            <h4 style={{marginLeft: 135, color: "#2196f3", fontSize: 16}}>Link Entity  Choose...</h4>

            <FormControl component="fieldset" className={classes.formControl + " fieldset " + classes.margin}>
                <FormLabel component="legend" className={"legend"}>
                    <span style={{
                        fontSize: 16,
                        fontWeight:"normal"
                    }}>Link Entity</span>
                </FormLabel>

                <GridContainer style={{marginTop: 50}}>
                    <GridItem xs={6} sm={6} md={6}>
                        <MyBoxSelecttion
                            {...props}
                            label={"Main"}
                            name={"MainEntity"}
                            ls={dataMain}
                            noImage={false}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <MyBoxSelecttion
                            {...props}
                            label={"Target"}
                            name={"SupplierEntry"}
                            ls={dataSupplier}
                            noImage={false}
                        />

                    </GridItem>
                </GridContainer>
            </FormControl>

        </Paper>
    )
}


const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));
