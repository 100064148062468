import React, {useEffect, useRef, useState} from "react";

export default ({data,borderColor}) => {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({width: 0, height: 0});
    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);


    const StyleContainer= {
        minWidth: "100%",
        minHeight: (dimensions.width * 2) / 2,
        border: "2px solid "+borderColor,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        margin: 10,
        borderRadius: 20,
        padding:10
    };
    const styleText1={
        textAlign:"left",
        minWidth:"30%",
        maxWidth:"30%"
    }
    const styleText2={
        textAlign:"right",
        minWidth:"70%",
        maxWidth:"70%"
    }
    const styleBox={
        display:"flex",flex:1,flexDirection:"row",minWidth:"100%"
    }

    return (
        <div style={StyleContainer}>
            {data.map((row)=>{
                return(
                    <div style={styleBox}>
                        <div style={styleText1}>{row.key}</div>
                        <div style={styleText2}>{row.val}</div>
                    </div>
                )
            })}
            {/*<div style={styleBox}>
                <div style={styleText1}><b>Fleet:</b></div>
                <div style={styleText2}><b>12</b></div>
            </div>
            <div style={styleBox}>
                <div style={styleText1}>Good:</div>
                <div style={styleText2}>83.3%</div>
            </div>
            <div style={styleBox}>
                <div style={styleText1}>Satisfactory:</div>
                <div style={styleText2}>16.7%</div>
            </div>
            <div style={styleBox}>
                <div style={styleText1}>Bad:</div>
                <div style={styleText2}>0.0%</div>
            </div>*/}
        </div>
    )
}