import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepChooseEntity from "./StepChooseEntity";
import StepAvailableList from "./StepAvailableList";
import StepSave from "./StepSave";
import {FindImages, sortData} from "../../../api/fn";
import {GetUserToken} from "../../../api/fetching-data";

export default class SetupAssociation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            MainEntity: "",
            SupplierEntry: "",
            main: "",
            supplier: "",
            modules: ["vehicle", "driver", "supplier", "personal", "location"]
        }
    }

    handleInput = (row, e) => {
        if(typeof e ==="undefined"){
            console.log("handleInput unD ^*-> ", row,e)
            return
        }
        const key = e.target.name;
        const val = e.target.value;
        this.setState({
            [key]: val
        })
        console.log("handleInput ^*-> ", key,val)
    }
    handleInput2 = (key, val) => {
        /*if(typeof e ==="undefined"){
            console.log("handleInput unD ^*-> ", row,e)
            return
        }
        const key = e.target.name;
        const val = e.target.value;*/
        this.setState({
            [key]: val
        })
        console.log("handleInput ^*-> ", key,val)
    }
    getHandleValue=(key)=>{
        return this.state[key];
    }
    getDisplayNameEntity=(module,record)=>{
        const fieldList=this.getListDisplayField(module);
        let names="";
        for(let  i in fieldList){
            const row=fieldList[i];
            names +=record.data[row.fieldname]+" "
        }
        return names
    }
    getListDisplayField=(module)=>{
        let ls = [];
        const attributes = this.props.allData.Attributes;
        for (let i in attributes) {
            const row = attributes[i];
            if (row.module === module) {
                if(row.display){
                    ls.push(row)
                }
            }
        }

        ls =sortData("displayposition",ls);
        return ls
    }
    getEntityData = (module) => {
        let ls = [];
        const Databases = this.props.allData.Databases;

        for (let i in Databases) {
            const row = Databases[i];
            if (row.module === module) {
                ls.push({
                    key: row.ref,
                    val: this.getDisplayNameEntity(module,row),
                    data: row.data
                })
            }
        }


        return ls
    }

    buildAssociationData=()=>{
        const Databases = this.props.allData.Databases;
        const files = this.props.allData.Files;
        const StoreAssociations = this.props.allData.Associations;
        let associations=[];
        let self = this;

        const GetRecord=(module,ref)=>{
            let names = "---";
            for (let i in Databases) {
                const row = Databases[i];
                if (row.module === module && row.ref===ref) {
                    names = self.getDisplayNameEntity(module,row)
                }
            }
            return names;
        }

        for(let i in StoreAssociations){
            let row=StoreAssociations[i];
            /**
             * Get Names of participants
             */
            row.mainname  =GetRecord(row.mainentity,row.main);
            row.suppliername  =GetRecord(row.supplierentity,row.supplier);
            /**
             * Get Image profile link
             */
            row. mainimg =FindImages(row.main, files);
            row. supplierimg =FindImages(row.supplier, files);
            /**
             * save into array
             */
            associations.push(row);
        }

        return associations;

    }

    submitNewLink=()=>{
        if(!window.confirm("Are you sure that you want to submit this Link?")){
            return
        }
        const payload={
            Org:GetUserToken().OrgCode,
            MainEntity     :this.state.MainEntity,
            SupplierEntity :this.state.SupplierEntry,
            Main           :this.state.main,
            Supplier       :this.state.supplier
        }
        this.props.SaveGlobalMiddleware({
            module:"association",
            payload:payload
        })
    }

    onDeleteRecord=(row)=>{
        if(!window.confirm("Are you sure that you want to delete this link?")){
            return
        }
        const payload={...row}
        const module = "association";
        this.props.DeleteGobalMiddleware({
            payload,module
        })
    }


    render() {
        return (
            <MainStepper
                {...this.props}
                handleInput={this.handleInput}
                handleInput2={this.handleInput2}
                getHandleValue={this.getHandleValue}
                getEntityData={this.getEntityData}
                state={this.state}
                submitNewLink={this.submitNewLink}
                associations={this.buildAssociationData()}
                onDeleteRecord={this.onDeleteRecord}
            />
        )
    }

}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Choose Entity', 'Add New & List'];
}

function getStepContent(stepIndex, props) {
    switch (stepIndex) {
        case 0:
            return <StepChooseEntity {...props} />;
        case 1:
            return <StepAvailableList {...props}/>;
        default:
            return 'Unknown stepIndex';
    }
}

const MainStepper = (props) => {
    let {goTo} = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <Fragment>
                        <Typography className={classes.instructions}>{getStepContent(activeStep, props)}</Typography>
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Back
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{float: "right"}}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                        <div style={{marginTop: 150}}>
                            <Button variant="contained" color="secondary" onClick={() => goTo("menu")}>
                                Close & Finish
                            </Button>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
}
