import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import {makeStyles} from "@material-ui/core";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StorageIcon from '@material-ui/icons/Storage';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listItem: {
        primary: {
            color: "blue"
        }
    },
    primary: {
        fontSize: 18
    }
}));
const listStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    primary: {
        color: "#42a5f5",
        fontWeight: "bold",
        fontSize: 12
    },
    secondary: {
        fontSize: 10
    }
}));


class EventPlanningPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            component: "menu"
        }

    }

    componentDidMount() {
        //todo

    }


    render() {

        const styleIcon = {fontWeight: "bold", fontSize: 38, color: "gray"}
        let lsOptions = [
            {
                primary: "Maintenance Event Planning",
                secondary: "Set event planning from vehicle manufacture specification or inspection or emergency fix ",
                icon: (<PeopleAltIcon style={styleIcon}/>),
                component: "operations-event-plan",
            },
            {
                primary: "Fault Logging",
                secondary: "Report any fault related to the vehicle when arrived",
                icon: (<EditAttributesIcon style={styleIcon}/>),
                component: "operations-event-plan",
            },
            {
                primary: "Maintenance Event Due",
                secondary: "List of the event due based on the mileage or period planned",
                icon: (<StorageIcon style={styleIcon}/>),
                component: "operations-event-plan",
            },
            {
                primary: "Maintenance Job Schedule",
                secondary: "Schedule a job for workshop from event due ",
                icon: (<EventAvailableIcon style={styleIcon}/>),
                component: "operations-event-plan",
            },{
                primary: "Workshop (Job Card)",
                secondary: "Schedule a job for workshop from event due ",
                icon: (<EventAvailableIcon style={styleIcon}/>),
                component: "operations-event-plan",
            },{
                primary: "Expense",
                secondary: "Schedule a job for workshop from event due ",
                icon: (<EventAvailableIcon style={styleIcon}/>),
                component: "operations-event-plan",
            },
        ];

        const goTo=(link)=>{
            window.location.href = "#"+link;
        }
        const onCancel=()=>{
            window.location.href = "#/operations-menu";
        }
        return (
            <Wallpaper {...this.props} pageTitle={"Operation Menu"} displayLeftPanel={false}>

                <InnerConter
                    {...this.props}
                    goTo={goTo}
                    onCancel={onCancel}
                    lsOptions={lsOptions}
                />
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(EventPlanningPage);

const InnerConter=(props)=>{
    let {lsOptions,goTo,onCancel}=props;
    const classes = useStyles();
    const classesListItem = listStyles();

    return(
        <div className={classes.root} style={{minWidth: "100%"}}>

            <List dense={true} style={{minWidth: "100%"}}>
                <ListItem>

                    <ListItemText
                        classes={classes}
                        primary={"Operations"}
                        style={{color: "red", fontWeight: "bold", fontSize: 24}}
                        size={32}
                    />
                </ListItem>

            </List>

            <List className={classes.root} style={{minWidth: "100%"}}>
                {lsOptions.map((row, index) => {
                    return (
                        <ListItem onClick={() => goTo(row.component)}
                                  style={{minWidth: "100%", cursor: "pointer"}}>
                            <ListItemAvatar>
                                {row.icon}
                            </ListItemAvatar>
                            <ListItemText
                                style={{minWidth: "100%"}}
                                primary={row.primary}
                                secondary={row.secondary}
                                classes={classesListItem}
                            />
                        </ListItem>
                    )
                })}

               {/* <ListItem>
                    <button
                        className={"btn btn-danger"}
                        onClick={() => onCancel()}
                    >Close
                    </button>
                </ListItem>*/}
            </List>
        </div>
    )
}


