import { FUEL_DATA_RECEIVED} from "../constants";


const initial = {
    Specs: [],
    Fillings: [],
    Usages:{}
}


const fuel = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case FUEL_DATA_RECEIVED:
            //console.log("REQ-2: FUEL_DATA_RECEIVED :", action);
            const data = action.data;
            if (typeof data === "undefined") {
                return Object.assign({}, newState);
            }
            newState.Specs = data.Specs;
            newState.Fillings = data.FillingFuel;
            //newState.Usages = data.Usages;
            return Object.assign({}, newState);
        default:
            return state
    }

}

export default fuel;