import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default ({clickAdd}) =>{
    const classes = useStyles();
    return (
        <div className={classes.root} style={{
            marginTop:5,
            marginBottom:150,
            minWidth:"100%"
        }}>
            <Fab color="primary" aria-label="add" onClick={()=>clickAdd("")} style={{
                float:"right"
            }}>
                <AddIcon />
            </Fab>
        </div>
    );
}
