import React, {Fragment} from "react";
import GridContainer from "../dashboard/Grid/GridContainer";
import GridItem from "../dashboard/Grid/GridItem";
import ControllerButton from "../ControllerInput/ControllerButton";
import TableDataView from "../tables/TableDataView";


const FleetDashboardVehicleView=({
                                     closeViewMonthly,state,makeFlag,styles,FloatMyFixed,
                                     getFleetIndexActualFlagColor,monthlyInfo,
                                     renderChartBuilder,fleetIndexValue,fleetIndexColor,
                                     findFlagColor
})=>{

    if(state.component !== "vehicle" ){
        return null
    }
    const analyseInfoData=()=>{
        let data=[];
        let info = monthlyInfo
        for(let i in monthlyInfo.data.data){
            let row = monthlyInfo.data.data[i];
            //row.Expense = parseFloat(row.Expense)
            data.push(row)
        }
        info.data.data  = data
        return monthlyInfo.data
    }
    const getFleetIndexValueColor = () => {
        let totalAll = 0, totalRed = 0, totalOrange = 0, totalGreen = 0;
        let totalCost = 0, totalDistance = 0, actual = 0;
        let data = state.DataAll.Fleet;
        for (let i in data) {
            const row = data[i];
            totalAll++;
            totalCost += parseFloat(row.Expense);
            totalDistance += parseFloat(row.Distance);
        }
        actual = totalCost / totalDistance;
        let flagColor = findFlagColor(FloatMyFixed(actual, 2), 8, 6)

        //console.log("actual (:)---> ",actual, ` ${totalCost} / ${totalDistance} `)
        return {actual, flagColor}

    }
    const monthColor = state.selectedMonthIndex.flagColor;
    const monthActual = state.selectedMonthIndex.actual;
    let {actual, flagColor} = getFleetIndexValueColor();
    console.log("monthlyInfo > ",monthlyInfo)
    return(
        <Fragment>
            <GridContainer style={{minWidth: "100%"}}>
                <GridItem xs={12} sm={12} md={2}>
                    <ControllerButton
                        variant="outlined"
                        label={"Close Vehicle View"}
                        color={"secondary"}
                        onClick={closeViewMonthly}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={5} style={styles.b3BoxLeft}>
                    <span style={styles.b3Title}>Vehicle Index: </span>
                    <span style={{color: monthColor, fontSize: 40}}>
                                        {monthActual}
                                    </span>
                    &nbsp;<span
                    style={{fontSize: 20, color: monthColor,}}>R/Km</span>
                    <span
                        style={{marginLeft: 15,}}>
                                        {makeFlag(monthColor, 52)}
                                    </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={styles.b3BoxRight}>
                    <span style={styles.b3Title}>Fleet Index: </span>
                    <span style={{color: flagColor, fontSize: 40}}>
                                        {FloatMyFixed(actual, 2)}
                                    </span>
                    &nbsp;<span
                    style={{fontSize: 20, color: fleetIndexColor,}}>R/Km</span>
                    <span
                        style={{marginLeft: 15,}}>
                                        {makeFlag(flagColor, 52)}
                                    </span>
                </GridItem>
            </GridContainer>

            <GridContainer style={{minWidth: "100%"}}>

                <GridItem xs={12} sm={12} md={7}>
                    <TableDataView
                        {...analyseInfoData()}
                        tableTitle={(<span style={{
                            color: "#2196f3",
                            fontWeight: "bold",
                            fontSize: 18
                        }}>{monthlyInfo.title}</span>)}
                        otherFunctions={[]}
                        options={{pageSize: 12}}
                        noAddButton={true}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={5} style={{
                    background: "lightgray",
                    marginRight: 20,
                    minWidth: "40%",
                    maxWidth: "40%"
                }}>
                    <div style={styles.chart}>
                        {renderChartBuilder(1, "chart1", "Fuel Spend and Mileage", true)}
                    </div>
                    <div style={styles.chart}>
                        {renderChartBuilder(2, "chart2", "Fuel Efficiency Index", false)}
                    </div>
                </GridItem>
            </GridContainer>
        </Fragment>
    )
}

export default FleetDashboardVehicleView;