import React, {Fragment} from "react";
import Chart from "react-google-charts";
import moment from "moment";

// https://react-google-charts.com/line-chart#multiple-line-types
class ChartMonthlyOverview3 extends React.Component {
    constructor(props) {
        super(props);
    }

    findHighestValue=(arr,scaleTo)=>{
        arr.sort();
        let index  = arr.length -1;
        return arr[index] + scaleTo
    }


    buildData = () => {
        let {data} = this.props;
        let mileages = [];
        let costs = [];
        let info=[];
        let myArrCost=[];
        let myArrMileage=[];


        info.push([
            {type: 'string', label: ''},
            {label:'Fuel Cost'},
            {label:'Distance Mileage'},
        ])
        let x=0;

        for (let i in data) {
            const row = data[i];
            const arr = row.date.split("-")
            const year = parseInt(arr[0])
            const month = parseInt(arr[1]);
            const date = new Date(year, month,1);
            let mDate = moment(date).format("MMM-YY").toString();

            const o=[mDate, parseFloat(row.cost), parseInt(row.mileages)];
            info.push(o);
            myArrCost.push(parseFloat(row.cost));
            myArrMileage.push(parseInt(row.mileages));

            x++;
        }

        //todo let find highest value in array
        const maxValueCost =this.findHighestValue(myArrCost, 20000) ;
        const maxValueMileage =this.findHighestValue(myArrMileage, 1000) ;

        return{
            data:info,
            maxValueCost: maxValueCost,
            maxValueMileage: maxValueMileage
        }

        //return info;
    }


    render() {
        const {data,maxValueCost,maxValueMileage} = this.buildData();
        return (
            <Chart
                pointSize={30}
                width="100%"
                height="400px"
                chartType="Line"
                loader={<div>Loading Chart</div>}
                data={data}
                legend={"none"}
                options={{
                    chart: {
                        title: 'Monthly usage Throughout the period range',
                        pointSize: 30,
                        pointShape: 'square',
                        legend:"none"
                    },
                    series: {
                        // Gives each series an axis name that matches the Y-axis below.
                        0: {axis: 'Cost', pointSize: 4},
                        1: {axis: 'Mileage', pointSize: 4},
                    },
                    axes: {
                        // Adds labels to each axis; they don't have to match the axis names.
                        y: {
                            Cost: {
                                label: 'Cost (Rand)',
                                range: {
                                    max: maxValueCost,
                                    min: 0,

                                },
                                format:"none",
                                pointSize: 30,
                            },
                            Mileage: {
                                label: 'Mileage (Km)',
                                range: {
                                    min: 0,
                                    max: maxValueMileage,
                                },
                                line:{
                                    pointSize: 30,
                                }

                            },
                            all: {
                                pointSize: 30,
                            }
                        }
                    },
                }}
                rootProps={{'data-testid': '4'}}
            />
        )


    }
}


export default ChartMonthlyOverview3;