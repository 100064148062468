import React from "react";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import {CheckList, MessageContent, ProgressBar} from "./index";
import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import {GetUserToken} from "../../../api/fetching-data";


const styles={
    box1:{
        marginTop: 35,
    }
}


export default (props)=>{
    let {goTo,allData}=props;
    let {Attributes}=allData;

    let completedList=[];
    let incompletedList=[];
    let appName ="maintenance";
    let categories=["vehicle","driver","personal","supplier","location"];
    let org =GetUserToken().OrgCode;
    for(let i in categories){
        let item=categories[i];
        let boo=false;
        for(let a in Attributes){
            const row = Attributes[a];
            if(row.module===item && org===row.org){
                boo=true
            }
        }

        if(boo){//module setup ready
            completedList.push(item)
        }else{//module not setup yet
            incompletedList.push(item)
        }


    }

    const total=completedList.length + incompletedList.length;
    let now= (completedList.length / total )*100;
    now  =now.toFixed(0);
    let barColor = "danger";
    if(now >=50 && now <=70){
        barColor="warning"
    }
    if(now >=71 && now <=90){
        barColor="primary"
    }

    return(
        <GridContainer style={{marginTop:50}} >
            <GridItem xs={12} sm={6} md={6} alignItems="center" style={styles.box1}>
                <ProgressBar {...props} style={{marginTop:25,marginBottom:15}} now={now} color={barColor}/>
                <br/>
                <MessageContent {...props}/>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} alignItems="center" >
                <CheckList {...props} completedList={completedList} incompletedList={incompletedList}/>
            </GridItem>
        </GridContainer>
    )
}
