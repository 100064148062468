import GridContainer from "../../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../../Common/dashboard/Grid/GridItem";
import React from "react";
import SelectBox from "./ControllerSelectBox";
import InputBox from "./ControllerInputBox";
import ControllerRadioButton from "./ControllerRadioButton";


export default (props)=>{
    let {handleInput,handleInput2, getHandleValue}=props;
    const renderBox=(name,label,ls)=>{
        return(
            <SelectBox
                name={name}
                ls={ls}
                handleInput={handleInput}
                getHandleValue={getHandleValue}
                label={label}
                {...props}
            />
        )
    }

    return(
        <GridContainer style={{marginTop: 50,paddingRight:20,paddingBottom:30}}>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                    Step: 3 Reminder
                </h4>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <ControllerRadioButton
                    name={"newEventReminderApply"}
                    label={"Apply Reminder"}
                    options={[{key:true,val:"Yes"},{key:false,val:"No"}]}
                    {...props}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {renderBox("newEventReminderUnit","Reminder Unit",[
                    {key:"day",val:"Day"},
                    {key:"month",val:"Month"},
                    {key:"year",val:"Year"},
                ])}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <InputBox
                    name={"newEventReminderValue"}
                    handleInput2={handleInput2}
                    required={true}
                    label={"Reminder Value"}
                    styleIn={{minWidth:"100%"}}
                    type={"number"}
                    {...props}
                />
            </GridItem>

        </GridContainer>
    )
}