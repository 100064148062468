import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import GridItem from "../dashboard/Grid/GridItem";
import GridContainer from "../dashboard/Grid/GridContainer";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

function generate(element) {
    return [0, 1, 2].map(value =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

const RenderList = ({listEntry, removeItem}) => {
    const classes = useStyles();
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    return (
        <div className={classes.root}>
            <Grid container spacing={2} style={{minWidth:"100%"}}>

                <Grid item xs={12} md={12}>
                    <div className={classes.demo} >
                        <List dense={dense} style={{minWidth:"100%"}}>
                            {listEntry.map((item,index)=>{
                                return(
                                    <ListItem >
                                        <ListItemText
                                            primary={item}
                                        />
                                        <ListItemSecondaryAction onClick={()=>removeItem(item)}>
                                            <IconButton edge="end" aria-label="delete">
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}

                        </List>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default class FormEditInputOption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            newKey: ""
        }
    }

    handleInput = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        this.setState({
            [key]: val
        })
    }
    addKey = () => {
        let tmp = this.state;
        if (tmp.newKey === "") {
            return alert("Sorry you can't this action , provide your key")
        }
        tmp.list.push(tmp.newKey);
        this.setState(tmp);
        this.props.onChangeList(this.state.list,undefined);
    }
    removeKey = (item) => {
        let tmp = this.state;
        let ls = [];
        for (let i in tmp.list) {
            const entry = tmp.list[i];
            if (entry === item) {
                continue
            }
            ls.push(entry)
        }
        tmp.list = ls;
        this.setState(tmp);
    }


    render() {

        return (
            <MyListBox
                {...this.props}
                handleInput={this.handleInput}
                addKey={this.addKey}
                removeKey={this.removeKey}
                state={this.state}
            />
        )
    }

}

const MyListBox = (props) => {
    let {row,handleInput,removeKey,addKey,state} = props;

    const classes = useStyles();
    let styleIn = {};
    return (
        <FormControl component="fieldset" className={classes.formControl + " fieldset " + classes.margin}
                     style={styleIn}>
            <FormLabel component="legend" className={"legend"}>{row.label}</FormLabel>
            <GridContainer style={{minWidth: "95%",paddingRight:20} }  alignItems="center">
                <GridItem xs={10} sm={10} md={10} alignItems="center">
                    <TextField
                        id="outlined-basic"
                        label="Outlined"
                        variant="outlined"
                        name={"newKey"}
                        onChange={(e) => handleInput(e)}
                        style={{minWidth: "100%",maxHeight:5}}
                    />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}  style={{}}>

                    <Button
                        variant="contained"
                        color="default"
                        onClick={() => addKey()}
                        style={{minWidth: "100%",maxHeight:75,marginTop:45,left:1,float:"left"}}
                    >
                        Add
                    </Button>&nbsp;&nbsp;
                </GridItem>
            </GridContainer>

            <GridContainer style={{minWidth: "100%"}}>
                <GridItem xs={12} sm={12} md={12} alignItems="center">
                    <RenderList listEntry={state.list} removeItem={removeKey}/>
                </GridItem>
            </GridContainer>

        </FormControl>
    )
}
