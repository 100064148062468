import React, {Component, Fragment} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import {FetchDataFleetminder, FindImages, GetDisplayNameFromEntity, getYTD, ToMonthYear} from "../../../api/fn";
import TableDataView from "../../../components/Common/tables/TableDataView";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import "../../../components/Pages/fuel/myStyle.css";

import {GetUserToken} from "../../../api/fetching-data";
import TableSimple from "../../../components/Common/tables/TableSImple";
import ControllerButton from "../../../components/Common/ControllerInput/ControllerButton";
import MyCircle from "../../../components/Pages/fuel/circle2";
import MyRadio from "../../../components/Pages/fuel/radio";

import IconFlagRed from "../../../assets/img/redicon.jpg";
import IconFlagOrange from "../../../assets/img/orangeicon.jpg";
import IconFlagGreen from "../../../assets/img/greenicon.jpg";
import MyChartExective0 from "../../../components/Pages/fuel/ChartFleetDashboard0";
import {InnerChart1} from "./innerChart1";
import ControllerCalendar from "../../../components/Common/ControllerInput/ControllerCalendarDateRange";
import Paper from "@material-ui/core/Paper";
import ModelSimple from "../../../components/Common/popup-modal/ModalView";
import Popover from "../../../components/Common/Popover";
import BoxSelect from "../../../components/Pages/fuel/ComboxFilterController";
import ControllerCalendarDateRange2 from "../../../components/Common/ControllerInput/ControllerCalendarDateRange2";
import FleetDashboardVehicleView from "../../../components/Common/FleetDashboard/FleetDashboardVehicleView";

const convertDateFormat_YYYYMMDD = (datesIn) => {
    return moment(datesIn).format("YYYY-MM-DD");
}
const makeFlag = (colorIn, size) => {
    let fUrl = IconFlagGreen;
    if (colorIn === "red") {
        fUrl = IconFlagRed
    } else if (colorIn === "orange") {
        fUrl = IconFlagOrange
    } else {
        fUrl = IconFlagGreen;
    }
    if (typeof size === "undefined") {
        size = 32
    }
    return (
        <img
            src={fUrl}
            width={size}
            style={{minWidth: size}}
        />)
}
const FloatFixed = (inNumber, dec) => {
    inNumber = parseFloat(inNumber);
    inNumber = inNumber.toFixed(2);
    return inNumber
}
const FloatMyFixed = (inNumber, position) => {
    if (isNaN(inNumber)) {
        let defaultNum = 0;
        return defaultNum.toFixed(position);
    }
    let cResult = inNumber.toString();
    const arr = cResult.split(".");
    if (arr.length === 1) {
        return inNumber.toFixed(2);
    }
    const str = arr[1].substring(0, position);
    const final = parseFloat(arr[0] + "." + str);
    return final;
}


class FuelFleetDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vehicle: "CA 324 787",//"",
            startDate: getYTD().startDate,
            endDate: getYTD().endDate,
            component: "index", // or month or day
            DataAll: [],
            selectedVehicle: "",
            selectedMonth: "",
            selectedMonthIndex: {},
            selectedDay: null,
            selectedGroupFilter: "all",
            selectedVehicleCategory:"all",
            selectedVehicleCompany:"all",
            profile: null,
            displayPopup:true
        }

    }

    componentDidMount = async () => {
        //todo
        await this.onSubmit();
        // this.lineChart();
    }

    handleGroupFilter = (val) => {
        this.setState({
            selectedGroupFilter: val
        })
    }
    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    }
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    getHandleValue = (key) => {
        return this.state[key] || ""
    }
    requestViewMonthly = (rowData) => {

        this.setState({
            component: "month",
            selectedVehicle: rowData.Vehicle,
        });
        //console.log("View monthly request clicked22! ", rowData);
        //alert("View monthly request clicked! ");
    }
    closeViewMonthly = () => {
        this.setState({
            component: "index",
            selectedVehicle: "",
        });
    }
    onSubmit = async () => {
        console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            DataAll: [],
            isLoading: true
        });
        if (this.state.vehicle === "") {
            //return alert("Please select vehicle!")
        }
        let hub = {
            Org: GetUserToken().OrgCode,//"Test Demo" ,// GetUserToken().OrgCode,
            PeriodStart: this.state.startDate,
            PeriodEnd: this.state.endDate,
            Category: this.state.selectedVehicleCategory,
            Company:this.state.selectedVehicleCompany,
            //vehicle: this.state.vehicle, // "CA 324 787" //this.state.vehicle
        }
        let endpoint = "/fuel/report/usage/daily-monthly";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                })
                return
            }
            console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                })
                return
            }
            _this.setState({
                DataAll: data,
                isLoading: false
            })

        });
    }

    calculateTargetActual = (distance, cost, allowance, ltrKm, costUnit, radioUp, radioLower) => {
        let target, actual;
        let flagColor;

        //let find our Target
        if (cost !== 0 && distance !== 0) {
            actual = cost / distance
            actual = FloatFixed(actual, 2)
        } else {
            actual = 0
        }

        //let find our Target
        target = ((ltrKm / 100) * costUnit) + allowance
        target = FloatFixed(target, 2)

        //let find our flag
        flagColor = "none";
        if (actual >= radioUp) {
            flagColor = "red"
        } else if (actual < radioUp && actual >= radioLower) {
            flagColor = "orange"
        } else if (actual < radioLower) {
            flagColor = "green"
        }

        return {target, actual, flagColor};
    }
    onClickViewVehicle = (data) => {
        console.log("onClickViewVehicle > ", data, this.props.allData);
        let supplierRef = "";
        let supplierEntity = "";
        let org = "";
        let name = "";
        let link = "";
        let phone = "";
        for (let i in this.props.allData.Associations) {
            const row = this.props.allData.Associations[i];
            if (row.mainentity === "vehicle" && row.main === data.id) {
                supplierRef = row.supplier;
                supplierEntity = row.supplierentity;
                org = row.org;
            }
        }

        const databases = this.props.allData.Databases;
        for (let i in databases) {
            const row = databases[i];
            if (row.org === org && row.module === supplierEntity && row.ref === supplierRef) {
                phone = row.phone;
                name = GetDisplayNameFromEntity(supplierRef, this.props.allData)
                link = FindImages(supplierRef, this.props.allData.Files)
            }
        }

        let o = {
            ref: supplierRef,
            role:"driver",
            name: name,
            link: link,
            phone: phone,
            vehicle:data.id,
        }
        this.setState({
            profile:o,
            displayPopup:true
        })
    }
    getColomns = (idLabel) => {
        const myRightStyle = {
            cellStyle: {
                textAlign: "right",
            },
            headerStyle: {
                textAlign: "right"
            }
        }
        return [
            {id: "Vehicle", label: idLabel},
            {id: "Expense", label: "Fuel(Rand)", type: "currency", ...myRightStyle},
            {id: "Distance", label: "Mileage(Km)", ...myRightStyle},
            {id: "Actual", label: "Actual", ...myRightStyle},
            /*{id: "Target", label: "Target", ...myRightStyle},*/
            {id: "Rating", label: "Rating", ...myRightStyle},
            {id: "OpenMileage", label: "Km/Open", ...myRightStyle},
            {id: "CloseMileage", label: "Km/Close", ...myRightStyle}
        ]
    }
    renderFleetList = () => {
        const state = this.state;
        if (state.component !== "all") {
            return
        }

        let columns = this.getColomns("Vehicle");
        let ls = [];
        let DataAll = this.state.DataAll.Fleet;

        for (let vehicle in DataAll) {
            const row = DataAll[vehicle];
            row.Rating = makeFlag(row.FlagColor, 32);
            row.Expense = parseFloat(row.Expense).toFixed(0);
            ls.push(row);
        }
        let data = {columns: columns, data: ls};

        let otherFunctions = [
            {icon: "date_range", tooltip: "View Monthly", isFreeAction: false, myAction: this.requestViewMonthly},
            /*{icon: "today", tooltip: "View Dialy", isFreeAction: false, myAction: this.requestViewDaily}*/
        ];

        if (ls.length === 0) {
            return (
                <h5 style={{color: "orange", marginTop: 50, marginLeft: 50}}>
                    &nbsp;&nbsp;&nbsp;Empty report, please select your date range and click on search!
                </h5>
            )
        }

        return (
            <GridItem xs={12} sm={12} md={12}>
                <TableDataView
                    {...data}
                    tableTitle={(<span style={{color: "#2196f3"}}>Vehicles</span>)}
                    /*onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}*/
                    otherFunctions={otherFunctions}
                />
            </GridItem>
        )
    }
    renderMonthly = () => {
        const state = this.state;
        const defaultDataReturn = {
            data: {columns: [], data: []},
            title: "",
            chartData: [],
            chartCategories: []
        }
        if (state.component !== "vehicle") {
            return defaultDataReturn
        }
        if (state.selectedVehicle === "") {
            return defaultDataReturn
        }
        let columns = this.getColomns("month");
        let ls = [];
        let chartData = [];
        let chartDataExpense = [];
        let chartDataDistance = [];
        let chartCategories = [];
        let DataAll = state.DataAll.Monthly;
        for (let vehicle in DataAll) {
            if (vehicle !== state.selectedVehicle) {
                continue;
            }
            const rowVehicle = DataAll[vehicle];
            for (let date in rowVehicle) {
                const row = rowVehicle[date];
                row.Vehicle = moment(date).format("MMM-YY");
                row.Rating = makeFlag(row.FlagColor);
                row.Expense = parseFloat(row.Expense).toFixed(0);
                row.monthId = date;
                ls.push(row);
            }
        }

        let otherFunctions = [
            {icon: "list", tooltip: "View Daily", isFreeAction: false, myAction: this.requestViewDaily},
        ];

        let data = {columns: columns, data: ls};
        const title = "Vehicle - " + state.selectedVehicle;

        return {
            data: data,
            title: title,
            chartData: chartData,
            chartCategories: chartCategories
        }

    }

    renderChartBuilder = (reqType, divId, cTitle, multipleAxe) => {
        let state = this.state;
        let chartDataExpense = [];
        let chartDataDistance = [];
        let chartEfficiencyActual = [];
        let chartEfficiencyTart = [];
        let dataAll = [];
        let chartCategories = [];
        let DataAll = state.DataAll.Monthly;
        for (let vehicle in DataAll) {
            if (vehicle !== state.selectedVehicle) {
                continue;
            }
            const rowVehicle = DataAll[vehicle];
            for (let date in rowVehicle) {
                const row = rowVehicle[date];
                row.Vehicle = moment(date).format("MMM-YY");
                row.Rating = makeFlag(row.FlagColor);
                row.Expense = parseFloat(row.Expense).toFixed(0);
                row.monthId = date;
                let actual = parseFloat(row.Expense) / parseFloat(row.Distance);
                const dtt =ToMonthYear(date ,"YYYY-MM")
                chartDataExpense.push([dtt, parseFloat(row.Expense)]);
                chartDataDistance.push([dtt, row.Distance]);
                dataAll.push([dtt, parseFloat(row.Expense), parseFloat(row.Distance)]);
                chartCategories.push(dtt)

                chartEfficiencyActual.push([dtt, actual]);
                chartEfficiencyTart.push([dtt, 6])

            }
        }

        // reqType
        let labelX1 = "Fuel Cost(Rand)", labelX2 = "Travel (Km)";
        let axeX1 = chartDataExpense, axeX2 = chartDataDistance;

        if (reqType === 2) {
            labelX1 = "Actual";
            labelX2 = "Target";
            axeX1 = chartEfficiencyActual;
            axeX2 = chartEfficiencyTart
        }

        return <InnerChart1
            divId={divId}
            categories={chartCategories}
            axeX1={axeX1}
            axeX2={axeX2}
            labelX1={labelX1}
            labelX2={labelX2}
            maxSoft={10}
            data={dataAll}
            cTitle={cTitle}
            multipleAxe={multipleAxe}
        />

    }
    requestViewDaily = (row) => {
        this.setState({
            selectedMonth: row.monthId,
            selectedMonthIndex: row,
            component: "day"
        })
    }
    requestViewMonth = (row) => {
        console.log("requestViewMonth @@@@@--> ", row)
        this.setState({
            selectedVehicle: row.id,
            component: "vehicle",
            selectedMonthIndex: row,
        })
    }
    closeViewMonth = (row) => {
        this.setState({
            selectedVehicle: "",
            component: "index"
        })
    }
    closeViewDaily = () => {
        this.setState({
            selectedMonth: "",
            component: "vehicle"
        })
    }
    closeViewDetail = () => {
        this.setState({
            selectedDay: null,
            component: "day"
        })
    }
    requestViewDetail = (row) => {
        this.setState({
            selectedDay: row,
            component: "detail"
        })
    }

    renderDay = () => {
        const state = this.state;
        if (state.component !== "day") {
            return
        }
        if (state.selectedMonth === "") {
            return
        }
        let columns = this.getColomns("Daily");
        let ls = [];
        let DataAll = state.DataAll.Daily;
        console.log("555 View daily request clicked! > ", state.selectedMonth, state.selectedVehicle, DataAll);
        for (let vehicle in DataAll) {
            if (vehicle !== state.selectedVehicle) {
                continue;
            }

            const rowVehicle = DataAll[vehicle];
            for (let date in rowVehicle) {
                const arr = date.split("-");
                const targetDate = arr[0] + "-" + arr[1];
                if (targetDate !== state.selectedMonth) {
                    continue
                }
                const row = rowVehicle[date];
                row.Vehicle = date;
                row.Rating = makeFlag(row.FlagColor);
                row.Expense = parseFloat(row.Expense).toFixed(0);
                row.monthId = date;
                ls.push(row);
            }

        }


        let data = {columns: columns, data: ls};
        let otherFunctions = [
            {icon: "list", tooltip: "View Detail", isFreeAction: false, myAction: this.requestViewDetail},
        ];

        const title = "Monthly Usage vehicle:" + state.selectedVehicle + " Period: " + state.selectedMonth;
        return (
            <GridItem xs={12} sm={12} md={12}>
                <ControllerButton
                    variant="outlined"
                    label={"Close Daily View"}
                    color={"secondary"}
                    onClick={this.closeViewDaily}
                />
                <TableDataView
                    {...data}
                    tableTitle={(<span style={{color: "#2196f3"}}>{title}</span>)}
                    otherFunctions={otherFunctions}
                />
            </GridItem>
        )

    }

    renderDetail = () => {
        if (this.state.component !== "detail") {
            return
        }
        const record = this.state.selectedDay;
        let ls = [
            {key: "Open Date", val: record.DateIn},
            {key: "Close Date", val: record.DateOut},
            {key: "Open Mileage", val: record.OpenMileage},
            {key: "Close Mileage", val: record.CloseMileage},
            {key: "Capture Date", val: record.Capture},
            {key: "Tip Distance", val: record.Distance},
            {key: "Travel Cost", val: "R " + record.Expense},
        ];
        let ls2 = [
            {key: "Manufacture Spec Ltrs/100Km", val: record.Consumption},
            {key: "Allowance", val: "R " + record.Allowance},
            {key: "Target cost Rand/Km", val: "R " + record.Target},
            {key: "Actual cost Rand/Km", val: "R " + record.Actual},
            {key: "Cost unit Rand/Km", val: "R " + record.CostUnit},
            {key: "Flag Color", val: record.FLagColor},
            {key: "Rating", val: makeFlag(record.FlagColor)},
        ]
        return (
            <GridContainer style={{paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                <GridItem xs={12} sm={12} md={12}>
                    <h5 style={{color: "#2196f3", margin: 10, fontSize: 12}}>Detail
                        usage {this.state.vehicle} [{record.DateIn}]</h5>

                    <ControllerButton
                        variant="outlined"
                        label={"Close Detail View"}
                        color={"secondary"}
                        onClick={this.closeViewDetail}
                    />

                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <TableSimple
                        data={ls}
                        includeHeader={false}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <TableSimple
                        data={ls2}
                        includeHeader={false}
                    />
                </GridItem>

            </GridContainer>
        )

    }

    renderIndexDetail = () => {
        if (this.state.component !== "index") {
            return
        }
        let ls = []
        let {actual, flagColor} = this.getFleetIndexActualFlagColor();

        let data = this.state.DataAll.Fleet;
        for (let vehicle in data) {
            const row = data[vehicle];
            const profImage = FindImages(vehicle, this.props.allData.Files);
            row.Expense = parseFloat(row.Expense).toFixed(0);
            if (this.state.selectedGroupFilter === "all") {
                ls.push({
                    id: vehicle,
                    actual: row.Actual,
                    expense: row.Expense,
                    mileage: row.Distance,
                    flagColor: row.FlagColor,
                    image: profImage
                })
            } else {
                if (this.state.selectedGroupFilter === row.FlagColor) {
                    ls.push({
                        id: vehicle,
                        actual: row.Actual,
                        flagColor: row.FlagColor,
                        image: profImage,
                        expense: row.Expense,
                        mileage: row.Distance,
                        fleetIndex:actual,
                        fleetIndexColor:flagColor,
                    })
                }
            }
        }

        return ls.map((row) => {
            return (
                <GridItem xs={12} sm={6} md={3}>
                    <MyCircle
                        number={row.actual}
                        colorIn={row.flagColor}
                        vehicle={row.id}
                        image={row.image}
                        expense={row.expense}
                        mileage={row.mileage}
                        onClick={this.requestViewMonth}
                        data={row}
                        onClickViewVehicle={this.onClickViewVehicle}
                        fleetIndex={actual}
                        fleetIndexColor={flagColor}
                    />
                </GridItem>
            )
        })
    }
    findFlagColor = (actual, radioUp, radioLower) => {
        //let find our flag
        let flagColor = "none";
        if (actual >= radioUp) {
            flagColor = "red"
        } else if (actual < radioUp && actual >= radioLower) {
            flagColor = "orange"
        } else if (actual < radioLower) {
            flagColor = "green"
        }
        return flagColor
    }
    getFleetIndexActualFlagColor = () => {
        let totalAll = 0, totalRed = 0, totalOrange = 0, totalGreen = 0;
        let totalCost = 0, totalDistance = 0, actual = 0;
        let data = this.state.DataAll.Fleet;
        for (let i in data) {
            const row = data[i];
            totalAll++;
            totalCost +=parseFloat( row.Expense);
            totalDistance += parseFloat(row.Distance);
            if (row.FlagColor === "red") {
                totalRed++
            } else if (row.FlagColor === "orange") {
                totalOrange++
            } else if (row.FlagColor === "green") {
                totalGreen++
            }
        }
        actual = totalCost / totalDistance;
        let flagColor = this.findFlagColor(FloatMyFixed(actual, 2), 8, 6)

        return {actual, flagColor, totalAll, totalOrange, totalGreen, totalRed}

    }
    getFilterData=(fieldKey)=>{
        let ls=[{key:"all",val:"All"},];
        let ls2 = {};
        const databases = this.props.allData.Databases;
        for (let i in databases) {
            let row = databases[i];
            if (row.module === "vehicle") {
                if(typeof row.data[fieldKey]!=="undefined"){
                    let cat = row.data[fieldKey];
                    ls2[cat] = true
                }
            }
        }
        for(let i in ls2){
            ls.push({
                key: i,
                val:i
            })
        }
        return  ls;
    }
    getAllCompaniesVehicle=()=>{
        let ls = this.getFilterData("Company")
        return ls
    }
    getAllCategoriesVehicle=()=>{
        let ls = this.getFilterData("Category")
        return ls
        /*let ls=[{key:"all",val:"All"},];
        let ls2 = {};
        const databases = this.props.allData.Databases;
        for (let i in databases) {
            let row = databases[i];
            if (row.module === "vehicle") {
                let cat = row.data.Category;
                ls2[cat] = true
            }
        }
        for(let i in ls2){
            ls.push({
                key: i,
                val:i
            })
        }
        return  ls;*/
    }
    renderHeaderGlobalSummary = () => {
        if (this.state.component !== "index") {
            return
        }

        let {actual, flagColor, totalAll, totalOrange, totalGreen, totalRed} = this.getFleetIndexActualFlagColor();

        const styleBoard = {
            maxHeight: 100, minH: 100
        }
        return (
            <div style={{
                minHeight: 100,
                minWidth: "100%",
                borderWidth: 5,
                borderColor: "gray",
                borderRadius: 5,
                paddingLeft: 25,
                marginTop: 20,
            }}>
                <GridContainer style={{marginTop: 10, paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                    <GridItem xs={12} sm={6} md={3} style={{...styleBoard, color: flagColor}}>
                        <span style={{color:"gray"}}>
                            Fleet Efficiency Index
                        </span>
                        <br/>
                        <span style={{
                            color: flagColor,
                            fontSize: 44,
                            textAlign: "center"
                        }}>{FloatMyFixed(actual, 2)}</span>&nbsp;
                        <span style={{color: flagColor, fontSize: 20, textAlign: "center"}}>R/Km</span>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={2} style={{
                        ...styleBoard,
                        /*backgroundColor:"blue",*/
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        {makeFlag(flagColor, 52)}
                    </GridItem>
                    <GridItem xs={12} sm={6} md={8} style={{maxHeight: 100, minH: 100}}>
                        <MyRadio
                            counterRed={totalRed}
                            counterAll={totalAll}
                            counterOrange={totalOrange}
                            counterGreen={totalGreen}
                            handleSelectGroup={this.handleGroupFilter}
                        />
                    </GridItem>

                </GridContainer>
            </div>
        )
    }
    renderDateSelector = () => {
        if (this.state.component !== "index") {
            return
        }
        return (
            <GridContainer style={{
                backgroundColor: "white",
                minWidth: "100%",
                minHeight: 35,
                maxHeight: 80,
                display: "flex",
                flexDirection: "row",
                padding: 10,
                paddingBottom: 40,
                marginBottom: 40,
            }}>

                <Paper xs={24} sm={24} md={24} style={{
                    paddingTop: -10,
                    minWidth: "100%",
                    margin: 20,
                    marginTop: 5,

                }}>
                    <ControllerCalendarDateRange2
                        label={"Start Date"}
                        label2={"End Date"}
                        handleInput2={this.handleInput2}
                        name={"startDate"}
                        getHandleValue={this.getHandleValue}
                        name2={"endDate"}
                        onSubmit={this.onSubmit}
                        btnLabel={"Search"}
                        variant={"contained"}
                        color={"primary"}
                        getAllCategoriesVehicle={this.getAllCategoriesVehicle}
                        getAllCompaniesVehicle={this.getAllCompaniesVehicle}
                    />
                </Paper>

            </GridContainer>
        )
    }
    renderPopoverButton = () => {
        return(
            <Popover
                _this={this}
                entry={this.state.profile}
                status={this.state.displayPopup}
            />
        )
    }
    render() {
        const monthlyInfo = this.renderMonthly();
        const monthColor = this.state.selectedMonthIndex.flagColor;
        const monthActual = this.state.selectedMonthIndex.actual;

        let {actual, flagColor, } = this.getFleetIndexActualFlagColor();
        console.log(":)--> monthlyInfo > ", monthlyInfo);
        return (
            <Wallpaper {...this.props} pageTitle={"Fleet Dashboard"} displayLeftPanel={true}>

                <GridContainer style={{marginTop: 50, paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                    <GridItem xs={12} sm={12} md={12}>
                        <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 24}}>
                            &nbsp;FLEET DASHBOARD
                        </h4>
                    </GridItem>


                    {this.renderDateSelector()}


                    {this.renderHeaderGlobalSummary()}

                    {this.renderIndexDetail()}

                    <FleetDashboardVehicleView
                        closeViewMonthly={this.closeViewMonthly}
                        getFleetIndexActualFlagColor={this.getFleetIndexActualFlagColor}
                        makeFlag={makeFlag}
                        state={this.state}
                        renderChartBuilder={this.renderChartBuilder}
                        styles={styles}
                        FloatMyFixed={FloatMyFixed}
                        monthlyInfo={this.renderMonthly()}
                        fleetIndexValue={actual}
                        fleetIndexColor={flagColor}
                        findFlagColor={this.findFlagColor}
                    />


                    {this.renderDay()}
                    {this.renderDetail()}
                    {this.renderPopoverButton()}

                </GridContainer>

                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}

export default Connector(FuelFleetDashboard);

const styles = {
    b3Title: {
        color: "rgba(0, 0, 0, 0.87)",
        marginRight: 10
    },
    b3BoxRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyItems: "right",
    },
    b3BoxLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    cav1: {
        backgroundColor: "black"
    },
    chart: {
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: 400,
        maxHeight: 400,
        backgroundColor: "red"
    }

}
