import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import SetupIncomplete from "../../../components/Pages/Vierifycation/SetupIncomplete";
import SetupMenu from "../../../components/Pages/Vierifycation/SetupMenu";
import SetupAttribute from "../../../components/Pages/SetupAttribute";
import SetupDatabase from "../../../components/Pages/SetupDatabase";
import SetupLinkAssociation from "../../../components/Pages/SetupAssociation";

class SettingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            component: "menu"
        }

    }

    componentDidMount() {
        //todo

    }

    goTo=(comp) => {
        this.setState({
            component:comp
        })
    }

    renderSetupAttribute = () => {
        return (
            <SetupAttribute
                {...this.props}
                goTo={this.goTo}
            />
        )
    }
    renderSetupDatabase=()=>{
        return(
            <SetupDatabase
                {...this.props}
                goTo={this.goTo}
            />
        )
    }
    renderIncompleteSetup = () => {
        return (
            <SetupIncomplete
                {...this.props}
                goTo={this.goTo}
            />
        )
    }
    renderSetupMenu=()=>{
        return(
            <SetupMenu
                {...this.props}
                goTo={this.goTo}
            />
        )
    }

    renderSetupLinkAssociation=()=>{
        return(
            <SetupLinkAssociation
                {...this.props}
                goTo={this.goTo}
            />
        )
    }

    switchComponent = () => {
        switch (this.state.component) {
            case "menu":
                return this.renderSetupMenu();
            case "attribute":
                return this.renderSetupAttribute();
            case "database":
                return this.renderSetupDatabase();
            case "link":
                return this.renderSetupLinkAssociation();
            default:
                return this.renderSetupMenu()
        }
    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Settings"}  displayLeftPanel={false}>

                {this.switchComponent()}
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(SettingPage);
const styles = {
    box1: {
        marginTop: 35,

    }
}
