import {
    FLEETMINDER_LOAD_DATA,
    FLEETMINDER_LOAD_RECEIVE
} from "../constants";
import {GetUserToken} from "../../api/fetching-data";
import {FetchDataFleetminder} from "../../api/fn";

const EntityDataMiddleware = store => next =>  async action => {
    if (action.type === FLEETMINDER_LOAD_DATA) {
        let hub = {
            Org: GetUserToken().OrgCode
        }
        let endpoint = "/maintenance/loader";
        await FetchDataFleetminder(hub, endpoint, async (data) => {
            let out = {};
            if (typeof data !== "undefined") {
                out = data.List;
            }
            console.log("FFF2: FetchDataFleetminder:", out, data);
            next({type: FLEETMINDER_LOAD_RECEIVE, data: out});
        });

    }else{
        next(action);
    }



}


export default EntityDataMiddleware;
