import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'

export default (props)=>{
    let {onClick,ls,onChooseMenu}=props;
    ////console.log("TTTXvvvvv-> ",ls);


    const [open, setOpen] = React.useState(false)

    function handleClick() {
        setOpen(!open)
    }

    /**
     *
     * Get the current schema
     */
    let currentSchema ={};
    let appName=props.navigations.currentApp;
    for(let i in props.navigations.schema){
        let row = props.navigations.schema[i];
        if(row.name===appName){
            currentSchema = row;
        }
    }


    const drawerWidth = 240

    const useStyles = makeStyles(theme =>
        createStyles({
            appMenu: {
                width: '100%',
                minHeight:"100%",
                backgroundColor:currentSchema.color ,//'#795548',//  '#2196f3',
            },
            navList: {
                width: drawerWidth,

            },
            menuItem: {
                width: drawerWidth,
                color:"white"
            },
            menuItemIcon: {
                color:'white' /*'#97c05c'*/,
            },
        }),
    );

    const classes = useStyles();

    return(
        <List component="nav" className={classes.appMenu}  style={{
            minHeight:"100%",
            borderRadius:5,
        }}>
            {ls.map((row, index) => {
                return(
                    <ListItem button className={classes.menuItem} onClick={() => onChooseMenu(row)}>
                        <ListItemIcon className={classes.menuItemIcon}>
                            <IconDashboard />
                        </ListItemIcon>
                        <ListItemText primary={row.key} />
                    </ListItem>
                )
            })}

        </List>
    )

}


