import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {GetUserToken} from "../../../api/fetching-data";
import {SERVER_FLEETMINDER} from "../../../configures/constants";
import {
    PostTo,
} from "../../../api/fetching-data";
import Connector from "../../../redux/common/Connector";
import {fetchDataEntity, UpCaseFirst} from "./fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {FetchDataCrm, FetchDataFleetminder} from "../../../api/fn";
import {Button, Form, Input, Select, Alert} from "antd";
import {TICKET_DATA_RECEIVED} from "../../../redux/constants";
import {findNameFromDatabase, GetEntityData} from "../../../api/entity-data";
import 'antd/dist/antd.css';

const {Option} = Select;

class FuelSettingUserSystem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Attributes: [],
            Entities: [],
            Users:[],
            component: "list",
            isLoading: false,
            Module: "",
        }

    }

    componentDidMount = async () => {
        await this.loadInitialData();

    }

    loadInitialData = async () => {
        await this.fetchListUser()


    }
    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    submitPost = async (hub, endpoint, successMsg) => {
        let _this = this;
        await FetchDataFleetminder(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }
    fetchListUser = async () => {
        let _this = this;

        let hub = {};
        hub.OrgCode = GetUserToken().OrgCode;
        hub.Org = hub.OrgCode;

        let endpoint = "/fuel/user/list";

        console.log("submitEntry *** send > ", hub);

        await PostTo(SERVER_FLEETMINDER, hub, endpoint, function (data) {
            console.log("submitEntry *** response > ", data);
            if (data !== null) {
                if (typeof data.RESULT !== "undefined") {
                    _this.setState({Users:data.RESULT});
                }
            }
        });

    }
    submitEntry = async (values) => {
        let _this = this;
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let hub = values;
        hub.OrgCode = GetUserToken().OrgCode;
        hub.Company = hub.OrgCode
        hub.Name = values.Name;
        hub.Surname = values.Surname;
        hub.Phone = values.Phone;
        hub.Email = values.Email;
        hub.Username =hub.Email
        hub.FullName = `${hub.Name} ${hub.Surname}`;
        hub.Role = 'admin'

        let endpoint = "/fuel/user/insert";

        console.log("submitEntry *** send > ", hub);

        await PostTo(SERVER_FLEETMINDER, hub, endpoint, function (data) {
            console.log("submitEntry *** response > ", data);
            if (data !== null) {
                if (typeof data.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.component = "list";
                    tmp.newEntry = {};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }
        });

    }

    onAddRecord = () => {
        this.setState({component: "form"})
    }
    onDelete = async (row) => {
        if (!window.confirm(`Are you sure you want to remove this user ${row.Username} ?`)) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });
        let hub = {...row}
        let endpoint = "/fuel/user/delete";
        this.submitPost(hub, endpoint, "Tank you, User removed")
    }
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }
        let modules = [
            {key: "driver", val: "Driver"},
            {key: "personnel", val: "Personnel"},
        ];
        let channels = [
            {key: "email", val: "Email"},
            {key: "sms", val: "SMS"},
        ]
        let users = [];
        if (this.state.Module !== "") {
            users = GetEntityData(this.state.Module, this.props)
        }

        const msgInput = (txt) => {
            return "Please input your " + txt
        }
        const msgSelect = (txt) => {
            return "Please select your " + txt
        }
        let inputList = [
            {
                name: "Name",
                label: "First Name",
                type: "text",
                required: true,
                message: msgInput("Name")
            },
            {
                name: "Surname",
                label: "Surname",
                type: "text",
                required: true,
                message: msgInput("Surname")
            },
            {
                name: "Phone",
                label: "Phone Number",
                type: "text",
                required: true,
                message: msgInput("Phone")
            },
            {
                name: "Email",
                label: "Email",
                type: "text",
                required: true,
                message: msgInput("Email")
            },
            /*{
                name: "Username",
                label: "Username",
                type: "text",
                required: true,
                message: msgInput("Username")
            },*/
            {
                name: "Password",
                label: "Password",
                type: "text",
                required: true,
                message: msgInput("Password")
            },
        ]
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };

        return (
            <Form
                {...layout}
                onSubmit={this.submitEntry}
                className="login-form"
                onFinish={this.submitEntry}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%", maxWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New User"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    onChange={(e) => this.handleChange(e, row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )

    }
    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        let columns = [
            {id: "Name", label: "First Name"},
            {id: "Surname", label: "Surname"},
            {id: "Phone", label: "Phone Number"},
            {id: "Email", label: "Email"},
            {id: "Username", label: "Username"},
            {id: "Role", label: "Role"},
        ]
        let ls = [];
        const state = this.state.Users;
        for (let i in state) {
            let row = state[i];
            //todo some futures

            ls.push(row)
        }

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("User")}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }


    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"System User Setup"} displayLeftPanel={true}>
                <div>
                    <div className="row">
                        <div className="col col-xs-12">
                            {this.renderList()}
                            {this.renderForm()}
                        </div>
                    </div>
                </div>

                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(FuelSettingUserSystem);
