import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CheckIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Icon from '@material-ui/core/Icon';
import SendIcon from '@material-ui/icons/Send';






const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    primary:{
        color:"#f44336",
        fontWeight:"bold",
        fontSize:24
    },
    button:{
        backgroundColor:"#42a5f5",
    }
});

export default (props)=> {
    let {goTo}=props;
    const classes = useStyles();
    const titleMessage = "Setup Alert".toLocaleUpperCase();

    return (
        <Card className={classes.card}>
            <CardContent>

                <List dense={true}>

                            <ListItem>
                                <ListItemAvatar>

                                    <ReportProblemIcon style={{color:"red",fontWeight:"bold"}}/>

                                </ListItemAvatar>
                                <ListItemText
                                    classes={classes}
                                    primary={titleMessage}
                                    style={{color:"red",fontWeight:"bold",fontSize:24}}
                                    size={32}
                                />
                            </ListItem>



                    <Divider />
                </List>

                <Typography className={classes.pos} color="textSecondary">
                    Warning Incomplete Setup
                </Typography>
                <Typography variant="body2" component="p">
                    Our system detect that you didn't complete your setup required to use this system.
                    Please make sure that you complet your setup before continue
                    <br />

                </Typography>

                <List dense={true}>
                    <ListItem style={{
                        textAlign:"right",
                        marginTop:100
                    }}>
                        <ListItemSecondaryAction>

                        <IconButton edge="end" aria-label="delete">
                            <SettingsIcon style={{color:"#42a5f5",fontSize:84}}/>
                        </IconButton>

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<SendIcon />}
                                onClick={()=>goTo("menu")}
                            >
                                Go Setup
                            </Button>

                            {/*<button style={{
                            borderRadius:5,
                            backgroundColor:"#42a5f5",
                            color:"white",
                            minHeight:40,
                            borderBottomRightRadius:21,
                            borderTopRightRadius:21,
                            fontWeight:"bold",
                            fontSize:24,
                            borderColor:"#42a5f5"
                        }}>
                            Go Setting
                        </button>*/}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </CardContent>
            <CardActions>
                {/*<Button size="small">Please use your rigth panel to setup </Button>*/}
            </CardActions>
        </Card>
    );
}
