import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {GetUserToken} from "../../../api/fetching-data";
import {SERVER_CRM, SERVER_FLEETMINDER} from "../../../configures/constants";
import {
    PostTo,
} from "../../../api/fetching-data";
import Connector from "../../../redux/common/Connector";
import {fetchDataEntity, UpCaseFirst} from "../Settings/fn";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import TableDataView from "../../../components/Common/tables/TableDataView";
import {FetchDataCrm, FetchDataFleetminder, GetDisplayNameFromEntity} from "../../../api/fn";
import {Button, Modal,Form, Input, Select, Alert} from "antd";
import {TICKET_DATA_RECEIVED} from "../../../redux/constants";
import {findNameFromDatabase, GetEntityData} from "../../../api/entity-data";
import ControllerButton from "../../../components/Common/ControllerInput/ControllerButton";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import eConfig from "../../../configures";
import 'antd/dist/antd.css';
import ReactDOM from 'react-dom';
import ModalConfirm from "./ModalConfirm";
const { confirm } = Modal;
const { TextArea } = Input;
const {Option} = Select;

class FuelLogsQa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Attributes: [],
            Entities: [],
            QaList: [],
            component: "list",
            isLoading: false,
            Module: "",
            selectedQaRecord:null,
            selectedQaStatus:"",
            qaComment:"",
        }

        //this.eventEmitter =  window.addEventListener("nv-enter", this.handleNvEnter);
        var event = new Event('nv-enter');
        this.eventEmitter =new Event('nv-enter') ;
        window.addEventListener("nv-enter", this.handleNvEnter);
        //window.document.body.addEventListener("nv-enter", this.handleNvEnter);

    }

    componentDidMount = async () => {
        await this.loadInitialData();

    }

    handleNvEnter=async (event)=>{
        console.log("Nv Enter:", event);
        await this.submitQa()

    }


    loadInitialData = async () => {
        await fetchDataEntity("Data", "collection", "collector", this);
        await fetchDataEntity("QaList", "collection", "filling-qa", this);
    }
    handleChange = (value, key) => {
        this.setState({
            [key]: value,
        })
    }
    handleChange1 = (e) => {
        let key = e.target.name;
        let val = e.target.value;
        this.setState({
            [key]: val,
        })
    }
    submitPost = async (hub, endpoint, successMsg) => {
        let _this = this;
        await FetchDataFleetminder(hub, endpoint, async (data) => {
            _this.setState({
                isLoading: false,
                component: "list"
            })
            if (data === null) {
                alert("Server connection Error! try again later");
                return
            }
            alert(successMsg)
            _this.setState({
                isLoading: false,
                component: "list"
            });
            await _this.loadInitialData();
        });
    }
    submitEntry = async (values) => {
        let _this = this;
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let hub = values;
        hub.Org = GetUserToken().OrgCode;
        let arr = hub.Ref.split("--");
        hub.Name = arr[1];
        hub.Ref = arr[0];
        let endpoint = "/collection/insert/collector";

        console.log("submitEntry *** send > ", hub);

        await PostTo(SERVER_FLEETMINDER, hub, endpoint, function (data) {
            console.log("submitEntry *** response > ", data);
            if (data !== null) {
                if (typeof data.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.component = "list";
                    tmp.newEntry = {};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }
        });

    }
    submitQa=async ()=>{
        //TODO let send our status
        const _this=this;
        let user = GetUserToken();
        let state = this.state;
        let record =state.selectedQaRecord;
        delete record.ActionReject;
        delete record.ActionApprove;
        delete record.Link;
        let hub={
            Org:user.OrgCode,
            Record:state.selectedQaRecord,
            Status:state.selectedQaStatus,
            Username:user.Username,
            Comment:state.qaComment,
        }

        //  qa-log

        let endpoint = "/collection/insert/qa-log";

        console.log("submitEntry *** send > ", hub);

        await PostTo(SERVER_FLEETMINDER, hub, endpoint, function (data) {
            console.log("qa submitEntry *** response > ", data);
            if (data !== null) {
                if (typeof data.RESULT !== "undefined") {
                   // alert("Thank you Qa Record submitted!");
                    let tmp = _this.state;
                    tmp.component = "list";
                    tmp.newEntry = {};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }
        });
    }

    onAddRecord = () => {
        this.setState({component: "form"})
    }
    onDelete = async (row) => {
        if (!window.confirm("Are you sure you want to remove this inbound queue ?")) {
            return
        }
        console.log('onDelete:', row);
        this.setState({
            component: "list"
        });
        let hub = {...row}
        let endpoint = "/collection/remove/collector";
        this.submitPost(hub, endpoint, "Tank you, User removed")
    }
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    requestActionQaApproved =async (row) => {

        let _this=this;
        _this.setState({
            selectedQaRecord:row,
            selectedQaStatus:"approve"
        });
        confirm({
            title: 'Do you want to submit this fuel logs? Vehicle: ['+row.Vehicle+"] Cost: R"+row.Cost+" Quantity: "+row.Quantity,
            icon: <ExclamationCircleOutlined />,
            content: 'When clicked the OK button, this dialog will be closed after 1 second',
            async onOk() {
                return new Promise(async (resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    await _this.submitQa();

                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {},
        });


    }
    commentContent=()=>{

        return(
            <TextArea
                name={"qaComment"}
                rows={4}
                onChange={(e)=>this.handleChange1(e)}
            />
        )
    }
    tePost=()=>{
        let _this=this;
        console.log('rejection confirm OK');
        return new Promise(async (resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            await _this.submitQa()
        }).catch(() => console.log('Oops errors!'))

    }
    requestActionQaReject =async (row) => {

        /*return (
            <ModalConfirm row={row} handleInput1={this.handleChange1} tePost={this.tePost} />
        )*/
        let _this =this;
        _this.setState({
            selectedQaRecord:row,
            selectedQaStatus:"reject"
        });
        confirm({
            title: 'Please entry your Rejection comment and click Ok ,Vehicle: ['+row.Vehicle+"] Cost: R"+row.Cost+" Quantity: "+row.Quantity,
            icon: <ExclamationCircleOutlined />,
            content: this.commentContent(row),
            okText: 'Yes',
            okType: 'danger',
            okButtonProps: {
                disabled: false,
            },
            cancelText: 'No',
             onOk:async () =>{

                 var event = new Event('nv-enter');
                 window.dispatchEvent(event);

             },
            onCancel:()=> {
                console.log('Cancel');
            },
        });
    }
    documentView=(dropboxId)=>{
        let link =dropboxId;
        document.title = "Qa Receipt";
        window.open(link, '_blank');
    }
    documentDownload=(dropboxId)=>{

        let url =dropboxId; // server + "" + endpoint;

        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a'); // create html element anchor
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = "receipt.jpg"; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();

    }
    renderForm = () => {
        if (this.state.component !== "form") {
            return
        }
        let modules = [
            {key: "driver", val: "Driver"},
            {key: "personnel", val: "Personnel"},
        ];
        let channels = [
            {key: "email", val: "Email"},
            {key: "sms", val: "SMS"},
        ]
        let users = [];
        if (this.state.Module !== "") {
            users = GetEntityData(this.state.Module, this.props)
        }

        const msgInput = (txt) => {
            return "Please input your " + txt
        }
        const msgSelect = (txt) => {
            return "Please select your " + txt
        }
        let inputList = [
            {
                name: "Module",
                label: "Select target entity",
                type: "option",
                required: true,
                options: modules,
                message: msgSelect("entity")
            },
            {
                name: "Ref",
                label: "User",
                type: "option",
                required: true,
                options: users,
                message: msgInput("User")
            },
            {
                name: "Username",
                label: "Username",
                type: "text",
                required: true,
                message: msgInput("Username")
            },
            {
                name: "Password",
                label: "Password",
                type: "text",
                required: true,
                message: msgInput("Password")
            },
            {
                name: "ChannelType",
                label: "Communication Channel",
                type: "option",
                required: true,
                options: channels,
                message: msgInput("Communication channel")
            },
        ]
        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };
        const tailLayout = {
            wrapperCol: {offset: 8, span: 16},
        };

        return (
            <Form
                {...layout}
                onSubmit={this.submitEntry}
                className="login-form"
                onFinish={this.submitEntry}
                onFinishFailed={this.onFinishFailed}
                name="basic"
                initialValues={{remember: true}}
                style={{minWidth: "50%", maxWidth: "50%"}}
            >
                <Alert style={{margin: '16px 0'}} message="New User"/>

                {inputList.map((row) => {
                    if (row.type === "option") {
                        return (
                            <Form.Item
                                {...tailLayout}
                                label={row.label}
                                name={row.name}
                                rules={[{required: row.required, message: row.message}]}
                            >
                                <Select
                                    name={row.name}
                                    defaultValue=""
                                    /*style={{ width: 120 }}*/
                                    onChange={(e) => this.handleChange(e, row.name)}
                                >
                                    {row.options.map((record, index) => {
                                        return (
                                            <Option value={record.key}>{record.val}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        )
                    }
                    return (
                        <Form.Item
                            {...tailLayout}
                            label={row.label}
                            name={row.name}
                            rules={[{required: row.required, message: row.message}]}
                        >
                            <Input
                                onChange={this.handInput}
                                type={row.type}
                                /*prefix={<LockOutlined type={row.prefix} style={{fontSize: 13}}/>}*/
                            />
                        </Form.Item>
                    )
                })}

                <Form.Item {...tailLayout}>
                    <Button
                        {...tailLayout}
                        type="secondary"
                        htmlType="submit"
                        style={{marginRight: 20}}
                        onClick={() => this.setState({component: "list"})}
                    >
                        Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )

    }
    renderList = () => {
        if (this.state.component !== "list") {
            return
        }
        let columns = [
            {id: "Vehicle", label: "Vehicle"},
            {id: "Cost", label: "Cost"},
            {id: "Quantity", label: "Quantity"},
            {id: "CostUnit", label: "CostUnit"},
            {id: "Mileage", label: "Mileage"},
            {id: "RefuelDate", label: "Refuel Date"},
            {id: "QaStatus", label: "Qa Status"},
            {id: "Link", label: "Receipt"},
            {id: "User", label: "Driver"},
            {id: "ActionApprove", label: ""},
            {id: "ActionReject", label: ""},
        ]
        let ls = [];
        const state = this.state.QaList;
        console.log("TTTTTTTTT----> ", this.state)
        for (let i in state) {
            let row = state[i];
            if(row.QaStatus==="reject" ||row.QaStatus==="approve"){
                continue;
            }
            row.User =GetDisplayNameFromEntity(row.Driver,this.props.allData);
            row.ActionReject = (
                <ControllerButton
                    variant="outlined"
                    label={"Reject  "}
                    color={"secondary"}
                    onClick={() => this.requestActionQaReject(row)}
                />
            );
            row.ActionApprove = (
                <ControllerButton
                    variant="outlined"
                    label={"Approve"}
                    color={"primary"}
                    onClick={()=>this.requestActionQaApproved(row)}
                />
            );
            row.Link=(
                <div style={{
                    color:"blue",
                    flex:1,
                    flexDirection:"row",
                    justifyContent:"space-around",
                    fontSize:18,
                }}>
                    <a onClick={()=>this.documentView(row.ImageReceipt)}>View</a>&nbsp;|&nbsp;
                    <a onClick={()=>this.documentDownload(row.ImageReceipt)}>Download</a>
                </div>
            )
            row.QaStatus = row.QaStatus||"Pending";
            //todo some futures

            ls.push(row)
        }

        console.log("**** this.getMember > ", state)

        let data = {columns: columns, data: ls};
        return (
            <GridItem xs={12} sm={12} md={12}>
                <br/>
                <TableDataView
                    {...data}
                    tableTitle={UpCaseFirst("Qa List")}
                    /*onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}*/
                    noAddButton={true}
                />
            </GridItem>
        )
    }


    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Qa logs"} displayLeftPanel={true}>

                <div>
                    <div className="row">
                        <div className="col col-xs-12">
                            {this.renderList()}
                            {this.renderForm()}
                        </div>
                    </div>
                </div>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(FuelLogsQa);
