import React from "react";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';


export default ({colorIn, number, vehicle, image}) => {

    const styles = {
        square: {
            background: colorIn,// "lightblue",
            minWidth: "100px",
            minHeight: "100px",
            maxWidth: "100px",
            maxHeight: "100px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5
        },
        circle: {
            background: "white",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex"
        },
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "row"
        },
        bigContainer: {
            display: "flex",
            flex: 1,
            /*border:"1px solid black"*/
            margin:10
        },
        boxLabel: {
            display: "flex",
            flex: 1,
            flexDirection: "row"
        },
        myLabel: {
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
            color: "black",// "#2196f3", fontSize: 12
        }
    }

    const styleforLabel = {
        color: "lightblack",
        fontSize: 20,
        fontWeight: "bold"
    }
    const defaultImage = "https://s16-us2.startpage.com/cgi-bin/serveimage?url=http%3A%2F%2Fwww.royalsupercars.co.uk%2Fwp-content%2Fuploads%2F2018%2F09%2Fdefault-2.jpg&sp=038e5373ce4bbb2756e7a29769a25562&anticache=852764";
    const profImage = image;

    return (
        <div style={styles.bigContainer}>
            <div style={styles.container}>
                <div style={{
                    borderWidth:2,
                    border:"2px solid "+colorIn,
                    borderRightWidth:0,
                }}>
                    <div style={{
                        ...styles.square,

                    }}>
                        <img
                            style={{minHeight: 100,margin:10,minWidth:"100%",maxHeight:"100%"}}
                            src={profImage}
                            width={100}
                        />
                    </div>
                    <div style={styles.myLabel}>{vehicle}</div>
                </div>
                <div style={{
                    borderWidth:2,
                    border:"2px solid "+colorIn,
                    borderLeftWidth:0,
                    borderBottomRightRadius: 5,
                    borderTopRightRadius: 5
                }}>
                    <div style={{
                        ...styles.square,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 5
                    }}>
                        <div style={styles.circle}>
                            <div style={{ textAlign: "center",}}>
                                <div style={styleforLabel}>{number}</div>
                                <div style={{fontSize: 10}}>R/Km</div>
                            </div>
                        </div>
                    </div>
                    <div style={{...styles.myLabel, color: "black", fontSize: 12}}>
                        View Detail &nbsp;&nbsp;
                        <PlayCircleFilledWhiteIcon/>
                    </div>
                </div>
            </div>

        </div>
    )
}
