import GridContainer from "../../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../../Common/dashboard/Grid/GridItem";
import React from "react";
import SelectBox from "./ControllerSelectBox";
import InputBox from "./ControllerInputBox";
import ControllerRadioButton from "./ControllerRadioButton";


export default (props)=>{
    let {state,handleInput,handleInput2,getTaskValue,setTaskValue,addTask,removeTask, getHandleValue}=props;
    const renderBox=(name,label,ls)=>{
        return(
            <SelectBox
                name={name}
                ls={ls}
                handleInput={handleInput}
                getHandleValue={getHandleValue}
                label={label}
            />
        )
    }

    const renderTaskBody = () => {
        let ls = [];
        for (let i in state.tasks) {
            const row = state.tasks[i];
            ls.push(row);
        }

        return ls.map((row, index) => {
            return (
                <tr>
                    <td>{row.name}</td>
                    <td>{row.category}</td>
                    <td>{row.cost}</td>
                    <td>
                        <a
                            onClick={() => removeTask(row.name)}
                            style={{cursor: "pointer", color: "blue"}}
                        >Remove</a>
                    </td>
                </tr>
            )
        })
    }

    return(
        <GridContainer style={{marginTop: 50,paddingRight:20,paddingBottom:30}}>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                    Step: 4 Task to perform
                </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Task Name"
                            onChange={(e) => setTaskValue(e, "name")}
                            value={getTaskValue("name")}

                        />
                    </div>
                    <div className="col">
                        <select
                            className="form-control"
                            onChange={(e) => setTaskValue(e, "category")}
                            value={getTaskValue("category")}

                        >
                            <option value={""}>--Select Category--</option>
                            <option value={"parts"}>Parts</option>
                            <option value={"labour"}>Labour</option>
                        </select>
                    </div>
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Cost"
                            onChange={(e) => setTaskValue(e, "cost")}
                            value={getTaskValue("cost")}
                        />
                    </div>
                    <div className="col">
                        <button onClick={(e)=>addTask(e)} className="btn  pull-right">Add
                            task
                        </button>
                    </div>
                </div>


                <hr/>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Cost</th>
                        <th>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderTaskBody()}
                    </tbody>
                </table>
            </GridItem>


        </GridContainer>
    )
}