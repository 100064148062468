import {connect} from 'react-redux';
import {
    SaveLoginInfo,
    SetNavigateMenu,
    SetNavigateModule,

} from "../action/action-global";

import {
    LoadData,
    SaveGlobalMiddleware,
    DeleteGobalMiddleware
} from "../action/action-fetch-data";

import{
DeleteMaintenaceMiddleware,
    LoadDataMaintenance,
    SaveMaintenanceMiddleware
}from "../action/action-maintenance"

import {
    LoaderFuelMiddleware,
    SaveFuelMiddleware,
    DeleteFuelMiddleware
} from "../action/action-fuel";

const mapDispatchToProps = dispatch => ({
    dispatch: dispatch,
    SaveLoginInfo:SaveLoginInfo,
    SetNavigateMenu:SetNavigateMenu,
    SetNavigateModule:SetNavigateModule,
    SaveGlobalMiddleware:(data)=>dispatch(SaveGlobalMiddleware(data)),
    DeleteGobalMiddleware:(data)=>dispatch(DeleteGobalMiddleware(data)),

    DeleteMaintenaceMiddleware:(data)=>dispatch(DeleteMaintenaceMiddleware(data)),
    LoadDataMaintenance:(data)=>dispatch(LoadDataMaintenance(data)),
    SaveMaintenaceMiddleware:(data)=>dispatch(SaveMaintenanceMiddleware(data)),

    LoaderFuelMiddleware:(data)=>dispatch(LoaderFuelMiddleware()),
    SaveFuelMiddleware:(data)=>dispatch(SaveFuelMiddleware(data)),
    DeleteFuelMiddleware:(data)=>dispatch(DeleteFuelMiddleware(data)),

    LoadData:()=>dispatch(LoadData()),
})

const mapStateToProps = state => ({
    menus:state.menus,
    login:state.login,
    navigations:state.navigations,
    addingModel:state.addingModel,
    info:state.info,
    verify:state.verify,
    allData:state.allData,
    maintenanceData:state.maintenanceData,
    fuel:state.fuel
});

const Connector=(requestedClass)=>{
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(requestedClass)
}

export default Connector;
