import React, {Component, Fragment} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import {FetchDataFleetminder} from "../../../api/fn";
import TableDataView from "../../Common/tables/TableDataView";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ControllerCalendar from "../../Common/ControllerInput/ControllerCalendarDateRange";
import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import AssistantPhotoIcon from '@material-ui/icons/GolfCourse';


import {MyBoxSelecttion} from "../SetupAssociation/fn";
import {GetEntityData} from "./fn";
import {GetUserToken} from "../../../api/fetching-data";
import TableSimple from "../../Common/tables/TableSImple";
import ControllerButton from "../../Common/ControllerInput/ControllerButton";

import ChartMonthlyOverview from "./ChartMonthlyOverview2";
import ChartMonthlyOverview1 from "./chartMonthlyOverview";
import ChartMonthlyOverview3 from "./ChartMonthlyOverview3";

const convertDateFormat_YYYYMMDD = (datesIn) => {
    return moment(datesIn).format("YYYY-MM-DD");
}

class FuelUsageMonthly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vehicle: "CA 324 787",//"",
            startDate: convertDateFormat_YYYYMMDD(new Date()),// new Date(),
            endDate: convertDateFormat_YYYYMMDD(new Date()),// new Date(),
            Usages: [],
            component: "list", // or detail
            selectedRecord: null,
            chartLines:[],
        }

    }


    componentDidMount() {
        //todo


    }

    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    }
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    getHandleValue = (key) => {
        return this.state[key] || ""
    }
    onSubmit = async () => {
        //console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            Usages: [],
            isLoading: true
        })

        if (this.state.vehicle === "") {
            //return alert("Please select vehicle!")
        }

        let hub = {
            Org: GetUserToken().OrgCode,
            AllVehicle:false,
            Entity:"vehicle",
            From: this.state.startDate,
            To: this.state.endDate,
            targetVehicle: this.state.vehicle,
        }
        /*let hub = {};
        hub.Org = GetUserToken().OrgCode;
        hub.AllVehicle = false;
        hub.From = "2019-06-01";
        hub.To = "2019-12-01";
        hub.TargetVehicle = "CA 324 787";
        hub.Entity = "vehicle";*/

        let endpoint = "/fuel/report/monthly/overview";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                })
                return
            }
            //console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                })
                return
            }

            _this.setState({
                Usages: data,
                isLoading: false
            })

            //_this.loadChart(data);

            //next({type: FUEL_DATA_RECEIVED, data: out});
        });
    }

    showDetailUsage = (row) => {
        this.setState({
            component: "detail", // or detail
            selectedRecord: row,
        });
    }
    closeShowDetailUsage = () => {
        this.setState({
            component: "list", // or detail
            selectedRecord: null,
        });
    }

    getColunms = () => {

        return [
            /*{id: "Vehicle", label: "Reg.#"},*/
            {id: "date", label: "Month", float: "left"},
            {id: "TotalFuelCost", label: "Rand"},
            {id: "TotalMileage", label: "Mileage"},
            {id: "Actual", label: "R/Km"},
            {id:"Target",label:"Target"},
            {id: "FlagColor", label: "Status"},
            {id: "TotalLitters", label: "Litters"},
            /*{id: "CostUnit", label: "Cost/Ltrs"},*/
            {id: "OpeningMileage", label: "Open mileage"},
            {id: "ClosingMileage", label: "Close mileage"},
        ]
    }


    renderList = () => {

        if (this.state.component !== "list") {
            return
        }

        let self = this;
        const formatMe = (inData) => {
            let d = parseFloat(inData)
            return d.toFixed(2)
        }
        const makeColor = (colorIn) => {
            return (<span style={{color: colorIn}}>{colorIn}</span>)
        }
        const makeFlag = (colorIn) => {
            return (<AssistantPhotoIcon style={{color: colorIn}}/>)
        }
        const makeViewButton=(row)=>{
            return(
                <a style={{color:"blue",cursor:"pointer"}} onClick={()=>self.showDetailUsage(row)}>Detail</a>
            )
        }

        /*
        {
      "Org": "Test Demo",
      "Year": "2019",
      "Vehicle": "CA 324 787",
      "Month": "06",
      "Driver": "",
      "TotalCost": 24006.88,
      "TotalMileage": 2014,
      "TotalFuelRand": 18476.700248605,
      "TotalLitters": 1550.0599999999997,
      "Actual": 9.17,
      "Target": 5.62,
      "Rating": 0,
      "FlagColor": "red",
      "OpeningMileage": 92540,
      "OpeningMileageDate": "",
      "ClosingMileage": 94554,
      "ClosingMileageDate": "2019-06-28"
    }
         */


        let ls = [];
        let chartLines=[];
        const usages=this.state.Usages;
        for (let vehicle in usages) {
            if(vehicle !==this.state.vehicle){
                continue
            }
            const rowVehicle = usages[vehicle];
            for(let date in rowVehicle){
                const row=rowVehicle[date];
                const o={
                    date:date,
                    TotalFuelCost:row.TotalFuelRand.toFixed(2),
                    TotalMileage:row.TotalMileage,
                    Actual:row.Actual,
                    Target:row.Target,
                    FlagColor:row.FlagColor,
                    TotalLitters:row.TotalLitters.toFixed(2),
                    CostUnit:row.CostUnit,
                    OpeningMileage:row.OpeningMileage,
                    ClosingMileage:row.ClosingMileage
                }
                ls.push(o);
                chartLines.push({
                    date:o.date,
                    mileages:o.TotalMileage,
                    fuels:o.TotalLitters,
                    cost:o.TotalFuelCost
                });
            }
        }
        if (this.state.Usages.length === 0) {
            return null
        }
        let data = {columns: this.getColunms(), data: ls};
        return (
            <Fragment>
                <br/>
                <GridItem xs={12} sm={12} md={7}>
                    <ChartMonthlyOverview3
                        data={chartLines}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                    <TableDataView
                        {...data}
                        tableTitle={"Monthly Usage Overview "}
                        onAddRecord={this.onAddRecord}
                        onDelete={this.onDelete}
                    />
                </GridItem>
                {/*<GridItem xs={12} sm={12} md={12}>
                    <ChartMonthlyOverview
                        data={chartLines}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <TableDataView
                        {...data}
                        tableTitle={"Monthly Usage Overview "}
                        onAddRecord={this.onAddRecord}
                        onDelete={this.onDelete}
                    />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <ChartMonthlyOverview1
                        data={chartLines}
                    />
                </GridItem>*/}



            </Fragment>
        )



    }

    renderDetail = () => {

        if (this.state.component !== "detail") {
            return
        }

        const record = this.state.selectedRecord;
        const FillingCurrent = record.FillingCurrent;
        const FillingNext = record.FillingNext;

        let ls = [
            {key: "Open Date", val: FillingCurrent.Date},
            {key: "Close Date", val: FillingNext.Date},
            {key: "Open Mileage", val: FillingCurrent.Mileage},
            {key: "Close Mileage", val: FillingNext.Mileage},
            {key: "Open Litters", val: FillingCurrent.Quantity},
            {key: "Close Litters", val: FillingNext.Quantity},
            {key: "USed Litters", val: record.TotalLitersUsed},
            {key: "Travel Mileage", val: record.Distance},
            {key: "Travel Cost", val: "R " + record.TotalCost},
            {key: "Manufacture Spec Ltrs/100Km", val: record.Spec},
            {key: "Allowance", val: "R " + record.Allowance},
            {key: "Used Rand/Km by Spec", val: "R " + record.RandKm},
            {key: "Target cost Rand/Km", val: "R " + record.Target},
            {key: "Actual cost Rand/Km", val: "R " + record.Actual},
            {key: "Cost unit Rand/Km", val: "R " + FillingCurrent.CostUnit},
            {key: "Flag Color", val: record.FLagColor},
            {key: "Flag Percentage", val: record.FlagPercentage + "%"},
        ]

        return (
            <GridItem xs={12} sm={12} md={12}>
                <h3>Detail usage {this.state.vehicle} [{record.DateIn}]</h3>
                <TableSimple
                    data={ls}
                    includeHeader={false}
                />
                <ControllerButton
                    variant="outlined"
                    color="primary"
                    onClick={this.closeShowDetailUsage}
                />
            </GridItem>
        )

    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Fuel Spec"} displayLeftPanel={true}>

                <GridContainer style={{marginTop: 50, paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                    <GridItem xs={12} sm={12} md={12}>
                        <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                            Fuel Monthly Overview
                        </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: 10}}>
                        <MyBoxSelecttion
                            {...this.props}
                            label={"Vehicle"}
                            name={"vehicle"}
                            noImage={true}
                            ls={GetEntityData("vehicle", this.props)}
                            handleInput2={this.handleInput2}
                            getHandleValue={this.getHandleValue}
                            getValue={this.getHandleValue}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                            <ControllerCalendar
                                label={"Start Date"}
                                label2={"End Date"}
                                handleInput2={this.handleInput2}
                                name={"startDate"}
                                getHandleValue={this.getHandleValue}
                                name2={"endDate"}
                                onSubmit={this.onSubmit}
                                btnLabel={"Search"}
                                variant={"contained"}
                                color={"primary"}
                            />
                        </GridItem>
                    </GridItem>
                    {this.renderList()}
                    {this.renderDetail()}
                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}


export default Connector(FuelUsageMonthly);