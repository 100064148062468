import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default (props) =>{
    const classes = useStyles();
    let {onAddRecord,state}=props;

    const onExit=()=>{
        window.location.href = "#/operations-menu"
    }
    const onAdd=()=>{
        if(state.selectedVehicle ===""){
            alert("Please select vehicle before adding");
            return
        }
        onAddRecord()
    }

    return (
        <div className={classes.root}>
            <Button variant="outlined" color="secondary" onClick={()=>onExit()}>
                Exit view maintenance event
            </Button>
            <Button variant="outlined" color="primary" onClick={()=>onAdd()}>
                Add new maintenance event
            </Button>
        </div>
    );
}
