import GridContainer from "../../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../../Common/dashboard/Grid/GridItem";
import React from "react";
import SelectBox from "./ControllerSelectBox";
import InputBox from "./ControllerInputBox";


export default (props)=>{
    let {handleInput,handleInput2, getHandleValue}=props;
    const renderBox=(name,label,ls)=>{
        return(
            <SelectBox
                {...props}
                name={name}
                ls={ls}
                handleInput={handleInput}
                getHandleValue={getHandleValue}
                label={label}
            />
        )
    }

    return(
        <GridContainer style={{marginTop: 50,paddingRight:20,paddingBottom:30}}>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                    Step: 1 General information about maintenance event
                </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {renderBox("newEventType","Type of Maintenance Event",[
                    {key:"Emergency Repair",val:"Emergency Repair"},
                    {key:"Inspection",val:"Inspection"},
                    {key:"Planned Service",val:"Planned Service"},
                ])}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {renderBox("newEventCategory","Category of Maintenance Event",[
                    {key:"mileage",val:"Mileage Based"},
                    {key:"time",val:"Time Based"},
                ])}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <InputBox
                    name={"newEventName"}
                    handleInput2={handleInput2}
                    required={true}
                    label={"Event Name"}
                    styleIn={{minWidth:"100%"}}
                    {...props}
                />
            </GridItem>
        </GridContainer>
    )
}