import React from "react";
import {SocketContext, useWebsocket} from './index';
import Config from "../../configures";
import ReconnectingWebSocket from "reconnecting-websocket";
import store from '../../redux/store';
import Connect from "../../redux/common/Connector";


let serverUrl = Config.ServerGlobal + "/backend-fleetminder/api/websocket";

const MyFunctionalComponent = () => {
    const priceData = useWebsocket();

}

export class WrappedSocketManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriber: null,
            prices: {},
            org: "easipath",
            ref: "biangacila@gmail.com",
            role: "admin",
            names: "Merveilleux",
            service: "taxi",
            info: "none",
        };

        const _this = this;
        store.subscribe(() => {
            const data = store.getState();
            _this.receivedProps(data);
        });


    }

    socket = null;
    static contextType = SocketContext;

    componentDidMount = async () => {
        //this.connection();
        await this.fetchData();
    }

    componentWillUnmount = async () => {

        try {
            this.socket !== null && this.socket.disconnect();
        } catch (e) {
            // socket not connected
        }
    }

    receivedProps(nextProps) {
    }

    fetchData = async () => {
        this.props.LoadData();
        this.props.LoadDataMaintenance();
        this.props.LoaderFuelMiddleware();

    }

    connection = () => {
        serverUrl = serverUrl + '/' + this.state.org + '/' + this.state.ref + '/' + this.state.role +
            '/' + this.state.names + '/' + this.state.service + '/' + this.state.info;
        const uri = serverUrl;
        const urls = [uri];
        let urlIndex = 0;
        const options = {
            WebSocket: undefined,
            maxReconnectionDelay: 10000,
            minReconnectionDelay: 1000 + Math.random() * 4000,
            reconnectionDelayGrowFactor: 1.3,
            minUptime: 5000,
            connectionTimeout: 4000,
            maxRetries: Infinity,
            debug: false,
        };
        // round robin url provider
        const urlProvider = () => urls[urlIndex++ % urls.length];
        this.socket = new ReconnectingWebSocket(urlProvider, [], options);
        this.socket.onopen = () => {
            console.log("####---> ", 'Hello Mr. Server! monitor socket HODER BY DRIVER' + uri);
            const title = "WS_CONNCABS_CONNECTED";
            const data = this.state.info;
            try {
                this.socket.send(JSON.stringify({type: title, Type: title, Payload: data}));
            } catch (e) {
                console.log("WS_CONNCABS_CONNECTED ERROR sendMessageSocket > ", e, title, data)
            }
        };
        this.socket.onmessage = ({data}) => {
            let message = JSON.parse(data);
            console.log("onMessage Socket ^^^> ", message.Type, " > ", data);
        }
    }

    render() {
        return (
            <SocketContext.Provider value={{
                prices: this.state.prices
            }}>
                {this.props.children}
            </SocketContext.Provider>
        );
    }

}


export default Connect(WrappedSocketManager);
