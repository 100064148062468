import {createStore, applyMiddleware} from 'redux';
import rootReducer from './index';
import logger from "redux-logger";
import {
    EntityDataMiddleware,
    SaveGlobalMiddleware,
    MaintenanceDataMiddleware,
    SaveMaintenanceMiddleware,
    FuelDataMiddleware,
    SaveDeleteFuelMiddleware
} from "./services";


const store = createStore(
    rootReducer,
    applyMiddleware(logger,
        EntityDataMiddleware,
        SaveGlobalMiddleware,
        MaintenanceDataMiddleware,
        SaveMaintenanceMiddleware,
        FuelDataMiddleware,
        SaveDeleteFuelMiddleware
    )
);


export default store;
