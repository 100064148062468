

const SERVER_RESERVATION = "ServerFleetminder";
const SERVER_CRM="ServerCRM";
const CURRENT_DATABASE="reservation";
const SERVER_FLEETMINDER="ServerFleetminder";
const  SERVER_VEHICLEMINDER= "ServerVehicleminder";

const DEFAULT_MODULES=[
    {key: "vehicle", val: "Vehicle"},
    {key: "driver", val: "Driver"},
    {key: "authorizer", val: "Authorizer"},
    {key: "customer", val: "Customer"},

];

export{
    SERVER_RESERVATION,
    SERVER_CRM,
    CURRENT_DATABASE,
    DEFAULT_MODULES,
    SERVER_FLEETMINDER,
    SERVER_VEHICLEMINDER
}
