import React,{Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Attributes from "./Page";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Vehicle', 'Driver', 'Personnel','Supplier','Location'];
}

function getStepContent(stepIndex,props) {
    switch (stepIndex) {
        case 0:
            return <Attributes {...props} module={"vehicle"} />;
        case 1:
            return <Attributes {...props} module={"driver"} />;
        case 2:
            return <Attributes {...props} module={"personnel"} />;
        case 3:
            return <Attributes {...props} module={"supplier"} />;
        case 4:
            return <Attributes {...props} module={"location"} />;
        default:
            return 'Unknown stepIndex';
    }
}

export default  (props)=> {
    let {goTo}=props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel><span style={{fontSize:12}}>{label}</span></StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <Fragment>
                        <Typography className={classes.instructions}>{getStepContent(activeStep,props)}</Typography>
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Back
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNext} style={{float:"right"}}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                        <div style={{marginTop:150}}>
                            <Button variant="contained" color="secondary" onClick={()=>goTo("menu")}>
                                Close & Finish
                            </Button>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
}
