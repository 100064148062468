import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const textStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
}));

export default ({handleInput, title, open, onClose, nameInput, submitNode,labelInput}) => {
    const classes = useStyles();
    const txtClasses = textStyles();
    const [modalStyle] = React.useState(getModalStyle);


    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={onClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title" style={{textAlign:"center"}}>{title}</h2>

                    <Grid container sm={12} style={{marginTop: 35, minHeight: "100%"}} component={"div"}>
                        <Grid item sm={12}>
                            <TextField
                                className={txtClasses}
                                required
                                id="outlined-required"
                                label={labelInput}
                                defaultValue=""
                                variant="outlined"
                                name={nameInput}
                                onChange={(e) => {
                                    handleInput(e)
                                }}
                                style={{
                                    minWidth:"100%"
                                }}
                            />

                        </Grid>
                        <Grid item sm={12}>
                            <button
                                onClick={() => onClose()}
                                style={{
                                    float: "left",
                                    backgroundColor: "red",
                                    color: "white",
                                    margin: 10,
                                    minWidth: 75,
                                    minHeight: 35,
                                    borderColor: "black",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                }}

                            >
                                Close
                            </button>
                            <button
                                onClick={() => submitNode()}
                                style={{
                                    float: "right",
                                    backgroundColor: "#42a5f5",
                                    color: "white",
                                    margin: 10,
                                    minWidth: 75,
                                    minHeight: 35,
                                    borderColor: "black",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                }}
                            >
                                Submit
                            </button>

                        </Grid>
                    </Grid>


                </div>
            </Modal>
        </div>
    );
}
