import React, {Component} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {makeStyles, TextField, withStyles} from "@material-ui/core";

class ControllerCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
        }
    }

    convertDateFormat_YYYYMMDD = (datesIn) => {
        let myDate = moment(datesIn).format("YYYY-MM-DD");
        return myDate;
    }
    onChange = (date) => {
        let mDate = moment(date).format("YYYY-MM-DD");
        this.setState({
            date: date
        });
        this.props.handleInput(this.props.name, mDate)
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor="inputState">{this.props.label}</label>
                <DatePicker
                    selected={this.state.date}
                    onChange={this.onChange}
                    className="form-control"
                />
            </div>
        )
    }
}

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(ControllerCalendar);
const innerStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: "100%"
    },
    margin: {
        margin: theme.spacing(1),
        maxWidth: "95%"
    },
    formControl: {
        margin: theme.spacing(3),
        borderColor: "gray",
        borderRadius: 3,
        borderWidth: 1
    },
}));


export default (props) => {
    let {name, label, required, handleInput2, styleIn, getHandleValue} = props;
    const classes = innerStyles();
    const handleChange = event => {

        handleInput2(name, event.target.value)
    };
    return (
        <CssTextField
            className={classes.margin}
            label={label}
            variant="outlined"
            id="custom-css-outlined-input"
            name={name}
            required={required}
            onChange={handleChange}
            handleInput={handleInput2}
            style={styleIn}
            value={getHandleValue(name)}
        />
    )
}

export {
    ControllerCalendar
}
