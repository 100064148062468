import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default (props) =>{
    const classes = useStyles();
    let {variant,color,onClick,styles,label}=props;

    return (
        <div className={classes.root} style={styles}>
            <Button variant={variant} color={color} onClick={()=>onClick()}>
                {label}
            </Button>

            {/*<Button variant="outlined" color="secondary" onClick={()=>onExit()}>
                Exit view maintenance event
            </Button>
            <Button variant="outlined" color="primary" onClick={()=>onAdd()}>
                Add new maintenance event
            </Button>*/}
        </div>
    );
}
