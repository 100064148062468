import React,{Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
export default function Chips({props,pageTitle}) {
    const styles={
        currentTitle:{
            color:"#42a5f5",
            fontWeight:"bold",
            fontSize:12
        },
        myFont:{
            fontSize:12
        }
    }

    return (
        <Fragment>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit"  onClick={handleClick} style={styles.myFont}>
                    {props.navigations.currentApp.toLocaleUpperCase()}
                </Link>
                <Link color="inherit"  onClick={handleClick} style={styles.myFont}>
                    {props.navigations.currentModule.toLocaleUpperCase()}
                </Link>
                <Typography color="inherit"  style={styles.currentTitle}>{pageTitle.toLocaleUpperCase()}</Typography>
            </Breadcrumbs>
            <Divider style={{marginBottom:10}}/>
        </Fragment>
    );
}
