import React from 'react';
import {makeStyles, Paper, withStyles} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import LinkIcon from '@material-ui/icons/Link';


import {MyBoxSelecttion} from "./fn";
import TableDataView from "../../Common/tables/TableDataView";
import ListOfAssociation from "./ListOfAssociate";


export default (props)=>{
    let {handleInput,getHandleValue,getEntityData,state,submitNewLink}=props;

    const classes = useStyles();

    let activeStep=0;
    if(state.main !=="" && state.supplier!==""){
        activeStep=1;
    }

   // ls = attachImageProfile(props.allData.Files, ls);

    const columns=[
        {id:"main",label:state.MainEntity},
        {id:"supplier",label:state.MainEntity},
    ]

    let data = {columns: columns, data: props.allData.Associations};
    //console.log("(:)-->Association > ",props.allData)


    return(
        <Paper style={{paddingLeft:135,paddingRight:135}}>
            <h4 style={{marginLeft:135,color:"#2196f3",fontSize:16}}>Link Entity Add New...</h4>

            <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin} >
                <FormLabel component="legend" className={"legend"}>
                    <span style={{
                        fontSize: 16,
                        fontWeight:"normal"
                    }}>Adding New Link</span>
                </FormLabel>

                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={5} sm={5} md={5}>
                        {/*{renderBox("Main","main",getEntityData(state.MainEntity))}*/}
                        <MyBoxSelecttion
                            {...props}
                            label={state.MainEntity}
                            name={"main"}
                            ls={getEntityData(state.MainEntity)}
                            noImage={true}
                        />
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5}>
                        {/*{renderBox("Supplier","supplier",getEntityData(state.SupplierEntry))}*/}
                        <MyBoxSelecttion
                            {...props}
                            label={state.SupplierEntry}
                            name={"supplier"}
                            ls={getEntityData(state.SupplierEntry)}
                            noImage={true}
                        />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={submitNewLink}
                            className={classes.backButton}
                            style={{
                                color:"#2196f3",
                                marginTop:25
                            }}
                        >
                            <LinkIcon  />Add
                        </Button>
                    </GridItem>
                </GridContainer>

                <Paper >
                    {/*<h5>List of association</h5>*/}
                    <ListOfAssociation {...props}/>
                    {/*<TableDataView
                        {...data}
                        tableTitle={""}
                    />*/}
                </Paper>

            </FormControl>

        </Paper>
    )
}

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));
