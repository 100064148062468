import React, {Fragment} from 'react';
import {
    makeStyles,
} from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Grid,
    Paper,
    GridList
} from '@material-ui/core';
import "../../../assets/css/fieldset-lengend.css";

import FormEditInputRadio from "./FormEditInputRadio";
import FormEditInputCheckbox from "./FormEditInputCheckbox";
import FormEditInputSelect from "./FormEditInputSelect";
import FormEditInputText from "./FormEditInputText";
import FormEditInputOptions from "./FormEditInputOptions";

const innerStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(3),
        borderColor: "gray",
        borderRadius: 3,
        borderWidth: 1
    },
}));


export default function myForm({formTitle, onChangeList, onChangeText, onChangeCheck, onChangeRadio, onCancel, onSubmit, formType, data, showTitle}) {
    const classes = innerStyles();

    if (typeof data === "undefined") {
        return null
    }

    let canShowTitle = true;
    if (typeof showTitle !== "undefined") {
        canShowTitle = false;
    }

    const innerStyle = {
        input: {
            maxHeight: 10,
            minWidth: "100%",

        }
    }

    return (
        <Paper style={{paddingLeft:135,paddingRight:135}}>
        <form onSubmit={onSubmit} className={classes.root} noValidate>
            {canShowTitle ?
                <Typography variant="h3" component="h4" gutterBottom>
                    <span style={{color: "#2196f3", fontSize: 16, fontWeight: "bold"}}><u>{formTitle}</u></span>
                </Typography> : null}

            {formType === "online" ?
                <Fragment>
                    <GridList  className={classes.gridList} cols={3} sm={12} md={12}>
                        {data.map((row, index) => {
                            if (row.type === "text" || row.type === "float") {
                                return (
                                    <Grid sm={4} md={4}>
                                        <FormEditInputText row={row} styleIn={innerStyle.input}
                                                           onChangeText={onChangeText}/>
                                    </Grid>
                                );
                            }
                            if (row.type === "option") {
                                return (
                                    <Grid sm={4} md={4}>
                                        <FormEditInputCheckbox row={row} onChangeCheck={onChangeCheck}/>
                                    </Grid>
                                )
                            }
                            if (row.type === "radio" || row.type === "boolean") {
                                return (
                                    <Grid sm={4} md={4}>
                                        <FormEditInputRadio row={row} onChangeRadio={onChangeRadio}/>
                                    </Grid>
                                )
                            }
                            if (row.type === "combobox") {
                                return (
                                    <Grid sm={4} md={4}>
                                        <FormEditInputSelect row={row} onChangeText={onChangeText}/>
                                    </Grid>
                                )
                            }
                            if (row.type === "list") {
                                return (
                                    <Grid sm={4} md={4}>
                                        <FormEditInputOptions row={row} onChangeList={onChangeList}/>
                                    </Grid>
                                )
                            }


                            return (
                                <Grid sm={4} md={4}>
                                    <FormEditInputText row={row}/>
                                </Grid>
                            )
                        })}
                        <Button onClick={() => onCancel()} type={"button"} variant="contained" color="secondary"
                                className={classes.margin}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" color="primary" className={classes.margin}>
                            Submit
                        </Button>
                    </GridList>

                </Fragment> :
                <Fragment>
                    <GridList className={classes.gridList} cols={3}  sm={12} md={12}>
                        {data.map((row, index) => {

                            return (
                                <Fragment>
                                    {row.type === "text" && (
                                        <Grid sm={4} md={4}>
                                        <FormEditInputText row={row} styleIn={{minWidth: "93%"}}
                                                           onChangeText={onChangeText}/>
                                        </Grid>
                                    )}
                                    {row.type === "float" && (
                                        <Grid sm={4} md={4}>
                                        <FormEditInputText row={row} styleIn={{minWidth: "98%", minHeight: "100%"}}
                                                           onChangeText={onChangeText}/>
                                        </Grid>
                                    )}
                                    {row.type === "option" && (
                                        <Grid sm={4} md={4}>
                                        <FormEditInputCheckbox row={row} styleIn={{minWidth: "98%",minHeight: "100%",fontSize: 12}}
                                                               onChangeCheck={onChangeCheck}/>
                                        </Grid>
                                    )}
                                    {row.type === "radio" && (
                                        <Grid sm={4} md={4}>
                                        <FormEditInputRadio row={row} styleIn={{minWidth: "98%",minHeight: "100%",fontSize: 12}}
                                                            onChangeRadio={onChangeRadio}/>
                                        </Grid>
                                    )}
                                    {row.type === "boolean" && (
                                        <Grid sm={4} md={4}>
                                        <FormEditInputRadio row={row} styleIn={{minWidth: "98%",minHeight: "100%",fontSize: 12}}
                                                            onChangeRadio={onChangeRadio}/>
                                        </Grid>
                                    )}
                                    {row.type === "combobox" && (
                                        <Grid sm={4} md={4}>
                                        <FormEditInputSelect row={row} styleIn={{minWidth: "98%",minHeight: "100%",fontSize: 12}}
                                                             onChangeText={onChangeText}/>
                                        </Grid>
                                    )}
                                    {row.type === "list" && (
                                        <Grid sm={4} md={4}>
                                        <FormEditInputOptions row={row} onChangeList={onChangeList}/>
                                        </Grid>
                                    )}
                                </Fragment>
                            )
                        })}

                        {canShowTitle ?
                            <Grid component={Paper} sm={12} md={12} style={{float: "right"}}>
                                <Button onClick={() => onCancel()} type={"button"} variant="contained" color="secondary"
                                        className={classes.margin}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" color="primary" className={classes.margin}>
                                    Submit
                                </Button>
                            </Grid> : null}
                    </GridList>
                </Fragment>
            }

        </form>
        </Paper>
    );
}
