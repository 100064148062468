import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MyTreeItem from "./TreeItem";

const getTreeItemsFromData = (treeItems,newNode,onRemoveNode) => {
    return treeItems.map(treeItemData => {
        let children = undefined;
        if (treeItemData.children && treeItemData.children.length > 0) {
            children = getTreeItemsFromData(treeItemData.children,newNode,onRemoveNode);
        }


        return (
            <TreeItem
                key={treeItemData.id}
                nodeId={treeItemData.id}
                label={<MyTreeItem
                    treeItemData={treeItemData}
                    children={children}
                    onClickAdd={newNode}
                    onRemoveNode={onRemoveNode}
                />}
                children={children}
            />
        );
    });
};
const DataTreeView = ({ treeItems,newNode,onRemoveNode }) => {
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {getTreeItemsFromData(treeItems,newNode,onRemoveNode)}
        </TreeView>
    );
};

export default ({treeItems,newNode,onRemoveNode})=>{
    return(
        <DataTreeView treeItems={treeItems} newNode={newNode} onRemoveNode={onRemoveNode}/>
    )
}

