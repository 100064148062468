import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Button} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import FormEditInputSelect from "../Common/form-input-control/FormEditInputSelect";
import FormEditInputText from "../Common/form-input-control/FormEditInputTextWithFrame";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: "80%",
        maxWidth: "60%",
        height: "80%",
        maxHeight: "80%",
        marginRight: "20%",
        marginLeft: "10%",
        marginTop: "0%",
        marginBottom: "20%",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    gridList: {
        width: "80%",
        height: "80%",
        maxWidth: "80%",
        maxHeight: "60%",
        overflow: "scroll"
    },
}));

export default ({onCancel,record,onChangeCategory, status, handleClose}) => {
    if(!status){
        return null;
    }
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    let options=["profile","gallery","other"];
    let rowCategory={
        field: "fileCategory",
        label: "Category",
        options: options,
        type: "option",
        required: true,
        dataType: "string"
    }
    let rowName={
        field: "fileName",
        label: "Name or Type of your File (if is not profile)",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    }

    return (
        <Paper style={{
            width: "80%",
            height: "80%"
        }}>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={status}
                onClose={()=>handleClose(record)}
            >
                <div style={modalStyle} className={classes.paper}>
                    <Grid container sm={12} style={{marginTop: 35, minHeight: "100%"}} component={"div"}>
                        <h1>Choose File Category</h1>
                        <Grid item sm={12}>
                            <FormEditInputSelect row={rowCategory} onChangeText={(row,e)=>onChangeCategory("fileCategory",e)}/>
                        </Grid>
                        <Grid item sm={12}>
                            <FormEditInputText
                                row={rowName}
                                onChangeText={(row,e)=>onChangeCategory("fileName",e)}
                                sectionName={"Name"}
                                styleIn={{width:"100%"}}
                            />
                        </Grid>
                        <Grid item sm={12}>

                            <Button
                                onClick={()=>onCancel()}
                                style={{float: "left", color: "gray", fontWeight: "bold", fontSize: 18}}
                            >Cancel</Button>

                            <Button
                                onClick={()=>handleClose(record)}
                                style={{float: "right", color: "red", fontWeight: "bold", fontSize: 18}}
                            >Continue</Button>
                        </Grid>


                    </Grid>
                </div>

            </Modal>
        </Paper>
    );
}
