import React from "react";

export default ({divId, periodFrom, periodTo, categories, prices, expenses}) => {

    let Highcharts = window.Highcharts;

    console.log("88889 ((((---> ", categories, prices, expenses);

    Highcharts.chart(divId, {
        chart: {
            zoomType: 'xy'
        },
        title: {
            text: ''//'Total fleet spend: ' + periodFrom + " To " + periodTo
        },
        subtitle: {
            text: ''
        },
        xAxis: [{
            categories: categories /**/,
            crosshair: true
        }],
        yAxis: [
            { // Primary yAxis
                labels: {
                    format: 'R-{value}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: 'Fuel',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }, { // Secondary yAxis
                title: {
                    text: 'Price',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    format: 'R {value}',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                opposite: true
            }],
        tooltip: {
            shared: true
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            x: 120,
            verticalAlign: 'top',
            y: 100,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        series: [{
            name: 'Fuel Cost',
            type: 'column',
            yAxis: 0,
            data: expenses, // [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
            tooltip: {
                valueSuffix: ' R'
            }

        }, {
            name: 'Price',
            type: 'spline',
            yAxis: 1,
            data: prices,// [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
            tooltip: {
                valueSuffix: 'R'
            }
        }]
    });

    console.log("(Chart):> finish chart exective 1 ")


}