import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    label:{
        fontSize:12
    }
}));

export default ({row,onChangeRadio})=> {
    const classes = useStyles();
    const [value, setValue] = React.useState('female');

    const handleChange = event => {
        setValue(event.target.value);
        onChangeRadio(row,event)
    };

    return (
        <div>
            <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin}>
                <FormLabel component="legend" className={"legend"} style={{fontSize:12}}>
                    <span style={{fontSize:12}}>{row.label}</span></FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} style={{fontSize:12}}>
                    {row.options.map((item) => {
                        return (
                            <FormControlLabel
                                aria-setsize={15}
                                value={item.key}
                                control={<Radio />}
                                label={item.val}
                                style={{fontSize:12}}
                                className={useStyles}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>

        </div>
    );
}
