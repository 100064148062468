import {FindImages, sortData} from "../../../api/fn";

export {
    buildAssociationData
}

const buildAssociationData=(props,self)=>{
    const Databases = props.allData.Databases;
    const files = props.allData.Files;
    const StoreAssociations = props.allData.Associations;
    let associations=[];
    //let self = this;

    const GetRecord=(module,ref)=>{
        let names = "---";
        for (let i in Databases) {
            const row = Databases[i];
            if (row.module === module && row.ref===ref) {
                names = getDisplayNameEntity(props,module,row)
            }
        }
        return names;
    }

    for(let i in StoreAssociations){
        let row=StoreAssociations[i];
        /**
         * Get Names of participants
         */
        row.mainname  =GetRecord(row.mainentity,row.main);
        row.suppliername  =GetRecord(row.supplierentity,row.supplier);
        /**
         * Get Image profile link
         */
        row. mainimg =FindImages(row.main, files);
        row. supplierimg =FindImages(row.supplier, files);
        /**
         * save into array
         */
        associations.push(row);
    }

    return associations;

}

const getDisplayNameEntity=(props,module,record)=>{
    const fieldList=getListDisplayField(props,module);
    let names="";
    for(let  i in fieldList){
        const row=fieldList[i];
        names +=record.data[row.fieldname]+" "
    }
    return names
}
const getListDisplayField=(props,module)=>{
    let ls = [];
    const attributes = props.allData.Attributes;
    for (let i in attributes) {
        const row = attributes[i];
        if (row.module === module) {
            if(row.display){
                ls.push(row)
            }
        }
    }

    ls =sortData("displayposition",ls);
    return ls
}