import React from 'react';

import CanvasJSReact from './canvasjs.react';

let CanvasJS = CanvasJSReact.CanvasJS;
let CanvasJSChart = CanvasJSReact.CanvasJSChart;

class ChartMonthlyOverview2 extends React.Component {
    constructor(props) {
        super(props);
        this.toggleDataSeries = this.toggleDataSeries.bind(this);
    }

    toggleDataSeries = (e) => {
        if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        }
        else {
            e.dataSeries.visible = true;
        }
        this.chart.render();
    }

    buildData = () => {
        let {data} = this.props;
        let mileages = [];
        let costs = [];

        /*
        {
                    date:o.date,
                    mileages:o.TotalMileage,
                    fuels:o.TotalLitters,
                    cost:o.TotalFuelCost
                }
                */

        for (let i in data) {
            const row = data[i];
            const arr = row.date.split("-")
            const year = parseInt(arr[0])
            const month = parseInt(arr[1]);
            const date = new Date(year, month, 1);
            /*const mileage = { x: new Date(year, month, 1), y: parseInt(row.mileages) };
            const cost = { x: new Date(year, month, 1), y: parseFloat(row.cost) };*/
            const mileage = {x: date, y: parseInt(row.mileages)};
            const cost = {x: date, y: parseFloat(row.cost)};
            mileages.push(mileage);
            costs.push(cost);
        }

        return {
            mileage: mileages,
            cost: costs
        }
    }

    render() {

        const info = this.buildData();
        console.log("VVVVOOO--> ", info);
        const options = {
            theme: "light2",
            animationEnabled: true,
            zoomEnabled: true,
            title: {
                text: "Mileage drive and Fuel Cost"
            },
            subtitles: [{
                text: "",//"Click Legend to Hide or Unhide Data Series"
            }],
            axisX: {
                title: "Dates"
            },
            axisY: {
                title: "Fuel Cost(Rand)",
                titleFontColor: "#51CDA0",
                lineColor: "#51CDA0",
                labelFontColor: "#51CDA0",
                tickColor: "#51CDA0",
                logarithmic: true,
                includeZero: true
            },
            axisY2: {
                title: "Mileage(Km)",
                titleFontColor: "#6D78AD",
                lineColor: "#6D78AD",
                labelFontColor: "#6D78AD",
                tickColor: "#6D78AD",
                includeZero: false,
                logarithmic: true,
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                itemclick: this.toggleDataSeries
            },
            data: [
                {
                    type: "line",
                    name: "Cost",
                    showInLegend: true,
                    xValueFormatString: "MMM YYYY",
                    yValueFormatString: "R#,##0.#",
                    dataPoints: info.cost
                },
                {
                    type: "line",
                    name: "Distance",
                    showInLegend: true,
                    axisYType: "secondary",
                    xValueFormatString: "MMM YYYY",
                    yValueFormatString: "#,##0 Km",
                    dataPoints: info.mileage
                }


            ]
        }


        return (
            <div>
                <CanvasJSChart options={options}
                               onRef={ref => this.chart = ref}
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }

}

export default ChartMonthlyOverview2;