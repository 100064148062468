import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';












const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    primary:{
        fontSize:54,
    }
}));

function generate(element) {
    return [0, 1, 2].map(value =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

export default  ({verify,allData,completedList,incompletedList}) =>{

    const classes = useStyles();


    return (
        <div className={classes.root}>


            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h6" className={classes.title}>
                        <u>Setup Attribute Status</u>
                    </Typography>
                    <div className={classes.demo} style={{maxWidth:"50%"}}>
                        <List dense={true}>
                            {completedList.map((item,index)=>{
                                return(
                                    <ListItem>
                                        <ListItemAvatar>

                                                <CheckIcon style={{color:"green"}}/>

                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item}
                                        />
                                    </ListItem>
                                )
                            })}

                        </List>
                    </div>

                    <div className={classes.demo} style={{maxWidth:"50%"}}>
                        <List dense={false}>
                            {incompletedList.map((item,index)=>{
                                return(
                                    <ListItem>
                                        <ListItemAvatar>

                                            <ClearIcon style={{color:"red"}} />

                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item}
                                        />
                                        {/*<ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete">
                                                <SettingsIcon style={{color:"blue"}}/>
                                            </IconButton>
                                        </ListItemSecondaryAction>*/}
                                    </ListItem>
                                )
                            })}

                        </List>
                    </div>
                </Grid>

            </Grid>
        </div>
    );
}
