import React,{useRef, useEffect, useState} from "react";

export default ({colorIn, status, actual, numberVehicle}) => {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);

    const styles={
        boxScore:{
            minWidth:"100%",
            maxWidth:"100%",
            minHeight:dimensions.width / 2,
            maxHeight:dimensions.width / 2,
            display:"flex",
            flex:1,
            justifyContent:"center",
            alignItems: "center",
            backgroundColor:colorIn,
           borderRadius:10
        },
        circle:{
            minWidth:"50%",
            maxWidth:dimensions.width / 2,
            minHeight:dimensions.width / 2,
            display:"flex",
            flex:1,
            flexDirection:"column",
            justifyContent:"center",
            alignItems: "center",
            borderRadius: dimensions.width / 4,
            backgroundColor: "white"
        },
        circleLabel1:{
            color: "lightblack",
            fontSize: 35,
            fontWeight: "bold"
        },
        circleLabel2: {
            fontSize: 12,
            color: "lightblack",
        },



    }


    return (
            <div style={styles.boxScore}  ref={targetRef}>
                <div style={{
                    minWidth: dimensions.width / 3,
                    maxWidth:dimensions.width / 3,
                    minHeight:dimensions.width / 3,
                    maxHeight:dimensions.width / 3,
                    display:"flex",
                    flex:1,
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems: "center",
                    borderRadius: dimensions.width / 4,
                    backgroundColor: "white"
                }}>
                    <div style={styles.circleLabel1}>{actual}</div>
                    <div style={styles.circleLabel2}>R/Km</div>
                </div>
            </div>

    )
}