import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";
import InputButton from "./ControllerButton";
import BoxSelect from "../../Pages/fuel/ComboxFilterController";
import GridItem from "../dashboard/Grid/GridItem";

export default (props) => {
    let {
        name,
        name2,
        label,
        label2,
        handleInput2,
        btnLabel,
        onSubmit,
        variant,
        color,
        getAllCategoriesVehicle,
        getAllCompaniesVehicle
    } = props;
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date(/*'2014-08-18T21:11:54'*/));
    const [selectedDate2, setSelectedDate2] = React.useState(new Date(/*'2014-08-18T21:11:54'*/));

    const convertDateFormat_YYYYMMDD = (datesIn) => {
        let myDate = moment(datesIn).format("YYYY-MM-DD");
        return myDate;
    }
    const handleDateChange1 = date => {
        setSelectedDate(date);
        handleInput2(name, convertDateFormat_YYYYMMDD(date))
    };
    const handleDateChange2 = date => {
        setSelectedDate2(date);
        handleInput2(name2, convertDateFormat_YYYYMMDD(date))
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around" style={{alignItems: "center"}}>
                <GridItem xs={3} sm={3} md={2}
                          style={{flex: 1, justifyContent: "center", alignItems: "center", paddingTop: 10}}>
                    <BoxSelect
                        options={getAllCategoriesVehicle()}
                        name={"selectedVehicleCategory"}
                        handleInput2={handleInput2}
                        label={"Vehicle Category"}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={2}
                          style={{flex: 1, justifyContent: "center", alignItems: "center", paddingTop: 10}}>
                    <BoxSelect
                        options={getAllCompaniesVehicle()}
                        name={"selectedVehicleCompany"}
                        handleInput2={handleInput2}
                        label={"Vehicle Company"}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={label}
                        format="yyyy-MM-dd"
                        value={selectedDate}
                        onChange={handleDateChange1}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label={label2}
                        format="yyyy-MM-dd"
                        value={selectedDate2}
                        onChange={handleDateChange2}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </GridItem>
                <GridItem xs={2} sm={2} md={1}>
                    <InputButton
                        label={btnLabel}
                        variant={variant}
                        onClick={onSubmit}
                        styles={{float: "right", marginTop: 15}}
                        color={color}
                    />
                </GridItem>

            </Grid>
        </MuiPickersUtilsProvider>
    );
}
