import { combineReducers } from 'redux';

import menus from './reducer/reducer-menus';
import login from './reducer/reducer-login';
import navigations from './reducer/reducer-navigation';
import addingModel from "./reducer/reducer-adding-model";
import info from "./reducer/reducer-selection-info";
import verify from "./reducer/reducer-verification-module";
import allData from "./reducer/reducer-all-data";
import maintenanceData from "./reducer/reducer-maintenance-data";
import fuel from "./reducer/reducer-fuel";

export default combineReducers({
    menus,
    login,
    navigations,
    addingModel,
    info,
    verify,
    allData,
    maintenanceData,
    fuel
})

/*
vim /etc/nginx/sites-available/


        location /backend-dms {
        proxy_pass http://localhost:19441;
    }


        location /backend-mailsender {
        proxy_pass http://localhost:19318;
    }

https://voip.easipath.com:19318/backend-mailsender

 */
