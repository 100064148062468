import {Paper} from "@material-ui/core";
import TableDataView from "../../../Common/tables/TableDataView";
import {UpcaseFirst} from "../../../../api/fn";
import React from "react";
import ButtonNavigation from "./ButtonNavigation";
import GridItem from "../../../Common/dashboard/Grid/GridItem";
import BoxSelect from "./BoxSelect";
import {MyBoxSelecttion} from "../../SetupAssociation/fn";
import GridContainer from "../../../Common/dashboard/Grid/GridContainer";


export default (props) => {

    let {ls,columns, onAddRecord, onDelete, state} = props;



    let data = {columns: columns, data: ls};


    const makeUpcase = (inWord) => {
        const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
        return upper;
    }
    return (
        <Paper style={{paddingLeft: 0, paddingRight: 0}}>
            <ButtonNavigation {...props} />
            <h4 style={{
                marginTop: 10,
                marginLeft: 10,
                color: "#2196f3",
                fontSize: 16
            }}>{makeUpcase("Maintenance Event Plan")}</h4>

            <GridContainer style={{marginTop: 50,padding:10}}>
                <GridItem xs={12} sm={12} md={12} style={{marginBottom:10}}>
                    <BoxSelect
                        {...props}
                        label={"Vehicle List"}
                        name={"selectedVehicle"}
                        noImage={true}
                    />
                </GridItem>

                {state.selectedVehicle!==""?
                <GridItem xs={12} sm={12} md={12}>
                    <TableDataView
                        {...data}
                        tableTitle={UpcaseFirst("Vehicle: " + state.selectedVehicle + "")}
                        onAddRecord={onAddRecord}
                        onDelete={onDelete}
                    />
                </GridItem>:null}
            </GridContainer>
        </Paper>
    )
}