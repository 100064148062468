import React from "react";
import {FUEL_LOAD_DATA,FUEL_DELETE_DATA,FUEL_SAVE_DATA} from "../constants";

const LoaderFuelMiddleware =  () => {
    //console.log("LoaderFuelMiddleware :> ",FUEL_LOAD_DATA);
    return {
        type: FUEL_LOAD_DATA,
    }
}
const SaveFuelMiddleware=(payload)=>{
    return { ...payload,
        type: FUEL_SAVE_DATA,
    }
}
const DeleteFuelMiddleware=(payload)=>{
    return { ...payload,
        type: FUEL_DELETE_DATA,
    }
}

export{
    LoaderFuelMiddleware,
    SaveFuelMiddleware,
    DeleteFuelMiddleware
}