import React,{Fragment} from "react";
import FormEdit from "../../../components/Common/form-input-control/FormEdit";
import TableDataView from "../../../components/Common/tables/TableDataView";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import {
    deleteEntryCRM_WithData,
    onHandleInputFormEditCheck,
    onHandleInputFormEditRadio,
    onHandleInputFormEditText,
    sortData,
    onHandleInputFormEditList,
    submitEntryCRM2
} from "../../../api/fn";
import {GetUserToken} from "../../../api/fetching-data";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import {Paper} from "@material-ui/core";

 export default class PageSetupAttribute extends React.Component{
    constructor(props){
        super(props);
        this.state={
            step:0,
            module:"",
            isLoading: false,
            listField: listField,
            showEdit:false,
            newEntry:{},

        }

        this.onHandleInputFormEditText2 = onHandleInputFormEditText.bind(this);
        this.onHandleInputFormEditRadio2 = onHandleInputFormEditRadio.bind(this);
        this.onHandleInputFormEditCheck2 = onHandleInputFormEditCheck.bind(this);
        this.onHandleInputFormEditList2 = onHandleInputFormEditList.bind(this);
    }
     onHandleInputFormEditList = (row, e) => {
         this.onHandleInputFormEditList2(row, e, this);
     }
    onHandleInputFormEditText = (row, e) => {
        this.onHandleInputFormEditText2(row, e, this);
    }
    onHandleInputFormEditRadio = (row, e) => {
        this.onHandleInputFormEditRadio2(row, e, this);
    }
    onHandleInputFormEditCheck = (row, e) => {
        this.onHandleInputFormEditCheck2(row, e, this);
    }
    onCancel=()=>{
        this.setState({
            showEdit: false
        })
    }
    handleInput = async (e) => {
        //console.log("onChangeModule :)-> ", e.target.name, " > ", e.target.value);
        let tmp = this.state;
        tmp[e.target.name] = e.target.value;
        this.setState(tmp);
        // await this.fetchAttributes();
    }
    componentDidMount=() =>{
        //todo
        this.setState({
            module:this.props.module
        })

    }

    fetchData = async () => {
        this.setState({
            showEdit: false,
            newEntry: {},
        });
        this.props.LoadData();
    }
     findField=(inKey)=>{
         let record={};
         for(let i in this.state.listField) {
             const row = this.state.listField[i];
             if(row.field===inKey){
                 record=row;
             }
         }
         //console.log("findField (:)--> ",inKey," > ",record.dataType);
         return record;
     }
    submitEntry = async (e) => {

        //todo delete after live
        /*let data = this.props.enquiry.AttributePeople;
        let entry = {module:this.state.module,...this.state.newEntry}
        data.push(entry);
        this.props.SaveAttributePeople(data);
        this.setState({
            showEdit: false,
            newEntry: {},
        });*/


        let newEntry={};
        for(let key in this.state.newEntry){
            let val = this.state.newEntry[key];
            let datatype="string";
            let row =this.findField(key);
            datatype = row.dataType;
            /*for(let i in this.state.listField){
                const row =this.state.listField[i];
                //console.log(":)->DataType > ",row.field,"===",key," > ",(row.field===key)," => ",row.dataType);
                if(row.field===key){
                    datatype = row.dataType
                }
            }*/
            if(datatype==="boolean"){
                val=val === "yes"
            }else if(datatype==="float" ||datatype==="number"){
                val=parseFloat(val)
            }
            newEntry[key]=val;
        }

        //console.log("submitEntry-2-->", this.state.newEntry," > ",newEntry);
        this.setState({newEntry:newEntry});

        //todo uncomment after
        let endpoint = "/entity/insert/attribute";
        let othersField = [{key: "Org", val: GetUserToken().OrgCode},{key:"module",val:this.props.module}];
        let reloadFunction = [this.fetchData];
        await submitEntryCRM2(e, newEntry,othersField, endpoint, reloadFunction, this);
    }
    onDelete = async (rowIn) => {
        const e = undefined;
        if (!window.confirm("Are you sure to delete?")) {
            return null
        }
        let conds = [
            {key: "org", type: "string", val: GetUserToken().OrgCode},
            {key: "module", type: 'string', val: this.props.module},
            {key: "fieldname", type: "string", val: rowIn.fieldname}
        ];
        let hub = {
            Conditions: conds,
            Fields: [],
            DbName: "crm",
            TableName: "GeneralTableField",
            In: {}
        }
        //todo uncomment aft
        let endpoint = "/global-service/remove";
        let reloadFunction = [this.fetchData];
        await deleteEntryCRM_WithData(e,hub,endpoint,reloadFunction,this);
    }
    onAddRecord = () => {
        this.setState({
            showEdit: true,
            newEntry: {},
        })
    }
    validateVehicleField=()=>{

        const isAllow=(keyIn)=>{
            const arr=["Username","Password","phone","email"];
            for(let i in arr){
                let item=arr[i];
                if(item===keyIn){
                    return false
                }
            }
            return true
        }
        let ls=[];
        const attrs = this.state.listField;
        for(let i in attrs) {
            const row = attrs[i];
            if(this.state.module==="vehicle"){
                if(isAllow(row.field)){
                    ls.push(row)
                }
            }else{
                ls.push(row)
            }

        }
        return ls
    }
    renderListUtil = () => {
        if (this.state.showEdit) {
            return null
        }
        let columns = [
            {id: 'fieldname', label: 'Field', minWidth: 170, dataType: "string"},
            /*{id: 'section', label: 'Section', minWidth: 170, dataType: "string"},*/
            {id: 'position', label: 'Position On Form', minWidth: 170, dataType: "float"},
            {id: 'unique', label: 'Principal Identifier', minWidth: 170, dataType: "boolean"},
            {id: 'mandatory', label: 'Mandatory', minWidth: 170, dataType: "boolean"},
            {id: 'display', label: 'Display', minWidth: 170, dataType: "boolean"},
            {id: 'datatype', label: 'Datatype', minWidth: 170, dataType: "boolean"},
        ];
        if(this.props.module !=="vehicle"){

        }
        let ls=[];
        const attrs = this.props.allData.Attributes;
        for(let i in attrs){
            const row=attrs[i];
            //console.log("renderListUtil compare > ",row.module," == ",this.props.module,"> ",(row.module ===this.props.module))
            if(row.module ===this.props.module){
                ls.push(row)
            }
        }
        ls =sortData("position",ls);
        let data = {columns: columns, data: ls};
        return (
            <Paper style={{paddingLeft:135,paddingRight:135}}>
            <TableDataView
                {...data}
                tableTitle={""}
                onAddRecord={this.onAddRecord}
                onDelete={this.onDelete}
                style={{}}
            />
            </Paper>
        )
    }

    renderEditForm = () => {
        if (!this.state.showEdit) {
            return null
        }

        return (
            <FormEdit
                formType={"outline"}
                formTitle={"Field Construction Form"}
                data={this.validateVehicleField()}
                onChangeText={this.onHandleInputFormEditText}
                onChangeRadio={this.onHandleInputFormEditRadio}
                onChangeCheck={this.onHandleInputFormEditCheck}
                onChangeList={this.onHandleInputFormEditList}
                onSubmit={this.submitEntry}
                onCancel={this.onCancel}
            />
        )
    }


    render() {

        const makeUpcase=(inWord)=>{
            const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
            return upper;
        }

        return(
            <Fragment >
                <GridContainer style={{marginTop:50}}>
                    <GridItem xs={12} sm={12} md={12}  >
                        <h4 style={{marginLeft:135,color:"#2196f3",fontSize:14}}>{makeUpcase(this.props.module)+" - Entity Structure Setup"}</h4>
                    </GridItem>
                    {this.state.module !== ""?
                        <Fragment>
                            <GridItem xs={12} sm={12} md={12} alignItems="center">
                                {this.renderListUtil()}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} alignItems="center" >
                                {this.renderEditForm()}
                            </GridItem>
                        </Fragment>:
                        <p style={{color: "red"}}>Please select your target module(people)</p>
                    }

                </GridContainer>


                <LoadingProcessing open={this.state.isLoading}/>
            </Fragment>
        )

    }

}


const options = ["yes", "no"];
const optionsDataType = [{key:"string",val:"Text"}, {key:"float",val:"Number"}, {key:"boolean",val:"Yes or No"},{key:"list",val:"Combox Box List"}];
const optionsBoolean = [{key:"yes",val:"Yes"}, {key:"no",val:"No"}];
const optionsDisplay=["names","contact","identity","other"];
const optionsDisplayPosition=[1,2,3,4,5,6,7];
const listField = [
    {
        field: "FieldName",
        label: "Field Name",
        options: options,
        type: "text",
        required: true,
        dataType: "string"
    },

    {
        field: "Position",
        label: "Position on Form",
        options: options,
        type: "float",
        required: true,
        dataType: "float"
    },
    {
        field: "Section",
        label: "Comment (Optional)",
        options: optionsDisplay,
        type: "text",
        required: false,
        dataType: "string"
    },
    {
        field: "DataType",
        label: "DataType",
        options: optionsDataType,
        type: "radio",
        required: true,
        dataType: "string"
    },{
        field: "Options",
        label: "Populate Combo Box List",
        options: [],
        type: "list",
        required: true,
        dataType: "list"
    },
    {
        field: "Username",
        label: "Username",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Password",
        label: "Password",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Unique",
        label: "Principal Identifier",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Mandatory",
        label: "Mandatory",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Display",
        label: "Display in Table",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "DisplayPosition",
        label: "Display Position in Table",
        options: optionsDisplayPosition,
        type: "combobox",
        required: true,
        dataType: "float"
    },
 /*   {
        field: "Required",
        label: "Required",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean" accounts@iitpsa.org.za
    },*/
    {
        field: "Phone",
        label: "Phone",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    },
    {
        field: "Email",
        label: "Email",
        options: optionsBoolean,
        type: "boolean",
        required: true,
        dataType: "boolean"
    }

];
