import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Button} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import {Card} from 'antd';
import 'antd/dist/antd.css';

const {Meta} = Card;

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: "80%",
        maxWidth: "60%",
        height: "80%",
        maxHeight: "80%",
        marginRight: "20%",
        marginLeft: "10%",
        marginTop: "0%",
        marginBottom: "20%",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    gridList: {
        width: "80%",
        height: "80%",
        maxWidth: "80%",
        maxHeight: "60%",
        overflow: "scroll"
    },
}));

export default ({_this, entry, status}) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const onClose = () => {
        _this.setState({
            profile: null,
            displayPopup: false
        })
    }
    if (entry === null) {
        return null
    }
    return (
        <Modal
            title={"Driver: " + entry.name}
            visible={status}
            onOk={onClose}
            confirmLoading={true}
            onCancel={onClose}
            open={status}
            style={{
                position:"absolute",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}
        >
            <Card
                hoverable
                style={{width: 240}}
                cover={<img alt={entry.ref} src={entry.link}/>}
            >
                <Meta title={ <span style={{color:"rgb(33, 150, 243)"}}>{entry.name}</span>}/>
                <br/>
                <p>{entry.phone}</p>
                <p>Role: {entry.role}</p>
                <p>Vehicle:{entry.vehicle}</p>
                <Button type="secondary" onClick={()=>onClose()}>
                    Close this Profile
                </Button>
            </Card>

        </Modal>
    )
    return (
        <Paper style={{
            width: "80%",
            height: "80%"
        }}>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={status}
                onClose={onClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <Grid container sm={12} style={{marginTop: 35, minHeight: "100%"}} component={"div"}>
                        <Grid item sm={6}>
                            <img src={entry.link} alt={entry.ref} style={{maxWidth: "90%"}}/>

                        </Grid>
                        <Grid item sm={6}>
                            <h2 id="simple-modal-title">{entry.name}</h2>
                            <div id="simple-modal-description">
                                <p>{entry.name}</p>
                                <p>{entry.phone}</p>
                                <p>Role: {entry.role}</p>
                                <p>Vehicle:{entry.vehicle}</p>
                            </div>
                        </Grid>
                        <Button
                            onClick={() => onClose()}
                            style={{float: "right", color: "red", fontWeight: "bold", fontSize: 18}}
                        >Close</Button>
                    </Grid>
                </div>

            </Modal>
        </Paper>
    );
}
