import GridContainer from "../../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../../Common/dashboard/Grid/GridItem";
import React from "react";
import SelectBox from "./ControllerSelectBox";
import ControllerTable from "./ControllerTable";


export default (props)=>{
    let {state,handleInput,handleInput2,getTaskValue,setTaskValue,addTask,removeTask, getHandleValue}=props;
    const renderBox=(name,label,ls)=>{
        return(
            <SelectBox
                name={name}
                ls={ls}
                handleInput={handleInput}
                getHandleValue={getHandleValue}
                label={label}
            />
        )
    }

    const getSummaryData=()=>{
        return[
            {key:"Type",val:state.newEventType},
            {key:"Category",val:state.newEventCategory},
            {key:"Name",val:state.newEventName},
            {key:"Due",val:state.newEventNextDue},
            {key:"",val:""},
            {key:"Repeat Apply",val:state.newEventRepeatApply?"Yes":"No"},
            {key:"Repeat Unit",val:state.newEventRepeatUnit},
            {key:"Repeat Value",val:state.newEventRepeatValue},
            {key:"",val:""},
            {key:"Reminder Apply",val: state.newEventReminderApply?"Yes":"No"},
            {key:"Reminder Unit",val:state.newEventReminderUnit},
            {key:"Reminder Value Before",val:state.newEventReminderValue},
            {key:"",val:""},
            {key:"Notification Apply",val:state.newEventNotificationApply?"Yes":"No"},
            {key:"Notify Person",val:state.newEventNotificationPerson},
        ]
    }

    const renderTaskBody = () => {
        let ls = [];
        for (let i in state.tasks) {
            const row = state.tasks[i];
            ls.push(row);
        }

        return ls.map((row, index) => {
            return (
                <tr>
                    <td>{row.name}</td>
                    <td>{row.category}</td>
                    <td>{row.cost}</td>
                    <td>
                        <a
                            onClick={() => removeTask(row.name)}
                            style={{cursor: "pointer", color: "blue"}}
                        >Remove</a>
                    </td>
                </tr>
            )
        })
    }

    return(
        <GridContainer style={{marginTop: 50,paddingRight:20,paddingBottom:30}}>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                    Step: 7 Maintenance Event Summary
                </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <h5>Event Setting</h5>
                <ControllerTable ls={getSummaryData()} tableTitle={"Event Setting"} />

            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <h5>Budget Tasks</h5>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Cost</th>
                        <th>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderTaskBody()}
                    </tbody>
                </table>
            </GridItem>


        </GridContainer>
    )
}