import React, {Component} from "react";
import {GetUserToken} from "../../../api/fetching-data";
import {FetchDataFleetminder, sortData,getYTD} from "../../../api/fn";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import Connector from "../../../redux/common/Connector";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import ControllerButton from "../../../components/Common/ControllerInput/ControllerButton";
import TableDataView from "../../../components/Common/tables/TableDataView";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import moment from "moment";
import MyChartExective0 from "../../../components/Pages/fuel/ChartExective0";
import ControllerCalendar from "../../../components/Common/ControllerInput/ControllerCalendarDateRange";
import {Paper} from "@material-ui/core";


class FuelCategoryOVerview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vehicle: "CA 324 787",//"",
            startDate:getYTD().startDate,
            endDate: getYTD().endDate,
            component: "all", // or month or day
            DataAll: [],
            selectedVehicle: "",
            selectedMonth: "",
            selectedDay: null,
            selectedGroupFilter: "all",
        }

    }

    componentDidMount = async () => {
        //todo
        await this.onSubmit();
        //this.lineChart();
    };

    handleGroupFilter = (val) => {
        this.setState({
            selectedGroupFilter: val
        })
    };
    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    };
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    };
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    };
    getHandleValue = (key) => {
        return this.state[key] || ""
    };

    onSubmit = async () => {
        console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            DataAll: [],
            isLoading: true
        });
        if (this.state.vehicle === "") {
            //return alert("Please select vehicle!")
        }
        let hub = {
            Org: GetUserToken().OrgCode,//"Test Demo" ,// GetUserToken().OrgCode,
            PeriodStart: this.state.startDate,
            PeriodEnd: this.state.endDate,
            //vehicle: this.state.vehicle, // "CA 324 787" //this.state.vehicle
        };
        let endpoint = "/fuel/report/usage/daily-monthly";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                });
                return
            }
            console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                });
                return
            }
            _this.setState({
                DataAll: data,
                isLoading: false
            })

        });
    };

    renderList = () => {
        const state = this.state;
        if (this.state.DataAll === null) {
            return null
        }
        let months = [];//this.state.DataAll.Dates;
        let categories = [];
        let columns = [];
        let store = [];
        let data = this.state.DataAll.MonthlyCategory;

        //categories.push("total");
        for (let service in data) {
            const rows = data[service];
            for (let date in rows) {
                const dtt = moment(date + "-01").format("MMM-YY")
                //categories2.push(dtt);
                months.push(date)
            }
            break
        }

        //** let define your categories
        //categories.push("month");
        const makeHeadingBold = (labelIn) => {
            return (<span style={{fontWeight: "bold"}}>{labelIn}</span>)
        }
        columns.push({id: "month", label: makeHeadingBold("Month")});
        for (let service in data) {
            categories.push(service);
            columns.push({id: service, label: makeHeadingBold(service)})
        }
        //categories.push("total");
        columns.push({id: "total", label: makeHeadingBold("total")});
        console.log("Categories:> ", categories, months);

        //** let find data
        for (let indexDate in months) {
            const date = months[indexDate];
            let total = 0;
            let record = {};
            const shortMonth = moment(date + "-01").format("MMM-YY")
            record["month"] = shortMonth;
            for (let indexService in categories) {
                const service = categories[indexService];
                let cost = 0;
                if (typeof data[service] !== "undefined") {
                    if (typeof data[service][date] !== "undefined") {
                        const row = data[service][date];
                        cost = row.Expense;
                        total += cost;
                    }
                }
                record[service] = cost.toFixed(2)
            }
            record["total"] = makeHeadingBold(total.toFixed(2));
            store.push(record);
        }

        //let make total by category
        let totalLine={};
        totalLine["month"] = makeHeadingBold("Total");
        for(let i in categories){
            const service = categories[i];
            for(let serv in data){
                if(!serv===service){
                    continue
                }
                const rows=data[service];
                let total2=parseFloat("0");
                for(let date in rows){
                    const row = data[service][date];
                    let cost = row.Expense;
                    console.log("(:)---> ",total2," > ",row.Expense," > ",cost);
                    total2 = total2 + cost;
                }
                totalLine[service] = makeHeadingBold(total2.toFixed(2));
            }
        }

        store.push(totalLine)

        let results = {columns: columns, data: store};
        let otherFunctions = [];

        const title = " Period From: " + state.startDate + " To " + state.endDate;
        return (
            <GridItem xs={12} sm={12} md={12}>


                <TableDataView
                    {...results}
                    tableTitle={(<span style={{color: "#2196f3"}}>&nbsp;</span>)}
                    otherFunctions={otherFunctions}
                    options={{pageSize: 12, search: false}}
                    noAddButton={true}
                />
            </GridItem>
        )

    }

    renderServiceChart = () => {
        const state = this.state;
        if (state.DataAll === null || state.DataAll.length === 0) {
            return null
        }
        let months = [];//this.state.DataAll.Dates;
        let categories = [];
        let categories2 = [];
        let data = this.state.DataAll.MonthlyCategory;


        //** let define your categories
        for (let service in data) {
            categories.push(service);
        }

        //categories.push("total");
        for (let service in data) {
            const rows = data[service];
            for (let date in rows) {
                const dtt = moment(date + "-01").format("MMM-YY")
                categories2.push(dtt);
                months.push(date)
            }
            break
        }

        let chartData1 = [];
        for (let indexDate in months) {
            const date = months[indexDate];
            let total = 0;
            for (let service in data) {
                if (service !== "Service") {
                    continue
                }
                if (typeof data[service] !== "undefined") {
                    if (typeof data[service][date] !== "undefined") {
                        const row = data[service][date];
                        total += row.Expense.toFixed(2);

                    }
                }
            }
            let fCost = parseFloat(total);

            const dtt = moment(date + "-01").format("MMM-YY")
            chartData1.push([dtt, fCost])
        }




        console.log("####--> ", categories2, data)
        //sortData("",categories2);
        MyChartExective0({
            divId: "myChart2",
            categories: categories2,
            data1: chartData1,
            data2: chartData1,
            axeX1: chartData1,
            axeX2: chartData1,
            labelX1: "",
            labelX2: "",
            maxSoft: 10
        });

    }
    makeViewHeading = (title) => {
        return (
            <p style={styleLabel}>
                <span style={{
                    float: "left"
                }}>
                    {title}
                </span>
                <span style={{
                    float: "right",
                    color: "#2196f3",
                    fontSize:12
                }}>
                    Period From: {this.state.startDate} To {this.state.endDate}
                </span>
            </p>
        )
    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Category overview"} displayLeftPanel={true}>
                <GridContainer style={{
                    backgroundColor: "white",
                    minWidth: "100%",
                    minHeight: 35,
                    maxHeight: 80,
                    display: "flex",
                    flexDirection: "row",
                    padding: 10,
                    paddingBottom: 40
                }}>

                    <GridItem xs={12} sm={12} md={6} style={{
                        paddingTop: -10
                    }}>
                        <ControllerCalendar
                            label={"Start Date"}
                            label2={"End Date"}
                            handleInput2={this.handleInput2}
                            name={"startDate"}
                            getHandleValue={this.getHandleValue}
                            name2={"endDate"}
                            onSubmit={this.onSubmit}
                            btnLabel={"Search"}
                            variant={"contained"}
                            color={"primary"}
                        />
                    </GridItem>

                </GridContainer>
                <div style={styles.containerGray}>
                    <GridContainer style={styles.GridContainer}>
                        <div style={styles.frame60}>
                            <div style={{
                                marginTop: 20,
                                marginBottom: 60,
                                minWidth:"100%"
                            }}>
                                {this.makeViewHeading("Fleet Fuel Expense")}
                            </div>
                            <div style={{
                                marginTop: 40,
                                marginBottom: 20,
                                minWidth:"100%"
                            }}>
                                {this.renderList()}
                            </div>
                        </div>
                        <div style={styles.frame40}>
                            <div style={{
                                backgroundColor: "white",
                                marginBottom: 20,
                                minWidth: "100%",
                                maxWidth: "100%",
                                minHeight: 400,
                                maxHeight: 400,
                                padding:10,
                                marginTop:20,
                            }}>

                                {this.makeViewHeading("Service Vehicle Fuel Expense")}

                                {this.renderServiceChart()}

                                <Paper id={"myChart2"} style={{
                                    minWidth: "100%",
                                    maxWidth: "100%",
                                    minHeight: 350,
                                    maxHeight: 350,
                                }}>
                                </Paper>

                            </div>
                        </div>





                    </GridContainer>
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )

    }

}

export default Connector(FuelCategoryOVerview);

const styleLabel = {
    background: "white",//"#fc9e8a",
    color: "red",
    fontWeight: "bold",
    fontSize: 18,
    paddingLeft: 10,
};

const styles = {

    GridContainerItem1: {
        backgroundColor: "white",
        margin: 20,

    },
    GridContainer: {
        backgroundColor: "lightgray",
        marginTop: 10,
        paddingRight: 20,
        paddingBottom: 30,
        minWidth: "100%"
    },
    containerGray: {
        backgroundColor: "lightgray",
        minWidth: "100%",
        minHeight: "100%"
    },
    frame60:{
        minWidth: "56%",
        backgroundColor:"white",
        margin: 20
    },
    frame40:{
        minWidth: "40%",
        marginRight:20,

    }
}
