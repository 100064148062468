import React, {Fragment} from 'react';
import {Header, MenuModule,MenuInfoBar} from "../../components/Common/Layouts";
import Grid from '@material-ui/core/Grid';
import {Paper} from "@material-ui/core";
import LeftSide from "../../components/Common/Layouts/LeftSide";
import Connector from "../../redux/common/Connector";
import Img404 from "../../assets/img/page404animation.gif";
import Wallpaper from "../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../components/Common/page/Common/LoadingProcessing";

const styles={
    Paper:{
        padding:20,
        marginTop: 10,
        marginBottom:10,
        marginLeft:10,
        minHeight:"90%",
    }
}
class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount=async() =>{

    }


    render() {

        return (
            <Wallpaper {...this.props} pageTitle={"Page not found (404)"}  displayLeftPanel={true}>
                <Paper style={styles.Paper}>
                    <img src={Img404}  alt={""} style={{minWidth:"50%",minHeight:"30%"}}/>
                </Paper>
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )


    }
}

export default Connector(HomePage);

