import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import {UpcaseFirst} from "../../../api/fn";
import {GetUserToken} from "../../../api/fetching-data";
import {GetEntityData} from "../../../components/Pages/fuel/fn";
import NewFillUp from "../../../components/Pages/fuel/newFillUp";
import TableDataView from "../../../components/Common/tables/TableDataView";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import BoxSelect from "../../../components/Pages/fuel/ComboxFilterController";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";

class FuelFillUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            component: "list",
            selectedVehicle: "",
            selectedMonth:"",
            newVehicle: "",
            newEntity: "",
            newDriver: "",
            newQuantity: "",
            newCostUnit: "",
            newTotalCost:"",
            newMileage: "",
            newRefuelDate: "",
            newTaxInvoice: "",
            newReference: "",
            newOrigin: "",
            newCompleteFillUp: false,

        }
    }


    componentDidMount() {
        //todo

    }

    fetchData = async () => {
        this.props.LoadData();
        this.props.LoadDataMaintenance()

    }
    handleInput = () => {

    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        });
        console.log("handleInput2 > ", key, val)
    }
    getHandleValue = (key) => {
        console.log("getHandleValue > ", key, " > ", this.state[key]);
        return this.state[key];
    }
    onSubmit = async () => {
        let _this = this;
        let state = this.state;

        /*




            newOrigin      :"",
            newCompleteFillUp       :false,
         */

        let payload = {
            Org: GetUserToken().OrgCode,
            Vehicle: state.newVehicle,
            Driver: state.newDriver,
            Quantity: parseFloat(state.newQuantity),
            CostUnit: parseFloat(state.newCostUnit),
            Mileage: parseFloat(state.newMileage),
            RefuelDate: state.newRefuelDate,
            TaxInvoice: state.newTaxInvoice,
            Reference: state.newReference,
            Origin: state.newOrigin,
            CompleteFillUp: state.newCompleteFillUp,
            Cost:parseFloat(state.newTotalCost)
        }

        let hub = {
            payload: payload,
            module: "filling"
        }

        this.props.SaveFuelMiddleware(hub);

        this.setState({
            component: "list",
            isLoading: true
        })

        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 5000)

    }
    onAddRecord = async () => {
        this.setState({
            component: "new"
        })
    }
    onCancelAdding = () => {
        this.setState({
            component: "list"
        })
    }
    onDelete = (row) => {
        console.log("onDelete $$$--> ", row)

        if (!window.confirm("Are you sure to delete this vehicle spec : " + row.vehicle + "?")) {
            return
        }

        let payload = {
            Org: row.org,
            Vehicle: row.vehicle,
        }

        let hub = {
            payload: payload,
            module: "specs"
        }

        this.props.DeleteFuelMiddleware(hub);

        this.setState({
            component: "list",
            isLoading: true
        })

        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 5000)
    }
    handleInputCheckbox = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = !tmp[key];
        this.setState(tmp)
    }
    getEntityData = (module) => {
        return GetEntityData(module, this.props);
    }
    getColunms = () => {
        /*
<td>{row.vehicle}</td>
                            <td>{row.quantity}</td>
                            <td>{row.costunit + " / " + row.unit}</td>
                            <td>{row.refueldate}</td>
                            <td>{row.mileage}</td>
                            <td>{row.completefillup ? "Yes" : "No"}</td>
         */
        return (
            [
                {id: "vehicle", label: "Vehicle"},
                {id: "quantity", label: "Quantity"},
                {id: "refueldate", label: "Date"},
                {id: "mileage", label: "Mileage"},
                {id: "cost", label: "Cost"},
            ]
        )
    }

    renderComboFilterMonth = () => {
        let ls = [];
        let months = {};
        for (let i in this.props.fuel.Fillings) {
            let row = this.props.fuel.Fillings[i];
            if (this.state.selectedVehicle === "") {
                continue
            }
            if (this.state.selectedVehicle === row.ref) {
                continue
            }

            const arr = row.refueldate.split("-");
            const date = arr[0] + "-" + arr[1];
            months[date] = true

        }
        for (let month in months) {
            ls.push({
                key: month,
                val: month
            })
        }
        return (
            <BoxSelect
                options={ls}
                name={"selectedMonth"}
                handleInput2={this.handleInput2}
                label={"Filter by Month"}
            />
        )
    }

    renderComboFilterVehicle = () => {
        let ls = [];
        let vehicles = GetEntityData("vehicle", this.props, true);
        for (let i in vehicles) {
            const row = vehicles[i];
            ls.push({
                key: row.key,
                val: row.val
            })
        }
        return (
            <BoxSelect
                options={ls}
                name={"selectedVehicle"}
                handleInput2={this.handleInput2}
                label={"Filter by vehicle"}
            />
        )
    }

    renderList = () => {
        let ls = [];
        let totalQty=0,totalCost=0;
        for (let i in this.props.fuel.Fillings) {
            let row = this.props.fuel.Fillings[i];
            //row.completefillup = row.completefillup ? "Yes" : "No";
            if(this.state.selectedVehicle!==""){
                if(this.state.selectedVehicle!==row.vehicle){
                    continue
                }
            }
            if(this.state.selectedMonth!==""){
                const arr = row.refueldate.split("-");
                const date = arr[0] + "-" + arr[1];
                if(this.state.selectedMonth!==date){
                    continue
                }
            }
            totalQty += parseFloat(row.quantity);
            totalCost +=parseFloat(row.cost);
            ls.push(row)
        }

        const makeColor=(val)=>{
            return(<span style={{color:"blue",fontWeight:"bold",fontSize:16}}>{val}</span>)
        }
        let results=[
            {
                vehicle:makeColor("Total"),
                quantity:makeColor(totalQty.toFixed(2)),
                mileage:makeColor("---"),
                refueldate:makeColor("---"),
                cost:makeColor(totalCost.toFixed(2))
            }
        ];
        for(let i in ls){
            const row = ls[i];
            results.push(row);
        }


        let data = {columns: this.getColunms(), data: results};
        console.log("YYYYYYYY--->>>>> ", this.props.fuel);
        return (
            <React.Fragment>
                <GridContainer style={{
                    backgroundColor: "white",
                    minWidth: "100%",
                    minHeight: 35,
                    maxHeight: 80,
                    display: "flex",
                    flexDirection: "row",
                    padding: 10,
                    paddingBottom: 40
                }}>
                    <GridItem xs={12} sm={3} md={3} style={{margin: 10}}>
                        {this.renderComboFilterVehicle()}
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} style={{margin: 10}}>
                        {this.renderComboFilterMonth()}
                    </GridItem>
                </GridContainer>


                <GridItem xs={12} sm={12} md={12}>

                    <TableDataView
                        {...data}
                        tableTitle={UpcaseFirst("Vehicle FillUp ")}
                        onAddRecord={this.onAddRecord}
                        onDelete={this.onDelete}
                    />
                </GridItem>
            </React.Fragment>

        )
    }
    renderNew = () => {
        return (
            <NewFillUp
                {...this.props}
                state={this.state}
                onCancel={this.onCancelAdding}
                onSubmit={this.onSubmit}
                handleInput2={this.handleInput2}
                getHandleValue={this.getHandleValue}
                getValue={this.getHandleValue}
                handleInput={this.handleInput}
                handleInputCheckbox={this.handleInputCheckbox}
                ls={GetEntityData("vehicle", this.props)}
                getEntityData={this.getEntityData}
            />
        )
    }
    switchComponent = () => {
        switch (this.state.component) {
            case "list":
                return this.renderList();
            case "new":
                return this.renderNew();
            default:
                return this.renderList()
        }
    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Fuel Filling"} displayLeftPanel={true}>

                {this.switchComponent()}
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(FuelFillUp);
