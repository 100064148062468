import GridContainer from "../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../Common/dashboard/Grid/GridItem";
import React from "react";
import InputBox from "../../Common/ControllerInput/ControllerInputBox";
import InputBoxDate from "../../Common/ControllerInput/ControllerCalendar";
import InputButton from "../../Common/ControllerInput/ControllerButton";
import {MyBoxSelecttion} from "../SetupAssociation/fn";
import ControllerRadioButton from "../../Common/ControllerInput/ControllerRadioButton";

export default (props)=>{
    let {state,handleInput2,ls,onSubmit,onCancel,getEntityData}=props;

    let entities=[
        {key:"driver",val:"Driver"},
        {key:"personnel",val:"Personnel"},
        {key:"supplier",val:"Supplier"},
    ];
    let origins=[
        {key:"internal",val:"Internal"},
        {key:"external",val:"External"},
    ];
    /*
    newVehicle         :"",
            newEntity:"",
            newDriver    :"",
            newQuantity          :"",
            newCostUnit      :"",
            newMileage       :"",
            newRefuelDate       :"",
            newTaxInvoice       :"",
            newReference      :"",
            newOrigin      :"",
            newCompleteFillUp       :false,
     */

    let fields = [
        {id: "newQuantity", label: "Quantity/Letter",dataType:"text"},
        {id: "newCostUnit", label: "Cost Unit/Rand",dataType:"text"},
        {id: "newMileage", label: "Mileage",dataType:"text"},
        {id: "newRefuelDate", label: "Refuel Date",dataType:"date"},
        {id:"newTotalCost",label: "Total Cost"}
    ];



    return(
        <GridContainer style={{marginTop: 50,paddingRight:20,paddingBottom:30}}>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                    New Fuel Filling
                </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{marginBottom:10}}>
                <MyBoxSelecttion
                    {...props}
                    label={"Vehicle"}
                    name={"newVehicle"}
                    ls={ls}
                    noImage={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{marginBottom:10}}>
                <MyBoxSelecttion
                    {...props}
                    label={"Worker Entity"}
                    name={"newEntity"}
                    ls={entities}
                    noImage={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{marginBottom:10}}>
                <MyBoxSelecttion
                    {...props}
                    label={"Worker"}
                    name={"newDriver"}
                    ls={getEntityData(state.newEntity)}
                    noImage={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{marginBottom:10}}>
                <MyBoxSelecttion
                    {...props}
                    label={"Petrol Source"}
                    name={"newOrigin"}
                    ls={origins}
                    noImage={false}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {fields.map((row,index)=>{
                    if(row.dataType==="date"){
                        return(
                            <InputBoxDate
                                name={row.id}
                                handleInput2={handleInput2}
                                required={true}
                                label={row.label}
                                styleIn={{minWidth:"100%"}}
                                {...props}
                                />
                        )
                    }
                    return(
                        <InputBox
                            name={row.id}
                            handleInput2={handleInput2}
                            required={true}
                            label={row.label}
                            styleIn={{minWidth:"100%"}}
                            {...props}
                        />
                    )
                })}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <ControllerRadioButton
                    name={"newCompleteFillUp"}
                    label={"Complete Fillup?"}
                    options={[{key:true,val:"Yes"},{key:false,val:"No"}]}
                    {...props}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>

                <InputButton
                    label={"Submit"}
                    variant={"contained"}
                    onClick={onSubmit}
                    styles={{float:"right"}}
                    color={"primary"}
                />
                <InputButton
                    label={"Cancel"}
                    variant={"outlined"}
                    onClick={onCancel}
                    styles={{float:"right"}}
                    color={"secondary"}
                />
            </GridItem>


            {/*<GridItem xs={12} sm={12} md={12}>
                <ControllerRadioButton
                    name={"newEventRepeatApply"}
                    label={"Repeat Every"}
                    options={[{key:true,val:"Yes"},{key:false,val:"No"}]}
                    {...props}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {renderBox("newEventRepeatUnit","Repeat Unit",[
                    {key:"day",val:"Day"},
                    {key:"month",val:"Month"},
                    {key:"year",val:"Year"},
                ])}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <InputBox
                    name={"newEventRepeatValue"}
                    handleInput2={handleInput2}
                    required={true}
                    label={"Repeat Value"}
                    styleIn={{minWidth:"100%"}}
                    type={"number"}
                    {...props}
                />
            </GridItem>*/}

        </GridContainer>
    )
}