import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import Menus from "./Menus";
import {SAVE_MODULE_MENU,SAVE_APP_NAME} from "../../../redux/constants/index"

const styles = {
    Active: {
        color: "white",
        fontWeight: "bold",
        fontSize: 16
    }
}
export default (props) => {
    /*let selectedModule=props.navigations.currentModule;*/

    let selectedApp = props.navigations.currentApp;
    let selectedModule =props.navigations.currentModule;// localStorage.getItem("@vehicleminder-module") || "";
    let selectedMenu =props.navigations.currentSubmenu; // localStorage.getItem("@vehicleminder-menu") || "";
    let ls = [];

    ////console.log("TTTXvvvvv  bbbb-> ",selectedApp,selectedModule," :> ",props.navigations.menus[selectedApp][selectedModule]);

    for (let i in props.navigations.menus[selectedApp][selectedModule]) {
        const row = props.navigations.menus[selectedApp][selectedModule][i];

        /*if (row.isDefault && selectedMenu === "") {
            selectedMenu = row.name;
            localStorage.setItem("@vehicleminder-app", selectedApp);
            localStorage.setItem("@vehicleminder-module", selectedModule);
            localStorage.setItem("@vehicleminder-menu", selectedMenu);
            localStorage.setItem("@vehicleminder-submenu", "");
            props.dispatch({
                type: SAVE_APP_NAME,
                payload: {
                    module: selectedModule,
                    menu: selectedMenu,
                    submenu: ""
                }
            });
            window.location.href = "#/" + row.link;
        }*/
        ls.push({key: row.name, link: row.link});
    }

    const onChooseMenu = (menu) => {
        props.dispatch({
            type: SAVE_MODULE_MENU,
            payload: {
                module: selectedModule,
                menu: menu.key,
                submenu: ""
            }
        });

        localStorage.setItem("@vehicleminder-module", selectedModule);
        localStorage.setItem("@vehicleminder-menu", menu.key);
        localStorage.setItem("@vehicleminder-submenu", "");
        window.location.href = "#/" + menu.link;
    }

    const handleClick = () => {

    }

    return (
        <Menus {...props}
               open={true}
               handleClick={handleClick}
               ls={ls}
               onChooseMenu={onChooseMenu}

        />
    )

}
