import React, {Component,Fragment} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import SetupIncomplete from "../../../components/Pages/Vierifycation/SetupIncomplete";
import SetupMenu from "../../../components/Pages/Vierifycation/SetupMenu";
import SetupAttribute from "../../../components/Pages/SetupAttribute";
import SetupDatabase from "../../../components/Pages/SetupDatabase";
import TreeBuilder from "../../../components/Common/TreeView/TreeBuilder";

import TreeView from "../../../components/Common/TreeView/index";
import {GetUserToken} from "../../../api/fetching-data";

class Sample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            component: "incomplete",
            tree:null,
        }

    }

    componentDidMount() {
        //todo
        TreeBuilder({_this:this});
    }

    goTo=(comp) => {
        this.setState({
            component:comp
        })
    }

    renderSetupAttribute = () => {
        return (
            <SetupAttribute
                {...this.props}
                goTo={this.goTo}
            />
        )
    }
    renderSetupDatabase=()=>{
        return(
            <SetupDatabase
                {...this.props}
                goTo={this.goTo}
            />
        )
    }
    renderIncompleteSetup = () => {
        return (
            <Fragment>
                <SetupIncomplete
                    {...this.props}
                    goTo={this.goTo}
                />

               {/* <TreeView
                    {...this.props}
                    module={"vehicle"}
                    org={GetUserToken().OrgCode}
                />*/}
            </Fragment>

        )
    }
    renderSetupMenu=()=>{
        return(
            <SetupMenu
                {...this.props}
                goTo={this.goTo}
            />
        )
    }

    switchComponent = () => {
        switch (this.state.component) {
            case "incomplete":
                return this.renderIncompleteSetup();
            case "menu":
                return this.renderSetupMenu();
            case "attribute":
                return this.renderSetupAttribute();
            case "database":
                return this.renderSetupDatabase();
            default:
                return this.renderIncompleteSetup()
        }
    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Setup"} displayLeftPanel={false}>

                {this.switchComponent()}
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(Sample);
const styles = {
    box1: {
        marginTop: 35,

    }
}
