import React from "react";
import moment from "moment";
const formatMyDate=(dateIn)=>{
    const arr = dateIn.split("-");
    const year = parseInt(arr[0])
    const month = parseInt(arr[1]);
    const date = new Date(year, month, 1);
    const nowDate = moment(date).format("MMM-YY");
    return nowDate;
}
export default ({divId,data, categories,axeX1,axeX2,labelX1,labelX2,maxSoft,titleChart,haveMultipleAxes}) => {

    let Highcharts = window.Highcharts;
    const ddd = window.document.getElementById(divId);
    console.log("Chart Div !!!!----> ",ddd);

    let options={
        chart: {
            scrollablePlotArea: {
                /*minWidth: 700*/
            }
        },
        title: {
            text: titleChart,
            align: 'left',

            style:{
                color:"red",
            }
        },
        subtitle: {
            text: null
        },
        xAxis: {
            /*tickInterval: 7 * 24 * 3600 * 1000, // one week */
            tickWidth: 0,
            gridLineWidth: 1,
            type: 'category',
            labels: {
                align: 'center',
                x: 3,
                y: -3,
            },
            categories: categories,
            showFirstLabel: true,
        },

        yAxis: [
            { // left y axis
                title: {
                    text: labelX1
                },
                labels: {
                    align: 'left',
                    x: 3,
                    y: 16,
                    format: '{value:.,0f}'
                },
                showFirstLabel: false,
                lineColor: "red",
            },{

                title: {
                    text: labelX2
                },
                labels: {
                    align: 'right',
                    x: 3,
                    y: 16,
                    format: '{value:.,0f}',
                    enabled:haveMultipleAxes
                },
                showFirstLabel: false,
                softMax:maxSoft,
                softMin:0,
                opposite: true,
            }
            ],

        legend: {
            align: 'left',
            verticalAlign: 'top',
            borderWidth: 0
        },

        tooltip: {
            shared: true,
            crosshairs: true
        },
        series: [
            {
                name: labelX1,
                data:axeX1,
                lineWidth: 4,
                yAxis: 0,
                marker: {
                    radius: 4
                },
                label:"left"
            },
            {
                name: labelX2,
                data:axeX2,
                yAxis: 1,
                lineWidth: 4,
                label:"right"
            }
        ]
    }

    if(haveMultipleAxes){
        /*options.yAxis.push({

            title: {
                text: labelX2
            },
            labels: {
                align: 'right',
                x: 3,
                y: 16,
                format: '{value:.,0f}',
                enabled:false
            },
            showFirstLabel: false,
            softMax:maxSoft,
            softMin:0,
            opposite: true,
        });
*/
        /*options.series.push(
            {
                name: labelX2,
                data:axeX2,
                yAxis: 1,
                lineWidth: 4,
                label:"right"
            }
        )*/
    }

    Highcharts.chart(divId,options);
}