import React from "react";
import {FetchDataFleetminder} from "../../../api/fn";
import {GetUserToken} from "../../../api/fetching-data";
export const fetchDataEntity = async (stateEntity, moduleEntity, table, _this,noCondition) => {
    _this.setState({
        isLoading: true,
        [stateEntity]: [],
    })
    let user = GetUserToken();
    let hub = {
        Org: user.OrgCode,
    }
    if(typeof noCondition !=="undefined"){
        hub={}
    }
    let endpoint = "/" + moduleEntity + "/list/" + table;
    await FetchDataFleetminder( hub, endpoint, async (data) => {
        console.log("):(--->fetchData " + moduleEntity + " response: ", data);
        _this.setState({
            isLoading: false,
        })
        if (data === null) {
            alert("Server connection Error! try again later");
            return
        }
        let my = data;
        _this.setState({
            isLoading: false,
            [stateEntity]: my,
        })
    });
}

export const UpCaseFirst = (inWord) => {
    const upper = inWord.charAt(0).toUpperCase() + inWord.substring(1);
    return upper;
}

