import {SAVE_ROLE, SAVE_UTIL} from "./enquiry";

const
    LOGGED_IN = "LOGGED_IN",
    SAVE_NAVIGATION_MODULE = "SAVE_NAVIGATION_MODULE",
    SAVE_NAVIGATION_MENU = "SAVE_NAVIGATION_MENU",
    SAVE_NAVIGATIONS = "SAVE_NAVIGATIONS",
    SAVE_ATTIBUTE_PEOPLE = "SAVE_ATTIBUTE_PEOPLE",
    SAVE_ATTIBUTE_SERVICE="SAVE_ATTIBUTE_SERVICE",
    SAVE_ATTIBUTE_PRODUCT="SAVE_ATTIBUTE_PRODUCT",
    LOAD_DATA="LOAD_DATA",
    TICKET_DATA_RECEIVED="TICKET_DATA_RECEIVED",
    SET_VERIFICATION_MODULE_MAINTENANCE="SET_VERIFICATION_MODULE_MAINTENANCE",
    SET_VERIFICATION_MODULE_FUEL="SET_VERIFICATION_MODULE_FUEL",
    SAVE_MODULE_MENU="SAVE_MODULE_MENU",
    SAVE_APP_NAME="SAVE_APP_NAME",
    SAVE_DATA="SAVE_DATA",
    DELETE_DATA="DELETE_DATA",
    FLEETMINDER_DELETE_DATA="FLEETMINDER_DELETE_DATA",
    FLEETMINDER_SAVE_DATA="FLEETMINDER_SAVE_DATA",
    FLEETMINDER_LOAD_DATA="FLEETMINDER_LOAD_DATA",
    FLEETMINDER_LOAD_RECEIVE="FLEETMINDER_LOAD_RECEIVE",
    FLEETMINDER_FAULT_LOAD_RECEIVE="FLEETMINDER_FAULT_LOAD_RECEIVE",

    FUEL_DATA_RECEIVED="FUEL_DATA_RECEIVED",
    FUEL_LOAD_DATA="FUEL_LOAD_DATA",
    FUEL_SAVE_DATA="FUEL_SAVE_DATA",
    FUEL_DELETE_DATA="FUEL_DELETE_DATA"
;

export {
    LOGGED_IN,
    SAVE_NAVIGATION_MODULE,
    SAVE_NAVIGATION_MENU,
    SAVE_NAVIGATIONS,
    SAVE_ROLE, SAVE_UTIL,
    SAVE_ATTIBUTE_PEOPLE,
    SAVE_ATTIBUTE_SERVICE,
    SAVE_ATTIBUTE_PRODUCT,
    LOAD_DATA,
    TICKET_DATA_RECEIVED,
    SET_VERIFICATION_MODULE_MAINTENANCE,
    SET_VERIFICATION_MODULE_FUEL,
    SAVE_MODULE_MENU,
    SAVE_APP_NAME,
    SAVE_DATA,
    DELETE_DATA,
    FLEETMINDER_LOAD_DATA,
    FLEETMINDER_LOAD_RECEIVE,
    FLEETMINDER_DELETE_DATA,
    FLEETMINDER_FAULT_LOAD_RECEIVE,
    FLEETMINDER_SAVE_DATA,
    FUEL_DATA_RECEIVED,
    FUEL_LOAD_DATA,
    FUEL_SAVE_DATA,
    FUEL_DELETE_DATA
}
