import React from "react";
import LTTree from "list-to-tree";


let myTree={
    /*child:"",
    parent:"",
    children:[]*/
};

let nodeIds={};

export default ({_this,treeItems})=>{


    const compareMaps=(map1,map2)=>{
        let boo=true;
        for(let i in map1){
            if(typeof map2[i]==="undefined"){
                boo=false
            }
        }
        return boo;
    }
    const addToNode=(tree,parent,child,id)=>{

        if(parent===""){
            let node={
                id:id,
                child:child,
                parent:parent,
                children:[]
            };
            if(typeof tree.children ==="undefined"){
                tree.children=[];
                tree.id="";
                tree.child="Organigram";
                tree.parent="";
                tree.detail={};
            }
            tree.children.push(node);
            nodeIds[id]=true;
            return true
        }

        if(tree.id===parent){
            let node={
                id:id,
                child:child,
                parent:parent,
                children:[]
            };
            tree.children.push(node);
            nodeIds[id]=true;
            return true
        }

        for(let i in tree.children){
            addToNode(tree.children[i],parent,child,id)
        }

        return false
    }

    const arrData =treeItems;

    /**
     * get the list of all our map ids
     */
    let allIds={};
    for(let i in arrData){
        let row =arrData[i];
        allIds[row.id]=true;
    }
    /**
     * let start our adding
     */
    const popLimit = 1;
    let fish=0;
    while(fish < popLimit){
        for(let i in arrData){
            const row = arrData[i];
            if(typeof nodeIds[row.id] !=="undefined"){//make sure that this id is not in the list yet
                continue;
            }
            addToNode(myTree,row.parent,row.child,row.id)//let add now to our tree
        }
        if(compareMaps(allIds,nodeIds)){//let check for end of process when all id in array is now process
            fish=1
        }
    }
    //console.log("TREE RESULT >> ",nodeIds," > ",myTree);
    //_this.setState({tree:myTree});
    return myTree
}

const arrData1=[
    {
        "id": "5",
        "child": "fleetminder",
        "parent": "3",
        "detail": {}
    },
    {
        "id": "6",
        "child": "maintenance",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "7",
        "child": "maintenance",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "8",
        "child": "fuel",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "2",
        "child": "akhanya",
        "parent": "",
        "detail": {}
    },
    {
        "id": "9",
        "child": "trip",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "10",
        "child": "product",
        "parent": "2",
        "detail": {}
    },
    {
        "id": "12",
        "child": "Learning Management",
        "parent": "11",
        "detail": {}
    },
    {
        "id": "13",
        "child": "Mobile",
        "parent": "11",
        "detail": {}
    },
    {
        "id": "14",
        "child": "Foot Agent",
        "parent": "13",
        "detail": {}
    },
    {
        "id": "11",
        "child": "system design",
        "parent": "10",
        "detail": {}
    },
    {
        "id": "15",
        "child": "Chela",
        "parent": "13",
        "detail": {}
    },
    {
        "id": "16",
        "child": "Credit management",
        "parent": "15",
        "detail": {}
    },
    {
        "id": "18",
        "child": "Wallet management",
        "parent": "15",
        "detail": {}
    },
    {
        "id": "3",
        "child": "product",
        "parent": "1",
        "detail": {}
    },
    {
        "id": "1",
        "child": "easipath",
        "parent": "",
        "detail": {}
    },
    {
        "id": "19",
        "child": "Files",
        "parent": "15",
        "detail": {}
    },
    {
        "id": "20",
        "child": "Recon",
        "parent": "19",
        "detail": {}
    },
    {
        "id": "21",
        "child": "Sim",
        "parent": "19",
        "detail": {}
    },
    {
        "id": "4",
        "child": "cclms",
        "parent": "3",
        "detail": {}
    },
    {
        "id": "22",
        "child": "Commission",
        "parent": "19",
        "detail": {}
    },
    {
        "id": "17",
        "child": "Airtime Distribution",
        "parent": "15",
        "detail": {}
    }
]
