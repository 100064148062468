import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";
import {Input, Modal} from "antd";

const { confirm } = Modal;
const { TextArea } = Input;


export default (props)=>{
    let {handleChange1,tePost,row}=props;

    const commentContent=()=>{

        return(
            <TextArea
                name={"qaComment"}
                rows={4}
                onChange={(e)=>handleChange1(e)}
            />
        )
    }
    return(
        confirm({
            title: 'Please entry your Rejection comment and click Ok ,Vehicle: ['+row.Vehicle+"] Cost: R"+row.Cost+" Quantity: "+row.Quantity,
            icon: <ExclamationCircleOutlined />,
            content: commentContent(),
            okText: 'Yes',
            okType: 'danger',
            okButtonProps: {
                disabled: false,
            },
            cancelText: 'No',
            onOk:async() =>{
                try{
                    await tePost();
                }catch (e) {
                    //console.log("Confirm error: ",e)
                }
                //

            },
            onCancel:()=> {
                console.log('Cancel');
            },
        })
    )
}
