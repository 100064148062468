import React, {Component} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import { UpcaseFirst} from "../../../api/fn";
import {GetUserToken} from "../../../api/fetching-data";
import {GetEntityData}from "../../../components/Pages/fuel/fn";
import NewSpec from "../../../components/Pages/fuel/newSpec";
import TableDataView from "../../../components/Common/tables/TableDataView";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";

class FuelSpec extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            component: "list",
            selectedVehicle: "",

            newVehicle         :"",
            newTargetUsage    :"",
            newLitter          :"",
            newDistanceKm      :"",
            newAllowance       :"",
            newTakeOnMileage :"",
            newCurrentFuelPrice :"",
            newRatioUpValue :"",
            newRatioLowerValue :"",
        }

    }


    componentDidMount() {
        //todo

    }
    fetchData = async () => {
        this.props.LoadData();
        this.props.LoadDataMaintenance()

    }
    handleInput = () => {

    }
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        });
        console.log("handleInput2 > ", key, val)
    }
    getHandleValue = (key) => {
        console.log("getHandleValue > ", key, " > ", this.state[key]);
        return this.state[key];
    }
    onSubmit=async ()=>{
        let _this = this;
        let state = this.state;

        let payload={
            Org : GetUserToken().OrgCode,
            Vehicle         :state.newVehicle,
            TargetUsage    :parseFloat(state.newTargetUsage),
            Litter          :parseFloat(state.newLitter),
            DistanceKm      :100,//parseFloat(state.newDistanceKm),
            Allowance       :parseFloat(state.newAllowance),
            TakeOnMileage: parseFloat(state.newTakeOnMileage),

            CurrentFuelPrice :parseFloat(state.newCurrentFuelPrice),
            RatioUpValue :parseFloat(state.newRatioUpValue),
            RatioLowerValue :parseFloat(state.newRatioLowerValue),
        }

        let hub={
            payload: payload,
            module:"specs"
        }

        this.props.SaveFuelMiddleware(hub);

        this.setState({
            component: "list",
            isLoading:true
        })

        setTimeout(()=>{
            this.setState({
                isLoading:false
            })
        },5000)

    }
    onAddRecord = async () => {
        this.setState({
            component: "new"
        })
    }
    onCancelAdding = () => {
        this.setState({
            component: "list"
        })
    }
    onDelete = (row) => {
        console.log("onDelete $$$--> ", row)

        if(!window.confirm("Are you sure to delete this vehicle spec : "+row.vehicle+"?")){
            return
        }

        let payload={
            Org : row.org,
            Vehicle         :row.vehicle,
        }

        let hub={
            payload: payload,
            module:"specs"
        }

        this.props.DeleteFuelMiddleware(hub);

        this.setState({
            component: "list",
            isLoading:true
        })

        setTimeout(()=>{
            this.setState({
                isLoading:false
            })
        },5000)
    }
    handleInputCheckbox = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = !tmp[key];
        this.setState(tmp)
    }
    getColunms = () => {
        /*
CurrentFuelPrice float64
	RatioUpValue float64
	RatioLowerValue float64
         */
        return (
            [
                {id: "vehicle", label: "Vehicle"},
                {id: "litter", label: "Manufacturer Spec"},
                {id: "operationspec", label: "Operation Spec"},
                {id: "targetspec", label: "Target Spec"},
                {id: "allowance", label: "Allowance"},
                {id:"takeonmileage",label:"Take On Mileage"},
                {id:"currentfuelprice",label:"Current Fuel Price"},
                {id:"ratioupvalue",label:"Ratio Up Value"},
                {id:"ratiolowervalue",label:"Ratio Lower Value"},
            ]
        )
    }

    renderList = () => {
        let ls=[];
        let specs = this.props.fuel.Specs;

        for(let i in specs){
            let  row = specs[i];
            row.operationspec = (row.litter / 100) * row.currentfuelprice;
            row.targetspec =  row.operationspec +  row.allowance;
            ls.push(row)
        }
        let data = {columns: this.getColunms(), data: ls};
        return(
            <GridItem xs={12} sm={12} md={12}>
                <TableDataView
                    {...data}
                    tableTitle={UpcaseFirst("Vehicle Spec " )}
                    onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}
                />
            </GridItem>
        )
    }
    renderNew = () => {
        return (
            <NewSpec
                {...this.props}
                state={this.state}
                onCancel={this.onCancelAdding}
                onSubmit={this.onSubmit}
                handleInput2={this.handleInput2}
                getHandleValue={this.getHandleValue}
                getValue={this.getHandleValue}
                handleInput={this.handleInput}
                handleInputCheckbox={this.handleInputCheckbox}
                ls={GetEntityData("vehicle",this.props)}
            />
        )
    }
    switchComponent = () => {
        switch (this.state.component) {
            case "list":
                return this.renderList();
            case "new":
                return this.renderNew();
            default:
                return this.renderList()
        }
    }

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Fuel Spec"} displayLeftPanel={true}>

                {this.switchComponent()}
                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }
}

export default Connector(FuelSpec);
