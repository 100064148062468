import React from "react";
import {makeStyles, TextField, withStyles} from "@material-ui/core";

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);
const innerStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth:"100%"
    },
    margin: {
        margin: theme.spacing(1),
        maxWidth:"95%"
    },
    formControl: {
        margin: theme.spacing(3),
        borderColor:"gray",
        borderRadius:3,
        borderWidth:1
    },
}));


export default (props) => {
    let {name,label,required,handleInput2, styleIn,getHandleValue}=props;
    const classes = innerStyles();
    const handleChange = event => {
        //console.log("InputBox handleInput2 > ",event.target.value,event);
        handleInput2(name,event.target.value)
    };
    return (
        <CssTextField
            className={classes.margin}
            label={label}
            variant="outlined"
            id="custom-css-outlined-input"
            name={name}
            required={required}
            onChange={handleChange}
            style={styleIn}
            value={getHandleValue(name)}
        />
    )
}
