import React from "react";
import moment from "moment";
const formatMyDate=(dateIn)=>{
    const arr = dateIn.split("-");
    const year = parseInt(arr[0])
    const month = parseInt(arr[1]);
    const date = new Date(year, month, 1);
    const nowDate = moment(date).format("MMM-YY");
    return nowDate;
}
export default ({divId,data1, categories,axeX1,axeX2,labelX1,labelX2,maxSoft}) => {

    let Highcharts = window.Highcharts;




    Highcharts.chart(divId, {

        chart: {
            scrollablePlotArea: {
                /*minWidth: 700*/
            }
        },

        title: {
            text: ''
        },

        subtitle: {
            text: ''
        },
        colorAxis: [{
            minColor: '#000fb0',
            maxColor: '#e3e5ff',
        }
        ],

        xAxis: {
            /*tickInterval: 7 * 24 * 3600 * 1000, // one week */
            /*tickWidth: 0,
            gridLineWidth: 1,*/
            type: 'category',
            /*labels: {
                align: 'center',
                /!*x: 3,
                y: -3,*!/
                formatter: function () {
                    // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/coloraxis/multiple-coloraxis/
                    return formatMyDate(this.value) //Math.abs(this.value) + '%';
                }
            },*/
            /*categories: categories,*/
            showFirstLabel: true,
        },

        yAxis: [
            { // left y axis
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    /*x: 3,
                    y: 16,*/
                    format: '{value:.,0f}'
                },
                showFirstLabel: false,

                lineColor: "red",
                softMax:maxSoft,
                softMin:0
            }],

        /*legend: {
            align: 'left',
            verticalAlign: 'top',
            borderWidth: 0
        },*/

        tooltip: {
            shared: true,
            crosshairs: true
        },


        series: [
            {
                name: labelX1,
                lineWidth: 4,
                marker: {
                    radius: 4
                },
                data: axeX1,
                /*color:"red",*/
                dataLabels: {
                    enabled: true,
                }
            },
        ]
    });
}