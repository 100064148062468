import React, {Component, Fragment} from "react";
import Wallpaper from "../../../components/Common/Layouts/wallpaper";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";
import Connector from "../../../redux/common/Connector";
import {FetchDataFleetminder,getYTD} from "../../../api/fn";
import TableDataView from "../../../components/Common/tables/TableDataView";
import GridItem from "../../../components/Common/dashboard/Grid/GridItem";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ControllerCalendar from "../../../components/Common/ControllerInput/ControllerCalendarDateRange";
import GridContainer from "../../../components/Common/dashboard/Grid/GridContainer";
import AssistantPhotoIcon from '@material-ui/icons/GolfCourse';
import "../../../components/Pages/fuel/myStyle.css";

import {GetUserToken} from "../../../api/fetching-data";
import TableSimple from "../../../components/Common/tables/TableSImple";
import ControllerButton from "../../../components/Common/ControllerInput/ControllerButton";
import MyCircle from "../../../components/Pages/fuel/circle";
import MyRadio from "../../../components/Pages/fuel/radio";

const makeFlag = (colorIn) => {
    return (<AssistantPhotoIcon style={{color: colorIn}}/>)
};
const FloatFixed = (inNumber, dec) => {
    inNumber = parseFloat(inNumber);
    inNumber = inNumber.toFixed(2);
    return inNumber
};
const convertDateFormat_YYYYMMDD = (datesIn) => {
    return moment(datesIn).format("YYYY-MM-DD");
};
class FuelFleetOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            vehicle: "CA 324 787",//"",
            startDate:getYTD().startDate,
            endDate: getYTD().endDate,
            component: "all", // or month or day
            DataAll: [],
            selectedVehicle: "",
            selectedMonth: "",
            selectedDay: null,
            selectedGroupFilter:"all",
        }

    }

    componentDidMount=async ()=> {
        //todo

    };

    handleGroupFilter=(val)=>{
        this.setState({
            selectedGroupFilter:val
        })
    };
    handleChangeStartDate = (date) => {
        this.setState({
            startDate: date
        });
    };
    handleChangeEndDate = (date) => {
        this.setState({
            endDate: date
        });
    };
    handleInput2 = (key, val) => {
        this.setState({
            [key]: val
        })
    };
    getHandleValue = (key) => {
        return this.state[key] || ""
    };
    requestViewMonthly = (rowData) => {

        this.setState({
            component: "month",
            selectedVehicle: rowData.Vehicle,
        });
        //console.log("View monthly request clicked22! ", rowData);
        //alert("View monthly request clicked! ");
    };
    closeViewMonthly = () => {
        this.setState({
            component: "all",
            selectedVehicle: "",
        });
    };
    onSubmit = async () => {
        //console.log("State > ", this.state);
        let _this = this;
        _this.setState({
            DataAll: [],
            isLoading: true
        });
        if (this.state.vehicle === "") {
            //return alert("Please select vehicle!")
        }
        let hub = {
            Org: GetUserToken().OrgCode,//"Test Demo" ,// GetUserToken().OrgCode,
            PeriodStart: this.state.startDate,
            PeriodEnd: this.state.endDate,
            //vehicle: this.state.vehicle, // "CA 324 787" //this.state.vehicle
        };
        let endpoint = "/fuel/report/usage/daily-monthly";
        await FetchDataFleetminder(hub, endpoint, async (data) => {

            if (typeof data === "undefined") {
                _this.setState({
                    isLoading: false
                });
                return
            }
            //console.log("onSubmit usage:", _this.state.Usages, data);
            if (data === null) {
                _this.setState({
                    isLoading: false
                });
                return
            }
            _this.setState({
                DataAll: data,
                isLoading: false
            })

        });
    };

    calculateTargetActual = (distance, cost, allowance, ltrKm, costUnit, radioUp, radioLower) => {
        let target, actual;
        let flagColor;

        //let find our Target
        if (cost !== 0 && distance !== 0) {
            actual = cost / distance;
            actual = FloatFixed(actual, 2)
        } else {
            actual = 0
        }

        //let find our Target
        target = ((ltrKm / 100) * costUnit) + allowance;
        target = FloatFixed(target, 2);

        //let find our flag
        flagColor = "none";
        if (actual >= radioUp) {
            flagColor = "red"
        } else if (actual < radioUp && actual >= radioLower) {
            flagColor = "orange"
        } else if (actual < radioLower) {
            flagColor = "green"
        }

        return {target, actual, flagColor};
    };
    getColomns = (idLabel) => {
        const myRightStyle = {
            cellStyle: {
                textAlign: "right",
            },
            headerStyle: {
                textAlign: "right"
            }
        };
        return [
            {id: "Vehicle", label: idLabel},
            {id: "Expense", label: "Fuel(Rand)", type: "currency", ...myRightStyle},
            {id: "Distance", label: "Mileage(Km)", ...myRightStyle},
            {id: "Actual", label: "Actual", ...myRightStyle},
            /*{id: "Target", label: "Target", ...myRightStyle},*/
            {id: "Rating", label: "Rating", ...myRightStyle},
            {id: "OpenMileage", label: "Km/Open", ...myRightStyle},
            {id: "CloseMileage", label: "Km/Close", ...myRightStyle}
        ]
    };
    renderFleetList = () => {
        const state = this.state;
        if (state.component !== "all") {
            return
        }

        let columns = this.getColomns("Vehicle");
        let ls = [];
        let DataAll = this.state.DataAll.Fleet;

        for (let vehicle in DataAll) {
            const row = DataAll[vehicle];
            row.Rating = makeFlag(row.FlagColor);
            row.Expense = parseFloat(row.Expense).toFixed(0);
            ls.push(row);
        }
        let data = {columns: columns, data: ls};

        let otherFunctions = [
            {icon: "date_range", tooltip: "View Monthly", isFreeAction: false, myAction: this.requestViewMonthly},
            /*{icon: "today", tooltip: "View Dialy", isFreeAction: false, myAction: this.requestViewDaily}*/
        ];

        if (ls.length === 0) {
            return (
                <h5 style={{color: "orange", marginTop: 50, marginLeft: 50}}>
                    &nbsp;&nbsp;&nbsp;Empty report, please select your date range and click on search!
                </h5>
            )
        }

        return (
            <GridItem xs={12} sm={12} md={12}>
                <TableDataView
                    {...data}
                    tableTitle={(<span style={{color: "#2196f3"}}>Vehicles</span>)}
                    /*onAddRecord={this.onAddRecord}
                    onDelete={this.onDelete}*/
                    otherFunctions={otherFunctions}
                />
            </GridItem>
        )
    };
    renderMonthly = () => {
        const state = this.state;
        if (state.component !== "month") {
            return
        }
        if (state.selectedVehicle === "") {
            return
        }
        let columns = this.getColomns("month");
        let ls = [];
        let DataAll = state.DataAll.Monthly;
        //console.log("ZZZZ - View monthly request clicked! > ", DataAll);
        for (let vehicle in DataAll) {
            if (vehicle !== state.selectedVehicle) {
                continue;
            }
            const rowVehicle = DataAll[vehicle];
            for (let date in rowVehicle) {
                const row = rowVehicle[date];
                row.Vehicle = date;
                row.Rating = makeFlag(row.FlagColor);
                row.Expense = parseFloat(row.Expense).toFixed(0);
                row.monthId = date;
                ls.push(row);
            }
        }

        let otherFunctions = [
            {icon: "list", tooltip: "View Daily", isFreeAction: false, myAction: this.requestViewDaily},
        ];

        let data = {columns: columns, data: ls};
        const title = "Vehicle Usage overview :" + state.selectedVehicle;

        {/*<Button variant="outlined" color="secondary" onClick={()=>onExit()}>
                Exit view maintenance event
            </Button>
            <Button variant="outlined" color="primary" onClick={()=>onAdd()}>
                Add new maintenance event
            </Button>*/
        }


        return (
            <GridItem xs={12} sm={12} md={12}>
                <ControllerButton
                    variant="outlined"
                    label={"Close Vehicle View"}
                    color={"secondary"}
                    onClick={this.closeViewMonthly}
                />

                <TableDataView
                    {...data}
                    tableTitle={(<span style={{color: "#2196f3"}}>{title}</span>)}
                    otherFunctions={otherFunctions}
                />
            </GridItem>
        )

    };

    requestViewDaily = (row) => {
        this.setState({
            selectedMonth: row.monthId,
            component: "day"
        })
    };
    closeViewDaily = () => {
        this.setState({
            selectedMonth: "",
            component: "month"
        })
    };
    closeViewDetail = () => {
        this.setState({
            selectedDay: null,
            component: "day"
        })
    };
    requestViewDetail = (row) => {
        this.setState({
            selectedDay: row,
            component: "detail"
        })
    };

    renderDay = () => {
        const state = this.state;
        if (state.component !== "day") {
            return
        }
        if (state.selectedMonth === "") {
            return
        }
        let columns = this.getColomns("Daily");
        let ls = [];
        let DataAll = state.DataAll.Daily;
        //console.log("555 View daily request clicked! > ", state.selectedMonth, state.selectedVehicle, DataAll);
        for (let vehicle in DataAll) {
            if (vehicle !== state.selectedVehicle) {
                continue;
            }

            const rowVehicle = DataAll[vehicle];
            for (let date in rowVehicle) {
                const arr = date.split("-");
                const targetDate = arr[0] + "-" + arr[1];
                if (targetDate !== state.selectedMonth) {
                    continue
                }
                const row = rowVehicle[date];
                row.Vehicle = date;
                row.Rating = makeFlag(row.FlagColor);
                row.Expense = parseFloat(row.Expense).toFixed(0);
                row.monthId = date;
                ls.push(row);
            }

        }


        let data = {columns: columns, data: ls};
        let otherFunctions = [
            {icon: "list", tooltip: "View Detail", isFreeAction: false, myAction: this.requestViewDetail},
        ];

        const title = "Monthly Usage vehicle:" + state.selectedVehicle + " Period: " + state.selectedMonth;
        return (
            <GridItem xs={12} sm={12} md={12}>
                <ControllerButton
                    variant="outlined"
                    label={"Close Daily View"}
                    color={"secondary"}
                    onClick={this.closeViewDaily}
                />
                <TableDataView
                    {...data}
                    tableTitle={(<span style={{color: "#2196f3"}}>{title}</span>)}
                    otherFunctions={otherFunctions}
                />
            </GridItem>
        )

    };

    renderDetail = () => {
        if (this.state.component !== "detail") {
            return
        }
        const record = this.state.selectedDay;
        let ls = [
            {key: "Open Date", val: record.DateIn},
            {key: "Close Date", val: record.DateOut},
            {key: "Open Mileage", val: record.OpenMileage},
            {key: "Close Mileage", val: record.CloseMileage},
            {key: "Capture Date", val: record.Capture},
            {key: "Tip Distance", val: record.Distance},
            {key: "Travel Cost", val: "R " + record.Expense},
        ];
        let ls2 = [
            {key: "Manufacture Spec Ltrs/100Km", val: record.Consumption},
            {key: "Allowance", val: "R " + record.Allowance},
            {key: "Target cost Rand/Km", val: "R " + record.Target},
            {key: "Actual cost Rand/Km", val: "R " + record.Actual},
            {key: "Cost unit Rand/Km", val: "R " + record.CostUnit},
            {key: "Flag Color", val: record.FLagColor},
            {key: "Rating", val: makeFlag(record.FlagColor)},
        ];
        return (
            <GridContainer style={{paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                <GridItem xs={12} sm={12} md={12}>
                    <h5 style={{color: "#2196f3", margin: 10, fontSize: 12}}>Detail
                        usage {this.state.vehicle} [{record.DateIn}]</h5>

                    <ControllerButton
                        variant="outlined"
                        label={"Close Detail View"}
                        color={"secondary"}
                        onClick={this.closeViewDetail}
                    />

                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <TableSimple
                        data={ls}
                        includeHeader={false}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <TableSimple
                        data={ls2}
                        includeHeader={false}
                    />
                </GridItem>

            </GridContainer>
        )

    };

    renderIndexDetail = () => {
        let ls = [
            /*{id: "CA 789 987", actual: 5, flagColor: "green"},
            {id: "CA 456 654", actual: 6.2, flagColor: "orange"},
            {id: "CA 258 852", actual: 5.8, flagColor: "green"},
            {id: "CA 789 987", actual: 7, flagColor: "red"},*/
        ];

        let data = this.state.DataAll.Fleet;
        for(let vehicle in data) {
            const row = data[vehicle];
            if(this.state.selectedGroupFilter==="all"){
                ls.push({
                    id: vehicle,
                    actual: row.Actual,
                    flagColor: row.FlagColor
                })
            }else{
                if(this.state.selectedGroupFilter===row.FlagColor){
                    ls.push({
                        id: vehicle,
                        actual: row.Actual,
                        flagColor: row.FlagColor
                    })
                }
            }
        }

        return ls.map((row) => {
            return (
                <GridItem xs={12} sm={6} md={3}>
                    <MyCircle
                        number={row.actual}
                        colorIn={row.flagColor}
                        vehicle={row.id}
                    />
                </GridItem>
            )
        })
    };

    renderHeaderGlobalSummary = () => {
        let totalAll=0,totalRed=0,totalOrange=0,totalGreen=0;
        let data = this.state.DataAll.Fleet;
        for(let i in data){
            const row = data[i];
            totalAll++;
            if(row.FlagColor==="red"){
                totalRed++
            }else if(row.FlagColor==="orange"){
                totalOrange++
            }else if(row.FlagColor==="green"){
                totalGreen++
            }
        }

        const styleBoard = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            flexDirection: "row",
            maxHeight:100,minH:100
        };
        let colorIn = "red";
        return (
            <div style={{
                minHeight: 100,
                minWidth: "100%",
                borderWidth: 5,
                borderColor: "gray",
                borderRadius: 5,
            }}>
                <GridContainer style={{marginTop: 10, paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                    <GridItem xs={12} sm={6} md={1} style={styleBoard}>
                        <span style={{color: colorIn, fontSize: 64}}>8</span>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={1} style={styleBoard}>
                        <AssistantPhotoIcon style={{color: colorIn, fontSize: 64}}/>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={2} style={{maxHeight:100,minH:100}}>
                        <MyRadio
                            counterRed={totalRed}
                            counterAll={totalAll}
                            counterOrange={totalOrange}
                            counterGreen={totalGreen}
                            handleSelectGroup={this.handleGroupFilter}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <figure className="highcharts-figure">
                            <div id="container"></div>
                            {/*<p className="highcharts-description">
                                Chart showing data loaded dynamically. The individual data points can
                                be clicked to display more information.
                            </p>*/}
                        </figure>
                    </GridItem>
                </GridContainer>
            </div>
        )
    };

    lineChart = () => {
        window.Highcharts.chart('container', {

            chart: {
                scrollablePlotArea: {
                    minWidth: 700
                }
            },

            data: {
                csvURL: 'https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/analytics.csv',
                beforeParse: function (csv) {
                    return csv.replace(/\n\n/g, '\n');
                }
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },

            xAxis: {
                tickInterval: 7 * 24 * 3600 * 1000, // one week
                tickWidth: 0,
                gridLineWidth: 1,
                labels: {
                    align: 'left',
                    x: 3,
                    y: -3
                }
            },

            yAxis: [{ // left y axis
                title: {
                    text: null
                },
                labels: {
                    align: 'left',
                    x: 3,
                    y: 16,
                    format: '{value:.,0f}'
                },
                showFirstLabel: false
            }, { // right y axis
                linkedTo: 0,
                gridLineWidth: 0,
                opposite: true,
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: -3,
                    y: 16,
                    format: '{value:.,0f}'
                },
                showFirstLabel: false
            }],

            legend: {
                align: 'left',
                verticalAlign: 'top',
                borderWidth: 0
            },

            tooltip: {
                shared: true,
                crosshairs: true
            },

            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function (e) {
                                window.hs.htmlExpand(null, {
                                    pageOrigin: {
                                        x: e.pageX || e.clientX,
                                        y: e.pageY || e.clientY
                                    },
                                    headingText: this.series.name,
                                    maincontentText: window.Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ':<br/> ' +
                                        this.y + ' sessions',
                                    width: 200
                                });
                            }
                        }
                    },
                    marker: {
                        lineWidth: 1
                    }
                }
            },

            series: [
                {
                    name: 'All Fuel(Km)',
                    lineWidth: 4,
                    marker: {
                        radius: 4
                    }
                },
                {
                    name: 'New Mileage(Km)'
                }
            ]
        });

    };

    render() {
        return (
            <Wallpaper {...this.props} pageTitle={"Global overview"} displayLeftPanel={true}>

                <GridContainer style={{marginTop: 50, paddingRight: 20, paddingBottom: 30, minWidth: "100%"}}>
                    <GridItem xs={12} sm={12} md={12}>
                        <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                            &nbsp;
                        </h4>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <GridItem xs={12} sm={12} md={12}>
                            <ControllerCalendar
                                label={"Start Date"}
                                label2={"End Date"}
                                handleInput2={this.handleInput2}
                                name={"startDate"}
                                getHandleValue={this.getHandleValue}
                                name2={"endDate"}
                                onSubmit={this.onSubmit}
                                btnLabel={"Search"}
                                variant={"contained"}
                                color={"primary"}
                            />
                        </GridItem>
                    </GridItem>
                    {this.renderFleetList()}
                    {this.renderMonthly()}
                    {this.renderDay()}
                    {this.renderDetail()}

                </GridContainer>

                <LoadingProcessing open={this.state.isLoading}/>
            </Wallpaper>
        )
    }

}

export default Connector(FuelFleetOverview);

/*
    Vehicle      string
	Date         string
	OpenMileage  float64
	CloseMileage float64
	Total        float64
	Capture      float64
	DateIn       string
	DateOut      string
	Distance     float64
	Expense      float64
	CostUnit     float64
	FlagColor    string
	Actual       float64
	Target       float64
	Consumption  float64
	Allowance    float64
     */
