import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        alignItems:"center"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default ({link,title,formatVariant,alignContent})=> {
    const classes = useStyles();
    let myFloat="left";
    if(typeof alignContent !=="undefined"){
        myFloat = alignContent
    }

    const extraStyle={
        backgroundPosition: 'top center',
        backgroundOrigin: 'border-box',
        border: 'solid 1px orange' ,
    };

    return (
        <div className={classes.root} style={{float:myFloat}}>
            {myFloat==="left"&&(
                <Avatar style={extraStyle} variant={formatVariant} alt={title} src={link} className={classes.large} component={"div"} />
            )}
            <Typography style={{fontSize:12}}>{title}</Typography>
            {myFloat==="right"&&(
                <Avatar style={extraStyle} variant={formatVariant} alt={title} src={link} className={classes.large} component={"div"} />
            )}
        </div>
    );
}
