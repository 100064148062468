import GridContainer from "../../../Common/dashboard/Grid/GridContainer";
import GridItem from "../../../Common/dashboard/Grid/GridItem";
import React from "react";
import SelectBox from "./ControllerSelectBox";
import InputBox from "./ControllerInputBox";
import ControllerRadioButton from "./ControllerRadioButton";


export default (props)=>{
    let {handleInput,handleInput2,getValue, getHandleValue,handleInputCheckbox,lsPersonnel}=props;

    const renderBox=(name,label,ls)=>{
        return(
            <SelectBox
                name={name}
                ls={ls}
                handleInput={handleInput}
                getHandleValue={getHandleValue}
                label={label}
                {...props}
            />
        )
    }
    // newEventNotificationEntity
    return(
        <GridContainer style={{marginTop: 50,paddingRight:20,paddingBottom:30}}>
            <GridItem xs={12} sm={12} md={12}>
                <h4 style={{marginLeft: 10, color: "#2196f3", fontSize: 16}}>
                    Step: 6 Notification
                </h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <ControllerRadioButton
                    name={"newEventNotificationApply"}
                    label={"Apply Notification"}
                    options={[{key:true,val:"Yes"},{key:false,val:"No"}]}
                    {...props}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {renderBox("newEventNotificationEntity","Target Entity",[
                    {key:"personnel",val:"Personnel"},
                    {key:"driver",val:"Driver"},
                    {key:"supplier",val:"Supplier"},
                ])}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {renderBox("newEventNotificationPerson","Notify To",lsPersonnel)}

            </GridItem>


        </GridContainer>
    )
}