import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar } from 'react-bootstrap';


export default ({verify,styles,now,color})=>{

    /*let completedList=[];
    let incompletedList=[];
    let appName ="maintenance";
    if(typeof verify[appName]!=="undefined"){
        const infos=verify[appName];
        for(let i in infos.allow){
            const item =infos.allow[i];
            completedList.push(item);
        }
        for(let i in infos.denny){
            const item =infos.denny[i];
            incompletedList.push(item);
        }
    }

    const total=completedList.length + incompletedList.length;
    let now= (completedList.length / total )*100;
    now  =now.toFixed(0);*/

    return(
        <ProgressBar striped variant={color} now={now} label={`${now}%`} style={styles} />
    )
}
