import {sortData} from "./fn";

export const findRecordFromDatabase=(ref,module,props)=>{
    const Databases = props.allData.Databases;
    for (let i in Databases) {
        const row = Databases[i];
        if (row.module === module) {
            return row
        }
    }
    return {};
}
export const findNameFromDatabase=(ref,module,props)=>{
    const record = findRecordFromDatabase(ref,module,props);
    return getDisplayNameEntity(module,record,props)
}

const getDisplayNameEntity=(module,record,props)=>{
    const fieldList=getListDisplayField(module,props);
    let names="";
    for(let  i in fieldList){
        const row=fieldList[i];
        names +=record.data[row.fieldname]+" "
    }
    return names
}
const getListDisplayField=(module,props)=>{
    let ls = [];
    const attributes = props.allData.Attributes;
    for (let i in attributes) {
        const row = attributes[i];
        if (row.module === module) {
            if(row.display){
                ls.push(row)
            }
        }
    }

    ls =sortData("displayposition",ls);
    return ls
}
const GetEntityData = (module,props) => {
    let ls = [];
    const Databases = props.allData.Databases;

    for (let i in Databases) {
        const row = Databases[i];
        if (row.module === module) {
            const val =getDisplayNameEntity(module,row,props);
            ls.push({
                key: row.ref+"--"+val,
                val: val,
                data: row.data
            })
        }
    }


    return ls
}

export {
    GetEntityData
}

export default GetEntityData;
