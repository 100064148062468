import React from "react";
import {Paper} from "@material-ui/core";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import StepInfo from "./StepInfo";
import SepInstruction from "./StepInstruction";
import StepReminder from "./StepReminder";
import StepTask from "./StepTask";
import StepNotify from "./StepNotification";
import StepExpire from "./StepExpire";
import StepSummary from "./StepSummary";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Info', 'Instruction', 'Reminder','Budget & Tasks','Notify','Expire Period','Summary'];
}

function getStepContent(step,props) {
    switch (step) {
        case 0:
            return <StepInfo {...props} />;
        case 1:
            return <SepInstruction {...props}/>;
        case 2:
            return <StepReminder {...props}/>;
        case 3:
            return <StepTask {...props}/>;
        case 4:
            return <StepNotify {...props}/>;
        case 5:
            return <StepExpire {...props}/>;
        case 6:
            return <StepSummary {...props}/>;

        default:
            return 'Unknown step';
    }
}


export default (props)=>{
    let {onCancelAdding}=props;
    return(
        <Paper style={{paddingLeft:0,paddingRight:0,minHeight:"100%"}}>
            <h1 style={{
                color: "#2196f3",
                fontSize: 18
            }}>
                <CancelPresentationIcon onClick={()=>onCancelAdding()} style={{color:"red",cursor:"pointer"}}/>
                &nbsp;&nbsp;New Maintenance Event
            </h1>
            <p>the following action will be taken in place to add the new event to the list</p>

            <HorizontalLinearStepper {...props} />

        </Paper>
    )
}


const HorizontalLinearStepper=(props)=> {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    let {onFinishAdd,state}=props;



    const isKeyEmpty=(key)=>{
        if(state[key]===""){
            return true
        }

        return false
    }

    const validationInfo=()=>{
        if(isKeyEmpty("newEventType") ||
            isKeyEmpty("newEventCategory") ||
            isKeyEmpty("newEventName")){
            return "Step 1 fail, please provide required value"
        }
        return ""
    }
    const validationInstruiction=()=>{
        if(isKeyEmpty("newEventNextDue")){
            return "Step 2 fail, please provide Next Due value"
        }
        if(state.newEventRepeatApply){
            if(isKeyEmpty("newEventRepeatUnit") ||
                isKeyEmpty("newEventRepeatValue") ){
                return "Step 2 fail, please provide Repeat Unit and  Value"
            }
        }

        return ""
    }
    const validationNotify=()=> {
        if(state.newEventNotificationApply){
            if (isKeyEmpty("newEventNotificationPerson")) {
                return "Step 5 fail, please provide notify person"
            }
        }
        return ""
    }
    const isStepOptional = step => {
        if(step === 3){
            return true
        }
        if(step === 2){
            return true
        }
        if(step === 5){
            return true
        }
        return false;
    };

    const isStepSkipped = step => {
        return skipped.has(step);
    };

    const handleNextFinish=()=>{

        if(validationInfo()!==""){
            alert(validationInfo())
            return
        }
        if(validationInstruiction()!==""){
            alert(validationInstruiction())
            return
        }
        if(validationNotify()!==""){
            alert(validationNotify())
            return
        }
        onFinishAdd();

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    }
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if(activeStep ===0){
            if(validationInfo()!==""){
                alert(validationInfo())
                return
            }
        }
        if(activeStep ===1){
            if(validationInstruiction()!==""){
                alert(validationInstruiction())
                return
            }
        }
        if(activeStep ===4){
            if(validationNotify()!==""){
                alert(validationNotify())
                return
            }
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </div>
                ) : (
                    <div>
                          <div>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                Back
                            </Button>
                            {isStepOptional(activeStep) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSkip}
                                    className={classes.button}
                                    style={{float:"right"}}
                                >
                                    Skip
                                </Button>
                            )}


                              {activeStep === steps.length - 1 ?
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={()=>handleNextFinish()}
                                className={classes.button}
                                style={{float:"right"}}
                            >
                                 Submit
                            </Button>:
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNext}
                                  className={classes.button}
                                  style={{float:"right"}}
                              >
                                   Next
                              </Button>}
                        </div>

                        <Paper style={{
                            margin:10
                        }}>
                            <Typography className={classes.instructions}>{getStepContent(activeStep,props)}</Typography>

                        </Paper>

                    </div>
                )}
            </div>
        </div>
    );
}