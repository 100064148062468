import {SAVE_MODULE_MENU,SAVE_APP_NAME} from "../constants/index";
const allRole = ["super", "admin", "capture", "enumerator"];
const adminRole = ["super", "admin"];

const initial = {
    currentApp:localStorage.getItem("@vehicleminder-app") || "maintenance",
    currentModule:localStorage.getItem("@vehicleminder-module")|| "alert",
    currentMenu:localStorage.getItem("@vehicleminder-menu")|| "maintenance-setting-verification",
    currentSubmenu: localStorage.getItem("@vehicleminder-submenu")||"",
    menus: {
        maintenance: {
            alert: [
                {
                    name: "Setup Alert",
                    app: "maintenance",
                    hasMenu: false,
                    link: "maintenance-setting-verification",
                    roles: adminRole,
                    isDefault: true
                },
            ],
            reporting: [
                {
                    name: "Dashboard",
                    app: "maintenance",
                    hasMenu: false,
                    link: "reporting-dashboard",
                    roles: adminRole,
                    isDefault: true
                },
                {
                    name: "Reports",
                    app: "maintenance",
                    hasMenu: false,
                    link: "reporting-reports",
                    roles: adminRole,
                    isDefault: false
                },
                {
                    name: "Database Profile",
                    app: "maintenance",
                    hasMenu: false,
                    link: "reporting-database",
                    roles: adminRole,
                    isDefault: false
                },
            ],
            operations: [
                {
                    name: "Operation Menu",
                    app: "maintenance",
                    hasMenu: false,
                    link: "operations-menu",
                    roles: adminRole,
                    isDefault: true
                },
                {
                    name: "Event Plan",
                    app: "maintenance",
                    hasMenu: false,
                    link: "operations-event-plan",
                    roles: adminRole,
                    isDefault: false
                },
                {
                    name: "Job Due",
                    app: "maintenance",
                    hasMenu: false,
                    link: "operations-event-plan",
                    roles: allRole,
                    isDefault: false
                },
                {
                    name: "Job Schedule",
                    app: "operations-event-plan",
                    hasMenu: false,
                    link: "operations-schedule-",
                    roles: allRole,
                    isDefault: false
                },
                {
                    name: "Workshop",
                    app: "maintenance",
                    hasMenu: false,
                    link: "operations-event-plan",
                    roles: allRole,
                    isDefault: false
                },
                {
                    name: "Expense",
                    app: "maintenance",
                    hasMenu: false,
                    link: "operations-event-plan",
                    roles: allRole,
                    isDefault: false
                },
                {
                    name: "Faults",
                    app: "maintenance",
                    hasMenu: false,
                    link: "operations-event-plan",
                    roles: allRole,
                    isDefault: false
                },
            ],
            settings: [
                {
                    name: "Entities",
                    app: "maintenance",
                    hasMenu: false,
                    link: "settings-entities",
                    roles: adminRole,
                    isDefault: true
                },
                {
                    name: "Association",
                    app: "maintenance",
                    hasMenu: false,
                    link: "settings-association",
                    roles: allRole,
                    isDefault: false
                },
                {
                    name: "Operation",
                    app: "maintenance",
                    hasMenu: false,
                    link: "settings-operation",
                    roles: allRole,
                    isDefault: false
                },
                {
                    name: "SMPT",
                    app: "maintenance",
                    hasMenu: false,
                    link: "settings-smpt",
                    roles: allRole,
                    isDefault: false
                },
            ]
        },
        trip:{
            home:[
                {
                    name: "Coming soon",
                    app: "trip",
                    hasMenu: true,
                    link: "coming-soon",
                    roles: adminRole,
                    isDefault: true
                },
            ]
        },
        fuel:{
            dashboards:[
                {
                    name: "Executive Dashboard",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-dashboard-executive",
                    roles: adminRole,
                    isDefault: true
                },
                {
                    name: "Fleet Dashboard",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-fleet-dashboard",
                    roles: adminRole,
                    isDefault: false
                },
                {
                    name: "Fleet Overview",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-fleet-overview",
                    roles: adminRole,
                    isDefault: false
                },
                {
                    name: "Category Overview",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-category-overview",
                    roles: adminRole,
                    isDefault: false
                },
            ],
            "Fuel Logs":[
                {
                    name: "Fuel Fill",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-filling",
                    roles: adminRole,
                    isDefault: true
                },
                {
                    name: "Qa Submitted Logs",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-logs-qa",
                    roles: adminRole,
                    isDefault: false
                },
                {
                    name: "Qa Logs history",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-logs-qa-history",
                    roles: adminRole,
                    isDefault: false
                },

            ],
            settings:[
                {
                    name: "Setup Consumption",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-setting-consumption",
                    roles: adminRole,
                    isDefault: true
                },
                {
                    name: "[Upload history]",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-upload-history-data",
                    roles: adminRole,
                    isDefault: false
                },
                {
                    name: "Entities Settings",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-setting-entities",
                    roles: adminRole,
                    isDefault: false
                },
                {
                    name: "Mobile User",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-setting-user-mobile",
                    roles: adminRole,
                    isDefault: true
                },
                {
                    name: "System User",
                    app: "fuel",
                    hasMenu: true,
                    link: "fuel-setting-user-system",
                    roles: adminRole,
                    isDefault: true
                },
            ]
        },
        reservation:{
            home:[
                {
                    name: "Coming soon",
                    app: "trip",
                    hasMenu: true,
                    link: "coming-soon",
                    roles: adminRole,
                    isDefault: true
                },
            ]
        }
    },
    schema:[
        {name:"maintenance",title:"Maintenance",color:"#1D2951"},
        {name:"fuel",title:"Fuel ",color:"#f44336"},
        {name:"trip",title:"Trip",color:"#2196f3",},
        {name:"reservation",title:"Reservation",color:"#673ab7"},
    ]
}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SAVE_APP_NAME:
            const entry1 = action.payload;
            //console.log("Reducer ::(--> ", action);
            newState = initial;
            newState.currentModule = entry1.module;
            newState.currentMenu = entry1.menu;
            newState.currentSubmenu = entry1.submenu;
            newState.currentApp = entry1.app;
            return Object.assign({}, newState);
        case SAVE_MODULE_MENU:
            const entry = action.payload;
            //console.log("Reducer ::(--> ", action);
            newState = initial;
            newState.currentModule = entry.module;
            newState.currentMenu = entry.menu;
            newState.currentSubmenu = entry.submenu;
            return Object.assign({}, newState);

        default:
            return state
    }
}

export default reduceNavigations;
