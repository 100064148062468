import {sortData} from "../../../api/fn";

const GetEntityData = (module,props,keyOnly) => {
    let ls = [];
    const Databases = props.allData.Databases;
    for (let i in Databases) {
        const row = Databases[i];
        if (row.module === module) {
            let display =  row.ref;
            if(typeof keyOnly==="undefined"){
                display =getDisplayNameEntity(module, row,props)
            }
            ls.push({
                key: row.ref,
                val: display,
                data: row.data
            })
        }
    }
    return ls
}
const getDisplayNameEntity = (module, record,props) => {
    const fieldList = getListDisplayField(module,props);
    let names = "";
    for (let i in fieldList) {
        const row = fieldList[i];
        names += record.data[row.fieldname] + " "
    }
    return names
}
const getListDisplayField = (module,props) => {
    let ls = [];
    const attributes = props.allData.Attributes;
    for (let i in attributes) {
        const row = attributes[i];
        if (row.module === module) {
            if (row.display) {
                ls.push(row)
            }
        }
    }

    ls = sortData("displayposition", ls);
    return ls
}

export{
    GetEntityData
}
