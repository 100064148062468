import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    label:{
        fontSize:12
    }
}));

export default (props)=> {
    let {options,label,name,handleInput2,getHandleValue}=props;
    const classes = useStyles();
    const [value, setValue] = React.useState('yes');

    const handleChange = event => {
        var isTrueSet = (event.target.value == 'true');
        setValue(isTrueSet);
        handleInput2(name,isTrueSet)
    };

    const isChecked=(inItem)=>{
        //console.log("isChecked : ",name, " > ",inItem," > ",getHandleValue(name));
        if(inItem==="Yes" && getHandleValue(name)){
            return true
        }
        if(inItem==="No" && !getHandleValue(name)){
            return true
        }

        return false
    }

    return (
        <div style={{minWidth:"100%",paddingRight:0,paddingLeft:10}}>
            <FormControl component="fieldset" className={classes.formControl+" fieldset "+classes.margin} style={{minWidth:"100%"}}>
                <FormLabel component="legend" className={"legend"} style={{fontSize:12}} style={{minWidth:"100%"}}>
                    <span style={{fontSize:12}}>{label}</span></FormLabel>
                <RadioGroup aria-label="gender" name={name} value={value} onChange={handleChange} style={{fontSize:12}}>
                    {options.map((item) => {
                        return (
                            <FormControlLabel
                                aria-setsize={15}
                                value={item.key}
                                control={<Radio checked={isChecked(item.val)}/>}
                                label={item.val}
                                style={{fontSize:12}}
                                className={useStyles}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>

        </div>
    );
}
