
import React from "react";
import TreeView from "./Recrusive";
import PopNewNode from "./PopNewNode";
import AddNewNodeButton from "./ButtonNewNode";
import {SAVE_DATA,LOAD_DATA} from "../../../redux/constants";
import {GetUserToken} from "../../../api/fetching-data";
import allData from "../../../redux/reducer/reducer-all-data";
import TreeBuilder from "./TreeBuilder";

export default class Tree extends React.Component{
    constructor(props){
        super(props);
        this.state={
            showAddNode:false,
            selectedNodeId:"",
            newNode:"",
            newTopicTitle:"",
            newTopicBody:""
        }
    }
    handleInput=(e)=>{
        const key =e.target.name;
        const val =e.target.value;
        this.setState({
            [key]:val
        })
    }
    addTopics=(parentId,topic)=>{

    }
    removeTopics=(parentId,topic)=>{

    }
    addNode=async ()=>{
        console.log("(:)->addNode: ",this.state.newNode);
        /**
         * do what ever to submit to crm global via reduce service
         */
        await this.props.dispatch({
            type:SAVE_DATA,
            payload:{
                Org:GetUserToken().OrgCode,
                Module:this.props.module,
                Parent:this.state.selectedNodeId,
                Child:this.state.newNode
            },
            module:"solutions"
        })
        await this.closeAddingNode();
      //await this.fetchTree();

    }
    removeNode=()=>{

    }
    fetchTree=async ()=>{
        //TODO make a reload function redux for solutions only
        this.props.dispatch({
            type:LOAD_DATA,
            payload:{},
        })
    }

    requestAddingTopic=(id)=>{
        this.setState({
            selectedNodeId:id
        })
    }

    requestAddingNode=(id)=>{
        this.setState({
            selectedNodeId:id,
            showAddNode:true,
        })
    }
    closeAddingNode=()=>{
        this.setState({
            selectedNodeId:"",
            showAddNode:false,
        })
    }

    render(){
        let treeItems=[];
        if(typeof this.props.allData.Filters !=="undefined"){
            if(this.props.allData.Filters !==null){
                const data = this.props.allData.Filters;
                for(let i in data){
                    const row = data[i];
                    treeItems.push({
                        id:row.id,
                        parent:row.parent,
                        child:row.child,
                        detail:row.detail||{}
                    })
                }
                //treeItems = this.props.allData.Filters;
            }
        }

        let lsData =[];
        if(treeItems.length>0){
            const result  = TreeBuilder({_this:this,treeItems});
            lsData.push(result);
        }


        return(
            <React.Fragment>
                <AddNewNodeButton
                    clickAdd={this.requestAddingNode}
                />
                <TreeView
                    treeItems={lsData}
                    newNode={this.requestAddingNode}
                    newTopic={this.requestAddingTopic}
                    onRemoveNode={this.removeNode}
                />

                <PopNewNode
                    onClose={this.closeAddingNode}
                    open={this.state.showAddNode}
                    handleInput={this.handleInput}
                    nameInput={"newNode"}
                    title={"Add New Node"}
                    submitNode={this.addNode}
                    labelInput={"Name of Node"}
                />
            </React.Fragment>

        )
    }

}

const arrData=[
    {
        "id": "5",
        "child": "fleetminder",
        "parent": "3",
        "detail": {}
    },
    {
        "id": "6",
        "child": "maintenance",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "7",
        "child": "maintenance",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "8",
        "child": "fuel",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "2",
        "child": "akhanya",
        "parent": "",
        "detail": {}
    },
    {
        "id": "9",
        "child": "trip",
        "parent": "5",
        "detail": {}
    },
    {
        "id": "10",
        "child": "product",
        "parent": "2",
        "detail": {}
    },
    {
        "id": "12",
        "child": "Learning Management",
        "parent": "11",
        "detail": {}
    },
    {
        "id": "13",
        "child": "Mobile",
        "parent": "11",
        "detail": {}
    },
    {
        "id": "14",
        "child": "Foot Agent",
        "parent": "13",
        "detail": {}
    },
    {
        "id": "11",
        "child": "system design",
        "parent": "10",
        "detail": {}
    },
    {
        "id": "15",
        "child": "Chela",
        "parent": "13",
        "detail": {}
    },
    {
        "id": "16",
        "child": "Credit management",
        "parent": "15",
        "detail": {}
    },
    {
        "id": "18",
        "child": "Wallet management",
        "parent": "15",
        "detail": {}
    },
    {
        "id": "3",
        "child": "product",
        "parent": "1",
        "detail": {}
    },
    {
        "id": "1",
        "child": "easipath",
        "parent": "",
        "detail": {}
    },
    {
        "id": "19",
        "child": "Files",
        "parent": "15",
        "detail": {}
    },
    {
        "id": "20",
        "child": "Recon",
        "parent": "19",
        "detail": {}
    },
    {
        "id": "21",
        "child": "Sim",
        "parent": "19",
        "detail": {}
    },
    {
        "id": "4",
        "child": "cclms",
        "parent": "3",
        "detail": {}
    },
    {
        "id": "22",
        "child": "Commission",
        "parent": "19",
        "detail": {}
    },
    {
        "id": "17",
        "child": "Airtime Distribution",
        "parent": "15",
        "detail": {}
    }
]
